import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import Icon from '@material-ui/core/Icon';
import Menu from '@material-ui/icons/Menu';
import DashboardIcon from '../../resources/icons/home_page.svg';
import InspectionsIcon from '../../resources/icons/inspections.svg';
import ReviewsIcon from '../../resources/icons/reviews.svg';
import UserPageIcon from '../../resources/icons/user_page.svg';
import ReportsIcon from '../../resources/icons/report_page.svg';
import { Logo } from '../../atoms';
import { SidebarMenuLink } from '../../molecules';
import { ROUTES } from '../../constants/routes';
import SearchIcon from '../../resources/icons/search_page.svg';
import { removeFilters } from '../../redux/slices/filterSlice';

const createIcon = (src: string) => (
  <IconWrapper>
    <Icon>
      <ReactSVG src={src} />
    </Icon>
  </IconWrapper>
);

const InspectorSidebar = ({ logoClassName }: Props) => {
  const [open, setOpen] = React.useState<boolean>(true);
  const history = useHistory();
  const dispatch = useDispatch();
  const handleResize = () => {
    setOpen(window.innerWidth > 850);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (window.innerWidth < 850) {
       setOpen(false);
    }
    dispatch(removeFilters(null));
  }, [history]);
  useEffect(() => {

  }, [window.innerWidth]);
  const MenuItems = {
    Dashboard: 'Dashboard',
    Inspections: 'Inspections',
    Reviews: 'Reviews',
    Account: 'Account',
    Search: 'Search',
    Reports: 'Reports',
  };

  // let screenSize = 0;
  const openSidebar = () => {
    if (window.innerWidth < 850) {
      setOpen(!open);
    } else {
      setOpen(true);
    }
  };

  return (
    <>
      <Hamburger>
        <Menu onClick={() => openSidebar()} />
      </Hamburger>
      {open && (
        <Wrapper>
          <RootLogo className={logoClassName}>
            <Logo />
          </RootLogo>

          <MenuWrapper>
            <SidebarMenuLink
              icon={() => createIcon(DashboardIcon)}
              to={ROUTES.DASHBOARD}
              title={MenuItems.Dashboard}
            />
            <SidebarMenuLink
              icon={() => createIcon(InspectionsIcon)}
              to={ROUTES.INSPECTIONS}
              title={MenuItems.Inspections}
            />
            <SidebarMenuLink
              icon={() => createIcon(ReviewsIcon)}
              to={ROUTES.REVIEWS}
              title={MenuItems.Reviews}
            />
            <SidebarMenuLink
              icon={() => createIcon(SearchIcon)}
              to={ROUTES.SEARCH_INSPECTOR}
              title={MenuItems.Search}
            />
            {/* <SidebarMenuLink
              icon={() => createIcon(ReportsIcon)}
              to={ROUTES.REPORTS}
              title={MenuItems.Reports}
            /> */}
            <SidebarMenuLink
              icon={() => createIcon(UserPageIcon)}
              to={ROUTES.ACCOUNT}
              title={MenuItems.Account}
            />
          </MenuWrapper>
        </Wrapper>
      )}
    </>
  );
};

export const Hamburger = styled.div`
display: none;
@media only screen and (max-width: 840px) {
  display: inline-block;
  max-height: 30px;
  position: absolute;
  top:5px;
  left:4px;
  z-index: 3;
  zoom:2.5;
}
`;

const RootLogo = styled.div`
  padding: 20px 14px;
  margin-bottom: 40px;
`;

const Wrapper = styled.div`
  position: relative;
  width: 80px;
  background: var(--primary);
  padding-top: 15px;
  transition: width 0.5s;
  height: 100vh;
  @media only screen and (max-width: 840px) {
    position: absolute;
    top: 78px;
    left: 0px;
    z-index: 20;
  }
`;

const MenuWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 48px;
  width: 100%;
  min-height: 500px;
  height: 80vh;
`;

const IconWrapper = styled.div`
  .MuiIcon-root {
    margin: 0;
    display: block;

    svg {
      width: 40px;
      height: 40px;
    }
  }
`;

interface Props {
  logoClassName?: string;
}

export default InspectorSidebar;
