import { AxiosResponse } from 'axios';

import { api } from './api';
import { UserType } from './userAuth';
import { objectKeysToCamelCase } from '../utils';
import {
  getAccessToken,
  getRefreshToken,
  refreshToken,
} from '../auth';

function sendNewPassword(data: NewPasswordData) {
  return api.request<NewPassword>({
    method: 'POST',
    url: '/authentication/password/change/',
    data,
  });
}

export function getCurrentUser(data) {
  return api.request<UserType>({
    method: 'GET',
    url: data ? `/account/me/?franchise_id=${data}` : `/account/me/`,
  });
}

function readUserData(id: number) {
  return api.request<UserType>({
    method: 'GET',
    url: `/account/me/${id}/`,
  });
}

function deleteAccount(data: PasswordData) {
  return api.request({
    method: 'POST',
    url: '/account/me/delete-my-account/',
    data,
  });
}

function setNewUserEmail(data: UserEmailData) {
  return api.request({
    method: 'POST',
    url: `/account/send-change-email-confirm-code/`,
    data,
  });
}

function changeUserEmail(data: UserEmailVerifyData) {
  return api.request({
    method: 'POST',
    url: `/account/change-mail/`,
    data,
  });
}

export const checkAvailableEmail = async (email: string) => {
  const result = (await fetch(`/api/v1/account/check-email/?email=${email}`)).json();
  return result;
}

async function sendNewUserAvatar(
  id: number,
  formData: FormData,
): Promise<UserType> {
  const uploadAvatar = () =>
    fetch(`/api/v1/account/me/${id}/`, {
      method: 'PATCH',
      body: formData,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });

  let response = await uploadAvatar();

  if (response.status === 401) {
    const refresh = getRefreshToken();

    if (!refresh) {
      return null;
    }

    // wait for token refresh
    await refreshToken(refresh);

    response = await uploadAvatar();
  }

  const userInfo = await response.json();

  return objectKeysToCamelCase(userInfo);
}

function sendNewUserData(
  id: number,
  data: UserDataPayload,
) {
  return api.request<UserType>({
    method: 'PATCH',
    url: `/account/me/${id}/`,
    data,
  });
}

function quickbooksOAuth() {
  return api.request<{ url: string }>({
    method: 'GET',
    url: '/quickbooks/auth/',
  });
}

interface NewPasswordData {
  oldPassword: string;
  newPassword1: string;
  newPassword2: string;
}

export interface PasswordData {
  password: string;
}

export type UserEmailData = {
  email: string;
};

export type UserEmailVerifyData = Omit<
  UserEmailData,
  'code'
>;

export type UserCodeData = {
  code: string;
};

export type UserDataPayload = Omit<UserType, 'id'>;

type NewPassword = AxiosResponse<NewPasswordData>;

export default {
  sendNewPassword,
  sendNewUserData,
  sendNewUserAvatar,
  getCurrentUser,
  readUserData,
  deleteAccount,
  changeUserEmail,
  setNewUserEmail,
  quickbooksOAuth,
};
