import * as React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { generatePath } from 'react-router';
import styled from 'styled-components';
import {
  getPermitByID,
  getPermits,
  getProjectPermits,
  updatePermit,
} from '../../api/permits';
import { getProjectByID } from '../../api/projects';
import { getContactTypeList } from '../../api/contactType';
import { createUser, getUsers, getUsersCustom } from '../../api/users';
import { getVocabulariesDataList } from '../../api/vocabulary';

import DashboardLayout from '../../layouts/DashboardLayout';
import PermitContentBlock from '../../organisms/PermitContentBlock';
import ActionPanelPermit from '../../organisms/ActionsPanelPermit';
import PermitMenu from '../../organisms/PermitMenu';
import { GET_ALL_QUERY_PARAMS } from '../../constants';
import {
  LoaderWithContainer,
  ModalDefaultDraggable,
} from '../../molecules';
import PermitForm from '../../forms/PermitForm';
import { USER_TYPES_VALUES } from '../../constants/forms';
import useTablePage from '../../hooks/useTablePage';
import UsersForm from '../../forms/UsersForm';
import SubPermitMenu from '../../organisms/SubPermitMenu';
import AddPermitForm from '../../forms/AddPermitForm';
import { ROUTES } from '../../constants/routes';
import ModalInfo from '../../molecules/ModalInfo';
import PermitViewContacts from '../../organisms/PermitViewContacts';
import useDidUpdateEffect from '../../hooks/useDidUpdate';
import DownloadingPopup from '../../organisms/DownloadAllInspectionsForms/DownloadingPopup';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/root';
import { setIsViewContactsModal, setPermitContacts, setSelectedPermit } from '../../redux/slices/projectSlice';
import { useEffect, useState } from 'react';
import { PERMIT_MENU_ACTIVE_TAB } from '../../constants/permitMenu';
import { someDocumentsSelected } from '../../utils/documentsUtil';
import { downloadAllPermitDocumentsCommaSeparatedIds } from '../../api/projectDocuments';
import { DOCUMENTS_TASK_SHEET, setHistoryCameFrom } from '../../redux/slices/documentsSlice';
import SubPermitMenuPartialPayment from '../../organisms/SubPermitMenuPartialPayment';

const processes = [
  {
    title: 'New',
    subProcesses: [{ title: 'New', id: 23 }],
    statuses: [23],
  },
  {
    title: 'Proposal',
    subProcesses: [
      { title: 'Proposal Sent', id: 24 },
      { title: 'Proposal Approved', id: 25 },
    ],
    statuses: [24, 25],
  },
  {
    title: 'In Progress',
    subProcesses: [
      { title: 'Review Approved', id: 26 },
      { title: 'Permit Submitted', id: 27 },
      { title: 'Permit Issued', id: 28 },
      { title: 'Inspection Started', id: 29 },
      { title: 'Payment Pending', id: 30 },
      { title: 'Hold', id: 32 },
    ],
    statuses: [26, 27, 28, 29, 30, 32],
  },
  {
    title: 'Completed',
    subProcesses: [
      { title: 'Completed', id: 31 },
      { title: 'Withdrawn', id: 33 },
    ],
    statuses: [31, 33],
  },
];

const PermitPage = ({searchPermitId = null, searchProjectId = null}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [isAddDocument, setAddDocument] = React.useState(
    false,
  );
  const [showReviewCommentLtr, setShowReviewCommentLtr] = React.useState(false);
  
  const [
    isDownloadAllDocs,
    setDownloadAllDocs,
  ] = React.useState(false);
  const [
    isDocsAvailable,
    setDocsAvailable,
  ] = React.useState(false);
  const [
    isDocsWaitingOnClient,
    setDocsWaitingOnClient,
  ] = React.useState(false);
  const [
    disableSend,
    setDisableSend,
  ] = React.useState(false);
  const [isSendDocs, setSendDocs] = React.useState(false);
  const [
    selectedDocWork,
    setSelectedDocWork,
  ] = React.useState(null);
  const [
    selectedDocBuilding,
    setSelectedDocBuilding,
  ] = React.useState(null);
  const [activityTypes, setActivityTypes] = React.useState(
    [],
  );
  const [activityValue, setActivityValue] = React.useState(
    null,
  );
  const [
    activityCreated,
    setActivityCreated,
  ] = React.useState(false);

  const [createdPermit, setCreatedPermit] = React.useState(
    false,
  );
  const [isLocked,setLocked] =React.useState(false);
  const selectedPermit = useSelector((s:RootState) => s.projectsSlice.selectedPermit);
  const historyCameFrom = useSelector((s:RootState) => s.documents.historyCameFrom);
  const showPartialPaymentPopup = useSelector((s:RootState) => s.fees.showPartialPaymentPopup);
  const [cameFromSearch, setCameFromSearch] = React.useState(false)
  const [disableReviewCommentBtn, setDisableReviewCommentBtn] = React.useState(false)
  const isPermitCompleted = () => {
    return (initialValues?.status?.label === "Completed" || initialValues?.status?.label === "Withdrawn") ? true : false
  }
  const history = useHistory();

  const { permitId, projectId } = useParams();

  let [permitGlobalSearch, setPermitGlobalSearch] = React.useState(false)

  useEffect(() => {
    if(historyCameFrom === DOCUMENTS_TASK_SHEET){
      dispatch(setHistoryCameFrom(''));
      setActiveTab(PERMIT_MENU_ACTIVE_TAB.DOCUMENTS);
    }
    
  }, [historyCameFrom])
  React.useEffect(() =>{
    if (history.location?.state?.from === 'searchIntoFees'){
      setActiveTab(PERMIT_MENU_ACTIVE_TAB.FEES);
    } else if (history.location?.state?.from === 'globalSearchPage') {
      setPermitGlobalSearch(true)
    } else {
      setPermitGlobalSearch(false)
    }

    const loadPermitById = async () => {
      const result = await getPermitByID(permitId);
      //@ts-ignore
      dispatch(setSelectedPermit(result.data))
    }
    loadPermitById();
  }, [])
  
React.useEffect(() => {
  Promise.all([getPermitByID(permitId)]).then(([permitRes]) => {
    if(permitGlobalSearch && (permitRes?.data?.status === 31 || permitRes?.data?.status === 33)) {
      setCameFromSearch(true)      
    } else {
      setCameFromSearch(false)
    }
    dispatch(setPermitContacts(permitRes.data.permitContacts))
  })
}, [selectedPermit])

  const [
    isProjectLoading,
    setProjectLoading,
  ] = React.useState(false);
  const dispatch = useDispatch();
  const [permits, setPermits] = React.useState(null);
  const [managerData, setManagerData] = React.useState(
    null,
  );
  
  const [permitStatus, setPermitStatus] = React.useState(
    null,
  );
  const [serviceTypes, setServiceTypes] = React.useState(
    null,
  );
  const [workTypes, setWorkTypes] = React.useState(null);
  const [buildingTypes, setBuildingTypes] = React.useState(
    null,
  );
  const [initialValues, setInitialValues] = React.useState(
    null,
  );
  const [
    isContactUpdated,
    setContactUpdated,
  ] = React.useState(false);
  const [allContacts, setAllContacts] = React.useState(
    null,
  );
  const [isFormSaved, setFormSaved] = React.useState(false);
  const [contactTypes, setContactTypes] = React.useState(
    null,
  );
  const [activeTab, setActiveTab] = React.useState(PERMIT_MENU_ACTIVE_TAB.ACTIVITIES);
  const [activityType, setActivityType] = React.useState(
    null,
  );

  const [
    isContactFormSubmitting,
    setIsContactFormSubmitting,
  ] = React.useState(false);

  const [
    isOpenAddPermitModal,
    setOpenAddPermitModal,
  ] = React.useState(false);

  const [isAddNote, setAddNote] = React.useState(false);
  const [isAddTicket, setAddTicket] = React.useState(false);
  const [
    isAddInspection,
    setAddInspection,
  ] = React.useState(false);
  const [isAddFees, setAddFees] = React.useState(false);
  const [isFeeSelected, setFeeSelected] = React.useState(
    false,
  );
  const [
    isSendingSelectedFees,
    setIsSendingSelectedFees,
  ] = React.useState(false);
  const [isAddCondition, setAddCondition] = React.useState(
    false,
  );

  const [documentsData, setDocumentsData] = React.useState(
    null
  );
  const isViewContactsModal = useSelector((s:RootState)=>s.projectsSlice.isViewContactsModal);

  const {
    createOrUpdate,
    isModalOpen,
    handleAdd,
    modalTitle,
    modalError,
    modalSuccess,
    modalErrorTitle,
    handleCloseModal,
    handleCloseModalInfo,
    values,
    errors,
  } = useTablePage({
    createEndpoint: createUser,
    updateEndpoint: updatePermit,
    modalCreateTitle: 'Add Contact',
  });

  const handleCloseForm = () => {
    if (!modalError && !modalSuccess) {
      handleCloseModal();
    }
  };

  const handleCloseAddPermitModal = () => {
    setOpenAddPermitModal(false);
  };

  const tabs = [
    {
      id: PERMIT_MENU_ACTIVE_TAB.ACTIVITIES,
      title: 'Activities',
      action: () => setActiveTab(PERMIT_MENU_ACTIVE_TAB.ACTIVITIES),
    },
    {
      id: PERMIT_MENU_ACTIVE_TAB.INSPECTIONS,
      title: 'Inspections',
      action: () => setActiveTab(PERMIT_MENU_ACTIVE_TAB.INSPECTIONS),
    },
    {
      id: PERMIT_MENU_ACTIVE_TAB.FEES,
      title: 'Fees',
      action: () => setActiveTab(PERMIT_MENU_ACTIVE_TAB.FEES),
    },
    {
      id: PERMIT_MENU_ACTIVE_TAB.NOTES,
      title: 'Notes',
      action: () => setActiveTab(PERMIT_MENU_ACTIVE_TAB.NOTES),
    },
    {
      id: PERMIT_MENU_ACTIVE_TAB.TICKETS,
      title: 'Tickets',
      action: () => setActiveTab(PERMIT_MENU_ACTIVE_TAB.TICKETS),
    },
    {
      id: PERMIT_MENU_ACTIVE_TAB.DOCUMENTS,
      title: 'Documents',
      action: () => setActiveTab(PERMIT_MENU_ACTIVE_TAB.DOCUMENTS),
    },
    {
      id: PERMIT_MENU_ACTIVE_TAB.CONDITIONS,
      title: 'Conditions',
      action: () => setActiveTab(PERMIT_MENU_ACTIVE_TAB.CONDITIONS),
    },
  ];

  const getInitialServiceTypes = (
    allTypes,
    currentTypes,
  ) => {
    if (currentTypes) {
      const resultTypes = [];
      currentTypes.forEach(el => {
        const type = allTypes.find(
          typeEl => typeEl.id === el,
        );
        resultTypes.push(type);
      });
      return resultTypes;
    }
    return null;
  };

  const getPermitContacts = (
    permitContacts,
    availableContacts,
  ) => {
    if (permitContacts) {
      const resultContacts = [];
      const permitUserIds = permitContacts.map(
        contact => contact.user,
      );
      availableContacts.forEach(
        contact =>
          permitUserIds.includes(contact.id) &&
          resultContacts.push(contact),
      );
      return resultContacts;
    }
    return null;
  };

  const createInitialValues = (
    permit,
    project,
    currManager,
  ) => {    
    return {
      name: permit.name,
      number: permit.number,
      total: permit.amount,
      expirationDate: permit?.expirationDate,
      square: permit.squareFeet,
      inspections: permit.inspectionsCount,
      storageBox: permit.storageBox,
      jurisdiction: project.jurisdictionName,
      documents: permit?.documents,
      address: project.address,
      description: permit.description,
      info: permit.info || '',
      status: permitStatus
        ?.filter(el => el.id === permit.status)
        .map(el => ({
          value: el.id?.toString(),
          label: el?.title,
        }))[0],
      // permitContacts: getPermitContacts(
      //   permit.permitContacts,
      //   allContacts,
      // ),
      serviceType: getInitialServiceTypes(
        serviceTypes,
        permit.serviceType,
      ),
      manager: currManager
        ? `${currManager.firstName} ${currManager.lastName}`
        : project.managerName,
      work: permit.work
        ? workTypes
            .filter(type => type.id === permit.work)
            .map(el => ({
              value: el.id?.toString(),
              label: el?.title,
            }))[0]
        : null,
      building: permit.building
        ? buildingTypes
            .filter(type => type.id === permit.building)
            .map(el => ({
              value: el.id?.toString(),
              label: el?.title,
            }))[0]
        : null,
      permitIssued: permit.permitIssued,
    };
  };

  React.useEffect(() => {
    setIsLoading(true);
    Promise.all([
      getProjectByID(projectId),
      getPermitByID(permitId),
      getContactTypeList({
        ...GET_ALL_QUERY_PARAMS,
      }),
      getVocabulariesDataList({
        ...GET_ALL_QUERY_PARAMS,
        ordering: 'title',
      }), // permit statuses
     
    ])
      .then(
        ([
          projectResponse,
          permitsResponse,
          contactTypesResponse,
          permitStatusResponse,
        ]) => {
          setPermits([
            ...projectResponse.data.permits,
            {
              name: 'Add new Permit',
              id: Math.random(),
              button: true,
            },
          ]);
          //@ts-ignore
          dispatch(setSelectedPermit(permitsResponse.data));
          setContactTypes(
            contactTypesResponse.data.results?.filter(
              el => el?.isActiveStatus === 'True',
            ),
          );
          setPermitStatus(
            permitStatusResponse.data.results.filter(
              el => el?.vocabularyEntity === 6,
            ),
          );
          setServiceTypes(
            permitStatusResponse.data.results?.filter(
              el =>
                el?.isActive && el?.vocabularyEntity === 5,
            ),
          );
          setWorkTypes(
            permitStatusResponse.data.results?.filter(
              el =>
                el?.isActive && el?.vocabularyEntity === 2,
            ),
          );
          setBuildingTypes(
            permitStatusResponse.data.results?.filter(
              el =>
                el?.isActive && el?.vocabularyEntity === 1,
            ),
          );
          setAllContacts([])
        },
      )
      .catch(() => {})
      .finally(() => setIsLoading(false));
  }, []);

  useDidUpdateEffect(() => {
    if (isContactUpdated) {
      Promise.all([
        getUsers({
          ...GET_ALL_QUERY_PARAMS,
          userRole: USER_TYPES_VALUES.CLIENT,
        }), // individuals
      ]).then(([individualContactsResponse]) =>
        // companyContactsResponse,
        setAllContacts([
          ...individualContactsResponse.data.results?.filter(
            el =>
              el?.isActive &&
              (el?.clientType === 2 ||
                el?.clientType === 1),
          ),
        ]),
      );
    }
  }, [isContactUpdated]);

  useDidUpdateEffect(() => {
    if (createdPermit) {
      setIsLoading(true);
      Promise.all([
        getProjectPermits(projectId),
      ])
        .then(([permitsResponse]) => {          
          setInitialValues(null);
          setPermits([
            ...permitsResponse.data.filter(
              el =>
                Number(el.project) ===
                Number(projectId),
            ),
            {
              name: 'Add new Permit',
              id: Math.random(),
              button: true,
            },
          ]);
          const newPermit = permitsResponse.data.find(
            el =>
              el.id ===
              Math.max.apply(
                null,
                permitsResponse.data.map(
                  permit => permit.id,
                ),
              ),
          );
          dispatch(setSelectedPermit(newPermit));
          const path = generatePath(ROUTES.PERMIT, {
            projectId: projectId,
            permitId: newPermit.id,
          });
          history.push(path);
        })
        .catch(() => {})
        .finally(() => {
          setIsLoading(false);
          setCreatedPermit(false);
          window.location.reload()
        });
    }
  }, [createdPermit]);

  React.useEffect(() => {
    if (isFormSaved) {
      setIsLoading(true);
      Promise.all([
        getProjectPermits(projectId),
      ])
        .then(([permitsResponse]) => {          
          if (projectId) {
            setPermits([
              ...permitsResponse.data.filter(
                el =>
                  Number(el.project) ===
                  Number(projectId),
              ),
              {
                name: 'Add new Permit',
                id: Math.random(),
                button: true,
              },
            ]);
            dispatch(setSelectedPermit(
              permitsResponse.data.find(
                el =>
                  Number(el.id) ===
                  Number(permitId),
              ),
            ));
          }
        })
        .catch(() => {})
        .finally(() => {
          setIsLoading(false);
          setFormSaved(false);
          window.location.reload()
        });
    }
  }, [isFormSaved]);

  useDidUpdateEffect(() => {
    if (
      selectedPermit &&
      selectedPermit?.project &&
      allContacts
    ) {
      setProjectLoading(true);

      Promise.all([
        getProjectByID(+selectedPermit.project),
        getUsers({
          ...GET_ALL_QUERY_PARAMS,
          userRole: USER_TYPES_VALUES.EMPLOYEE,
        }),
      ])
        .then(([projectResponse, managersResponse]) => {
          const currManager = managersResponse.data.results.find(
            el => el?.id === selectedPermit?.manager,
          );
          const initialData = createInitialValues(
            selectedPermit,
            projectResponse.data,
            currManager,
          );
          setInitialValues(initialData);
          dispatch(setSelectedPermit(initialData))
        })
        .catch((e) => {console.log(e)})
        .finally(() => setProjectLoading(false));
    }
  }, [selectedPermit, allContacts]);

  const handlePermitSelect = selected => {
    if (selected?.id === selectedPermit?.id) {
      return null;
    }
    if (selected.button) {
      dispatch(setSelectedPermit(selectedPermit));
      setOpenAddPermitModal(true);
    }
    if (selected && !selected.button) {
      setInitialValues(null);
      dispatch(setSelectedPermit(selected));
      const path = generatePath(ROUTES.PERMIT, {
        projectId: projectId,
        permitId: selected.id,
      });
      history.push(path);
    }
    if (!selected) {
      dispatch(setSelectedPermit(null));
    }
    return null;
  };

  const onActivityChange = selectedActivity => {
    setActivityType(selectedActivity);
  };

  const handleCloseViewContactsForm = () => {
    dispatch(setIsViewContactsModal(false));
  };
  const handleDocumentData=(data)=>{
    setDocumentsData(data)
  }
  const showMergingForms = useSelector((s:RootState) => s.inspections.showMergingForms);
  const mcheckedBoxes = useSelector((s:RootState) => s.documents.mcheckedBoxes);
  const downloadAllDocs = async () => {
    const documents = Object.keys(mcheckedBoxes).reduce((idArr,key) => {
      if(mcheckedBoxes[key]){
        idArr.push(key);
      }
      return idArr;
    },[]).join(',');

    const result = await downloadAllPermitDocumentsCommaSeparatedIds(documents);
    let fileName = '';
    try {
      fileName = result.data.contentDisposition.split("filename=")[1].replace(/"/g,'');
    } catch(e){
      fileName = 'files.zip';
    }
    
    const binaryData = atob(result.data.file);
    const uint8Array = new Uint8Array(binaryData.length);
    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }

    const blob = new Blob([uint8Array], {type: 'application/zip'});
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();
  }
  return (
    <DashboardLayout overY>
      {isLoading && <LoaderWithContainer />}
      {!isLoading && selectedPermit && (
        <>
          <div style={{ width: '100%' }}>
            <ActionPanelPermit
              permits={permits}
              permitSelect={handlePermitSelect}
              selectedPermit={selectedPermit}
              processes={processes}
              currentStatus={selectedPermit.status}
            />
          </div>
          <PermitMenu tabs={tabs} activeTab={activeTab} />
          <div style={{ width: '100%' }}>
          {showMergingForms && <DownloadingPopup />}
          {showPartialPaymentPopup && <SubPermitMenuPartialPayment />}
            <SubPermitMenu
              activeTab={activeTab}
              onActivityChange={onActivityChange}
              permit={permitId}
              selectedPermit={initialValues}
              setAddNote={setAddNote}
              setAddTicket={setAddTicket}
              setAddInspection={setAddInspection}
              setAddFees={setAddFees}
              setActivityCreated={setActivityCreated}
              setAddCondition={setAddCondition}
              isFeeSelected={isFeeSelected}
              setIsSendingSelectedFees={
                setIsSendingSelectedFees
              }
              workTypes={workTypes}
              buildingTypes={buildingTypes}
              selectedWork={selectedDocWork}
              selectWork={setSelectedDocWork}
              selectedBuilding={selectedDocBuilding}
              selectBuilding={setSelectedDocBuilding}
              setActivityTypes={setActivityTypes}
              activityTypes={activityTypes}
              setActivityValue={setActivityValue}
              activityValue={activityValue}
              setAddDocument={setAddDocument}
              setDownloadAllDocs={setDownloadAllDocs}
              isDocsAvailable={someDocumentsSelected(mcheckedBoxes)}
              downloadAllDocs={downloadAllDocs}
              isDocsWaitingOnClient={isDocsWaitingOnClient}
              disableSend={disableSend}
              setSendDocs={setSendDocs}
              cameFromSearch={cameFromSearch || isPermitCompleted()}
              setLocked={setLocked}
              isLocked={isLocked}
              documentsData={documentsData}
              disableReviewCommentBtn={disableReviewCommentBtn}
              setShowReviewCommentLtr={setShowReviewCommentLtr}
            />
          </div>
        </>
      )}
      
      
      {!isProjectLoading && !isLoading && (
        <>
          <PermitForm
            initialValues={initialValues}
            selectedPermit={selectedPermit}
            projectId={projectId}
            permitStatus={permitStatus}
            allContacts={allContacts}
            serviceTypes={serviceTypes}
            workTypes={workTypes}
            buildingTypes={buildingTypes}
            setFormSaved={setFormSaved}
            isLoading={isLoading}
            contactTypes={contactTypes}
            setIsContactsModalOpen={handleAdd}
            onSubmit={createOrUpdate}
            cameFromSearch={cameFromSearch || isPermitCompleted()}
          />
          <PermitContentBlock
            projectId={projectId}
            permit={permitId}
            permitStatus={selectedPermit?.status}
            activeTab={activeTab}
            activityType={activityType}
            isAddNote={isAddNote}
            setAddNote={setAddNote}
            isAddTicket={isAddTicket}
            setAddTicket={setAddTicket}
            setAddInspection={setAddInspection}
            isAddFees={isAddFees}
            isAddInspection={isAddInspection}
            setAddFees={setAddFees}
            activityCreated={activityCreated}
            setActivityCreated={setActivityCreated}
            isAddCondition={isAddCondition}
            setAddCondition={setAddCondition}
            setFeeSelected={setFeeSelected}
            isSendingSelectedFees={isSendingSelectedFees}
            setIsSendingSelectedFees={
              setIsSendingSelectedFees
            }
            selectedBuilding={selectedDocBuilding}
            selectedWork={selectedDocWork}
            isAddDocument={isAddDocument}
            setAddDocument={setAddDocument}
            setDocsAvailable={setDocsAvailable}
            setDocsWaitingOnClient={setDocsWaitingOnClient}
            setDisableSend={setDisableSend}
            isDownloadAllDocs={isDownloadAllDocs}
            setDownloadAllDocs={setDownloadAllDocs}
            isSendDocs={isSendDocs}
            setSendDocs={setSendDocs}
            cameFromSearch={cameFromSearch || isPermitCompleted()}
            isLocked={isLocked}
            handleDocumentData={handleDocumentData}
            setDisableReviewCommentBtn={setDisableReviewCommentBtn}
            showReviewCommentLtr={showReviewCommentLtr}
            setShowReviewCommentLtr={setShowReviewCommentLtr}
          />
        </>
      )}

      {isViewContactsModal && (
        <StyledModalDraggable
          onClose={handleCloseViewContactsForm}
          title="Permit Contacts"
          extraWide
        >
          <PermitViewContacts
            permit={permitId}
            selectedPermit={selectedPermit}
            setContactUpdated={setContactUpdated}
            cameFromSearch={cameFromSearch || isPermitCompleted()}
          />
        </StyledModalDraggable>
      )}

      {isOpenAddPermitModal && (
        <StyledModalDraggable
          onClose={handleCloseAddPermitModal}
          title="Add Permit"
        >
          <AddPermitForm
            allContacts={allContacts}
            permitStatus={permitStatus}
            serviceTypes={serviceTypes}
            buildingTypes={buildingTypes}
            workTypes={workTypes}
            contactTypes={contactTypes}
            managersData={[]}
            setIsContactFormSubmitting={
              setIsContactFormSubmitting
            }
            selectedProjectId={projectId}
            close={handleCloseAddPermitModal}
            setCreatedPermit={setCreatedPermit}
          />
        </StyledModalDraggable>
      )}

      {isModalOpen && (
        <StyledModalDraggable
          onClose={handleCloseForm}
          title={modalTitle}
        >
          <UsersForm
            setIsContactFormSubmitting={
              setIsContactFormSubmitting
            }
            initialValues={values}
            modalError={modalErrorTitle.length > 0}
            initialErrors={errors}
            onSubmit={createOrUpdate}
          />
        </StyledModalDraggable>
      )}

      {(modalSuccess || modalError) && (
        <ModalInfo
          timeToClose={5000}
          isError={modalError}
          onClose={handleCloseModalInfo}
          errorMsg={modalErrorTitle}
        />
      )}
    </DashboardLayout>
  );
};

const StyledModalDraggable = styled(ModalDefaultDraggable)`
  height: 100%;
  overflow: inherit;
`;

export default PermitPage;
