import * as React from 'react';
import { yupResolver } from '@hookform/resolvers';
import { useForm, Controller } from 'react-hook-form';
import styled from 'styled-components';
import { addDays, format } from 'date-fns';
import * as yup from 'yup';
import { DeleteOutline } from '@material-ui/icons';
import {
  ButtonWithLoader,
  CheckboxWithLabel,
  InputBaseContainer,
  InputDatePickerWithLabel,
  InputSelectWithLabel,
  InputTextWithLabel,
  ModalDefaultDraggable,
  TextAreaWithLabel
} from '../../molecules';

import CommentsChat from '../../organisms/CommentsChat';

import { FormServerError } from '../../types';
import {
  COMMENTS_DATETIME_FORMAT,
  DEFAULT_DATE_FORMAT,
  GET_ALL_QUERY_PARAMS,
  InspectionStatuses,
} from '../../constants';

import { formatDateTime } from '../../utils/formatDate';

import { getCurrentUser } from '../../api/userSettings';
import {
  createPermitInspectionsComment,
  getPermitInspectionById,
  getPermitInspectionsComments,
  sendInspectionMessage,
} from '../../api/inspections';
import isYupFieldRequired from '../../utils/isYupFieldRequired';
import {
  getActivitiesList,
  getActivitiesStatusList,
  getCustomActivities,
} from '../../api/activities';
import {
  deleteInspectionReport,
  downloadInspectionReport,
  getInspectionEditTypes,
  getInspectionTypeByID,
  getInspectionTypes,
  getInspectionTypesCustom,
  uploadInspectionReport,
} from '../../api/inspectionTypes';
import { getTrades } from '../../api/trades';
import { getPermitUsers, getUserByID, getUsers, getUsersCustom } from '../../api/users';
import parseUTCDate from '../../utils/parseUTCDate';
import { USER_TYPES_VALUES } from '../../constants/forms';
import { getPermitByID } from '../../api/permits';
import { getProjectByID, getProjects } from '../../api/projects';
import InspectionsInfoBlock from '../../organisms/InspectionsInfoBlock';
import PermitViewContacts from '../../organisms/PermitViewContacts';
import setInspectionData from '../../utils/setInspectionData';
import { Button } from '@material-ui/core';
import useUserRole from '../../hooks/useUserRole';
import ModalInfo from '../../molecules/ModalInfo';
import ReactInputMask from 'react-input-mask';
import useDevice from '../../hooks/useDevice';
import { useEffect, useRef, useState } from 'react';
import DesktopAddReport from './DesktopAddReport';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/root';
import { setModalOpen, setPermitInspectionsList, setShowSuccessAndPrint } from '../../redux/slices/inspectionsSlice';
import { InspectorSet } from '../../utils/InspectorSetTools';
import StatusChangeConfirmPopup from './StatusChangeConfirmPopup';
import { setPromptMessage, setShowFormPopup } from '../../redux/slices/InspectionsReportSlice';

const labels = {
  activityTitle: 'Activity Title',
  inspectionType: 'Inspection Type',
  information: 'Information',
  trades: 'Trades',
  activityContact: 'Activity Contact',
  inspectionDate: 'Inspection Date',
  activityStatus: 'Activity Status',
  period: 'AM/PM',
  inspector: 'Inspector',
  followUp: 'Follow-up date',
  assignedTo: 'Assigned to',
  emergency: 'Emergency',
  isCompleted: 'Completed',
  activityContactCheckbox: 'Activity Contact',
  jurisdictionContacts: 'Jurisdiction',
  projectContacts: 'Project Contacts',
  additionalContacts: 'More Contacts',
  cellPhone: 'Cell Phone',
  phoneNumber: 'Phone Number',
  dateCreated: "Date Created",
  createdBy: "Created By",
  dateCompleted: "Date Completed",
  completedBy: "Completed By",
};

const periodData = [
  { name: <p></p>, id: 2},
  { name: 'AM', id: 0 },
  { name: 'PM', id: 1 },
];

const schemaAdd = yup.object().shape({
  activityTitle: yup
    .object()
    .required()
    .typeError('Select a Activity Title from the list')
    .label(labels.activityTitle),
  inspectionType: yup
    .array()
    .of(yup.object())
    .required()
    .typeError('Select a Activity Type from the list')
    .label(labels.inspectionType),
  information: yup
    .string()
    .nullable()
    .label(labels.information),
  // ############
  // mritunjoy
  // EA-56
  // Cell Phone number field in the inspection form
  cellPhone: yup
    .string()
    .test('len', 'Invalid Cell Number', (val => val.split(" ").join("").length === 12 || val.split(" ").join("").length === 0))
    // .required()
    .nullable()
    .label(labels.cellPhone),
  phoneNumber: yup
    .string()
    .test('len', 'Invalid Phone Number', (val => val.split(" ").join("").length === 12 || val.split(" ").join("").length === 0))
    // .required()
    .nullable()
    .label(labels.phoneNumber),

  // mritunjoy
  // EA-56
  // Cell Phone number field in the inspection form
  // ############

  // removing trades field as per EA-215 ticket
  // mritunjoy
  // trades: yup
  //   .array()
  //   .of(yup.object())
  //   .required()
  //   .typeError('Select a Trade from the list')
  //   .label(labels.trades),
  activityContact: yup
    .object()
    .shape({
      value: yup.string(),
      label: yup.string(),
    })
    .nullable()
    // .required()
    // .typeError('Select a Activity Contact from the list')
    .label(labels.activityContact)
    .when(["cellPhone", "phoneNumber"], (cellPhone, phoneNumber, schema) => {
      if(cellPhone.length || phoneNumber.length)
        return schema.required("Must Select Activity Contact")
      return schema
    }),
  emergency: yup.boolean().label(labels.emergency),
});

const schemaEdit = yup.object().shape({
  activityTitle: yup
    .object()
    .shape({
      value: yup.string(),
      label: yup.string(),
    })
    .required()
    .typeError('Select a Activity Title from the list')
    .label(labels.activityTitle),
  inspectionType: yup
    .mixed()
    .notOneOf(
      ['', null, undefined, [], {}],
      'Inspection Type is a required field',
    )
    .required()
    .typeError('Select a Activity Type from the list')
    .label(labels.inspectionType),
  activityStatus: yup
    .object()
    .shape({
      value: yup.string(),
      label: yup.string(),
    })
    .required()
    .typeError('Select a Activity Status from the list')
    .label(labels.activityStatus),
  // inspectionDate: yup.date().label(labels.inspectionDate),
  inspectionDate: yup.mixed().notOneOf([[], {}]),
  period: yup.object().nullable().label(labels.period),
  inspector: yup
    .object()
    .nullable()
    .label(labels.inspector),
  activityContact: yup
    .object()
    .nullable()
    .label(labels.activityContact),
  information: yup
    .string()
    .nullable()
    .label(labels.information),
  // ############
  // mritunjoy
  // EA-56
  // Cell Phone number field in the inspection form
  cellPhone: yup
  .string()
  .when('activityStatus', {
    is: (selectedStatus) => selectedStatus?.name !== InspectionStatuses.NOT_SCHEDULED && selectedStatus?.name !== "Void" && selectedStatus?.name !== InspectionStatuses.NOT_APPLICABLE && selectedStatus?.name !== "Cancelled",
    then: yup.string().required().nullable(),
    otherwise: yup.string().nullable(),
  })
  .label(labels.cellPhone),
phoneNumber: yup
  .string()
  .when('activityStatus', {
    is: (selectedStatus) => selectedStatus?.name !== InspectionStatuses.NOT_SCHEDULED && selectedStatus?.name !== "Void" && selectedStatus?.name !== InspectionStatuses.NOT_APPLICABLE && selectedStatus?.name !== "Cancelled",
    then: yup.string().required().nullable(),
    otherwise: yup.string().nullable(),
  })
  .label(labels.phoneNumber),

  // mritunjoy
  // EA-56
  // Cell Phone number field in the inspection form
  // ############
  followUp: yup.mixed().notOneOf([[], {}]),
  assignedTo: yup
    .object()
    .nullable()
    .label(labels.assignedTo),

  emergency: yup.boolean().label(labels.emergency),
  isCompleted: yup.boolean().label(labels.isCompleted),
  activityContactCheckbox: yup
    .boolean()
    .label(labels.activityContactCheckbox),
  jurisdictionContacts: yup
    .boolean()
    .label(labels.jurisdictionContacts),
  projectContacts: yup
    .boolean()
    .label(labels.projectContacts),
  additionalContacts: yup
    .array()
    // .when('isCompleted', {
    //   is: isCompleted => isCompleted,
    //   then: yup.array().of(yup.object()).required(),
    // })
    .nullable()
    .label(labels.additionalContacts),
  hiddenCheck: yup
    .boolean()
    .oneOf([true], 'required')
    .required(),
});

const PermitInspectionsForm = ({
  onSubmit,
  initialValues,
  modalError,
  addButtonText = 'Add new Inspection',
  updateButtonText = 'Update the Inspection',
  initialErrors,
  permit,
  permitStatus,
  allActivities,
  isReadOnly,
  isSimulateDeleted,
  setInitialValues,
  updateTable,
  cameFromSearch,
  projectId,
}: Props) => {    
  const dispatch = useDispatch();
  const dailyInspections = useSelector((s:RootState) => s.inspections.permitInspectionsList);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modalSuccess, setModalSuccess] = React.useState(false);
  const selectedPermit = useSelector((s:RootState) => s.projectsSlice.selectedPermit);

  const toDateUTC =
    // eslint-disable-next-line no-nested-ternary
    initialValues?.inspectionDate ? new Date(initialValues?.inspectionDate) : '';

  const followUpUTC =
    // eslint-disable-next-line no-nested-ternary
    initialValues?.followUp ? new Date(initialValues?.followUp) : '';

  // UseState items for InputSelect
  const [titlesData, setTitlesData] = React.useState([]);
  const [statusData, setStatusData] = React.useState([]);
  
  const [
    inspectionsTypeData,
    setInspectionTypeData,
  ] = React.useState([]);

  const [tradesData, setTradesData] = React.useState([]);

  const [usersData, setUsersData] = React.useState([]);  

  const [
    inspectorsData,
    setInspectorsData,
  ] = React.useState([]);

  const [assignedData, setAssignedData] = React.useState(
    [],
  );
  
  const [
    additionalContactsData,
    setAdditionalContactsData,
  ] = React.useState([]);

  const [isSubmitting, setIsSubmitting] = React.useState<
    boolean
  >(false);
  const [selectedStatusValue,setSelectedStatus] = useState<string>('');
  // /useState items for InputSelect

  const [
    inspectionTrades,
    setInspectionTrades,
  ] = React.useState(null);

  const [
    checkboxesError,
    setCheckboxesError,
  ] = React.useState('');

  const [readOnlyInfo, setReadOnlyInfo] = React.useState(
    [],
  );

  const [
    isViewContactsModal,
    setIsViewContactsModal,
  ] = React.useState(false);

  const [
    currentProject,
    setCurrentProject,
  ] = React.useState(null);
  const [modalErr, setModalErr] = React.useState(false)
  const [holdSubmit, setHoldSubmit] = React.useState(false)
  const [conditionHoldSubmit, setConditionHoldSubmit] = React.useState(false)
  const [holdModalOpen, setHoldModalOpen] = React.useState(false)
  const [permitData, setPermitData] = React.useState(null)
  const [conditionHoldModalOpen, setConditionHoldModalOpen] = React.useState(null)
  const showFormPopup = useSelector((s:RootState) => s.inspectionReports.showFormPopup);
  const isDeleted =
    initialValues?.isDeleted || isSimulateDeleted;

  const {
    handleSubmit,
    errors,
    register,
    control,
    formState,
    watch,
    setValue,
    getValues
  } = useForm({
    resolver: yupResolver(
      initialValues ? schemaEdit : schemaAdd,
    ),
    defaultValues: initialValues
      ? {
          ...initialValues,
          toDate: toDateUTC,
          followUp: followUpUTC,
        }
      : {},
  });

  const [isIpad]= useDevice()
  const selectedStatus = watch('activityStatus');
  const selectedType = watch('inspectionType');
  const selectedTitle = watch('activityTitle');
  const isInspectionCompleted = watch('isCompleted');
  const newInformation = watch('information');
  let activityContactCheckbox = ''
  let jurisdictionCheckbox = ''
  let projectContactsCheckbox = ''
  
  useEffect(() => {
    if(selectedStatus){
      if(initialValues.statusName === InspectionStatuses.SCHEDULED && selectedStatus.name !== InspectionStatuses.SCHEDULED){
        setValue('inspector', undefined);
      }
      if(initialValues.statusName === 'Not Scheduled' && selectedStatus.name === 'Scheduled'){      
        const _permitStatus: any = selectedPermit.status;
        if(_permitStatus.label === 'Hold'){
           dispatch(setPromptMessage('Permit Status is HOLD.'));
           dispatch(setShowFormPopup(true));
        } else if(isConditionOnHold) {
          dispatch(setPromptMessage('There is a condition with either an Inspection Hold or Permit Hold.'));
          dispatch(setShowFormPopup(true));
        }        
      }
    }
  },[selectedStatus])
  useEffect(() => {
    let _selectedType = selectedType;
    if(typeof selectedType === 'object') {
      _selectedType = selectedType.name;
    }

    if(initialValues && initialValues.statusName === InspectionStatuses.SCHEDULED) {
      if(_selectedType !== initialValues.activityTypeName){
        setValue('inspector', null);
      }
    }
    
  },[selectedType])
  const undoStatusChange = () => {
    const status = {
      activity: 11,
      id: 27,
      isActiveStatus: "True",
      isDeleted: false,
      name: InspectionStatuses.NOT_SCHEDULED
    }
    setValue('activityStatus',status);
  }
  const IsInspectionTypeDisabled = ():boolean => {
    if(inspectionsTypeData.length === 0) {
      return true;
    }
    if(selectedStatus){
      return !(selectedStatus.name === InspectionStatuses.SCHEDULED || selectedStatus.name === InspectionStatuses.NOT_SCHEDULED);
    }
    return isLoading || 
           isReadOnly || 
           initialValues?.isCompleted || 
           cameFromSearch || 
           !inspectionsTypeData.length;
 }
  if(!isIpad) {
    activityContactCheckbox = watch(
      'activityContactCheckbox',
    );
    jurisdictionCheckbox = watch(
      'jurisdictionContacts',
    );
    projectContactsCheckbox = watch('projectContacts');
  }
  
  const toDateValue = watch('toDate');
  const [errorReport, setErrorReport] = React.useState("");
  const [commentCreated, setCommentCreated] = React.useState(null);
  const [isConditionOnHold, setIsConditionOnHold] = React.useState(false)
  const [inspectionPrompt, setInspectionPrompt] = React.useState(false)
  const [conditionPrompt, setConditionPrompt] = React.useState(false)

  const setPromptValue = (inspectionHold,conditionHold) => {
    setInspectionPrompt(inspectionHold);
    setConditionPrompt(conditionHold)
  }
  React.useEffect(() => {
    if (toDateValue) {
      const newFollowUpDate = addDays(
        new Date(toDateValue),
        2,
      );
      setValue('followUp', newFollowUpDate);
    }
  }, [toDateValue]);  

  React.useEffect(() => {
    if (currentProject?.jurisdiction) {
      // if(!initialValues) {
        Promise.all([
            getInspectionTypesCustom(currentProject?.jurisdiction),
        ]).then(([typesResponse]) => {            
          const inspectorTypes = typesResponse.data
          setInspectionTypeData(inspectorTypes);
        });
      // }
    }
  }, [currentProject]);

  React.useEffect(() => {
    if (isInspectionCompleted) {
      if (
        !activityContactCheckbox &&
        !jurisdictionCheckbox &&
        !projectContactsCheckbox
      ) {
        setValue('hiddenCheck', false);
        setCheckboxesError(
          'One of these options is required',
        );
      } else {
        setCheckboxesError('');
        setValue('hiddenCheck', true);
      }
    }
  }, [
    isInspectionCompleted,
    activityContactCheckbox,
    jurisdictionCheckbox,
    projectContactsCheckbox,
  ]);
  
  // Set initialValues for EditMode
  React.useEffect(() => {
    if (initialValues) {
      Promise.all([
        getActivitiesList({
          ...GET_ALL_QUERY_PARAMS,
          activityType: 8,
        }),
        getPermitUsers({
          user_role: 3,
          permit_id: permit,
        }),
        getUsersCustom({
          userRole: USER_TYPES_VALUES.INSPECTOR,
        }),
        getPermitInspectionById({
          ...GET_ALL_QUERY_PARAMS,
          id: initialValues?.id,
        }),
      ]).then(
        ([
          // inspectionResponse,
          activitiesTitleList,
          allUsersList,
          inspectorsList,
          inspectionIdResponse,
        ]) => {

          setInspectionTrades(inspectionIdResponse.data);    
          // setting up inspectors data for edit inspections      
          if (initialValues?.trades) {
            const inspectors = inspectorsList.data
              ?.filter(inspector => inspector?.isActive)
              .map(inspector => {
                return {
                  ...inspector,
                  inspectorName: `${inspector?.firstName} ${inspector.lastName}`,
                };
              })
              ?.filter(inspector => {
                
                return inspector?.trade?.some(inspTrade =>
                  initialValues?.trades.includes(inspTrade),
                );
              });
              
            setInspectorsData(inspectors);
          }
          if (inspectionIdResponse?.data?.trades) {
            const inspectors = InspectorSet(inspectorsList.data)
              ?.filter(inspector => inspector?.isActive)
              .map(inspector => {
                return {
                  ...inspector,
                  inspectorName: `${inspector?.firstName} ${inspector.lastName}`,
                };
              })
              ?.filter(inspector => {
                return inspector?.trade?.some(inspTrade =>
                  inspectionIdResponse?.data?.trades?.includes(inspTrade),
                );
              });

            setInspectorsData(inspectors);
          }
          const activitiesTitles = activitiesTitleList.data.results?.filter(
            title => title?.isActiveStatus === 'True',
          );
          setTitlesData(activitiesTitles);
          if (initialValues?.title || initialValues?.titleName) {
            const activityTitle = activitiesTitleList.data.results.find(
              title =>
                title.id === initialValues.title ||
                title.name === initialValues.title ||
                title.name === initialValues.titleName,
            );
            setValue('activityTitle', activityTitle);
          }          
          
          if (initialValues?.inspectionType) {
            getInspectionTypeByID(inspectionIdResponse?.data?.inspectionType).then(typeResponse => {
              setValue('inspectionType', typeResponse?.data);
            })
          }

          if (
            initialValues?.activityContact ||
            initialValues?.contactId
          ) {
            // filtering the user by matching the id
            // mritunjoy
            const user = allUsersList?.data.find(el => el?.id == initialValues?.contactId)
         
            if(user) {
              setValue('activityContact', user);
            } else {
              getUserByID(initialValues?.contactId || "").then(userData => setValue('activityContact', userData?.data))
            }
          }

          if (initialValues?.info || initialValues?.information) {
            const info = initialValues?.info || initialValues?.information;
            setValue('information', info);
          }
          
          if (initialValues?.inspector) {
            const inspector = inspectorsList.data?.find(
              el =>
                `${el?.firstName} ${el?.lastName}` ===
                  initialValues?.inspector ||
                el?.id === initialValues?.inspector,
            );
            
            if (inspector) {
              setValue('inspector', inspector);
            }
          }

          // setting the period data in inspection form

          if (
            initialValues
          ) {
            const period = periodData?.find(
              el =>
                el?.id === initialValues?.period ||
                el?.name === initialValues?.period,
            );
            
            setValue('period', period);
          }

          if (initialValues.isEmergency) {
            setValue(
              'emergency',
              initialValues.isEmergency,
            );
          }

          if (initialValues.isCompleted) {
            setValue(
              'isCompleted',
              initialValues.isCompleted,
            );
          }
        },
      );
    }
  }, [initialValues]);

  // Give activityStatus in action of activityTitle

  React.useEffect(() => {
    if (!selectedTitle) {
      setValue('activityStatus', '');
    }

    if (typeof selectedTitle === 'object') {
      setIsLoading(true);

      Promise.all([
        getActivitiesStatusList({
          ...GET_ALL_QUERY_PARAMS,
          activity: selectedTitle.id,
        }),
      ])
        .then(([activitiesStatusList]) => {
          const activitiesStatus = activitiesStatusList.data.results?.filter(
            status => status?.isActiveStatus === 'True',
          );

          setStatusData(activitiesStatus);

          const initialStatus = activitiesStatus?.find(
            status =>
              status?.name ===
                initialValues.activityStatus ||
              status?.name === initialValues.status ||
              status?.id === initialValues.status ||
              status?.name === initialValues.statusName,
          );

          setValue('activityStatus', initialStatus);
        })
        .catch(() => {
          setValue('activityStatus', '');
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [selectedTitle]);
  // /Set initialValues for EditMode

  React.useEffect(() => {
    if (
      formState.isSubmitting &&
      !Object.keys(errors).length && 
      !errorReport.length
    ) {
      setIsSubmitting(formState.isSubmitting);
    }
    if(formState.isDirty) {      
      if(Object.keys(formState.dirtyFields).indexOf('activityStatus') > -1){
        const activityStatusObject = getValues('activityStatus');
        if(activityStatusObject){
          setSelectedStatus(activityStatusObject.name);
        }        
      }
      
    }

  }, [formState]);

  React.useEffect(() => {
    if (Object.keys(errors).length) {
      setIsSubmitting(false);
    }
  }, [errors, initialErrors]);

  React.useEffect(() => {
    setIsLoading(true);

    Promise.all([
      getCustomActivities({
        permit_id:permit,
        ordering:null,
        event_type:"Activity",
        activity_type_name:"Conditions",
      }),
      getActivitiesList({
        ...GET_ALL_QUERY_PARAMS,
        activityType: 8,
      }),
      getUsersCustom({
        userRole: USER_TYPES_VALUES.EMPLOYEE,
      }),
      getPermitByID(permit),
      getPermitUsers({
        permit_id: permit,
        userRole: USER_TYPES_VALUES.CLIENT,
      }),
    ])
      .then(
        ([conditionsResponse,
          activitiesTitleList,
          employeeList,
          permitResponse,
          individualResponse,
        ]) => {
          const conditionHoldData = conditionsResponse?.data?.results?.find(el => !el?.isCompleted && (el?.activityTitleName === 'Inspection Hold' || el?.activityTitleName === 'Permit Hold'))
          if(conditionHoldData) {
            setIsConditionOnHold(true)
          } else {
            setIsConditionOnHold(false)
          }
                            
          setPermitData(permitResponse?.data)
          Promise.all([getProjectByID(permitResponse?.data?.project)]).then(([projectResponse]) => {
            
            const activitiesTitles = activitiesTitleList.data.results?.filter(
              title => title?.isActiveStatus === 'True',
            );
            setTitlesData(activitiesTitles);
            const allEmployee = employeeList.data?.filter(
              employee => employee?.isActive,
            );
            
            setAssignedData(allEmployee);
              // setting up assigned to data for edit inspections
            if (initialValues?.assignedTo) {              
              const assignee = allEmployee?.find(el =>
                `${el?.firstName} ${el?.lastName}` === initialValues?.assignedTo || el?.id === initialValues?.assignedTo) 
                if (assignee) {
                setValue('assignedTo', assignee);
              }
            }
            
            const contacts = individualResponse?.data;
            setUsersData(contacts);
            setAdditionalContactsData(contacts);
            const project = projectResponse?.data
            setCurrentProject(project);
        
          })

        },
      )
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  React.useEffect(() => {
    setReadOnlyInfo([
      {
        label: 'Project Name',
        value: initialValues?.projectName || currentProject?.name,
      },
      {
        label: 'Permit Name',
        value: initialValues?.permitName,
      },
      {
        label: 'Permit Number',
        value: initialValues?.permitNumber,
      },
      {
        label: 'Permit Address',
        value: initialValues?.projectAddress ? initialValues?.projectAddress : currentProject?.address,
      },
      {
        label: 'Jurisdiction',
        value: isEmployee ? currentProject?.jurisdictionName : initialValues?.projectJurisdiction,
      },
      {
        label: 'Project Manager',
        value: isEmployee ? currentProject?.managerName : initialValues?.projectManager,
      },
      {
        label: 'Inspection Number',
        value: initialValues?.id,
      },
    ]);
  }, [currentProject])

  React.useEffect(() => {
    if (Array.isArray(selectedType) && !initialValues) {
      Promise.all([
        getTrades({
          ...GET_ALL_QUERY_PARAMS,
          ordering: 'name',
        }),
      ]).then(([response]) => {
        const newArr = selectedType
          .map(elem => {
            return [...elem.tradeIds];
          })
          .flat();

        setTradesData(
          response.data.results.filter(elem =>
            newArr.includes(elem.id),
          ),
        );
      });
    }
  }, [selectedType]);

  const [
    inspectionReport,
    setInspectionReport,
  ] = React.useState(null);

const downloadReport = () => {
  if (initialValues?.visibleFilename) {            
    downloadInspectionReport(
      inspectionTrades.id
    ).then(file => {
      function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);

        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, {
          type: mime,
        });
      }
      var myFile = dataURLtoFile(
        `data:application/pdf;application/docx;base64,${file.data.file}`,
        file.data.fileName,
      );     
        let url = window.URL.createObjectURL(myFile);
        let a = document.createElement('a');
        a.href = url;
        a.download = initialValues?.visibleFilename
        a.click();
    }).catch(err => console.log(err)
    );
  }
}

// ###############################

  const modifiedGetCommentsRequest = (isSystemMsg) => {
    return Promise.all([
      getPermitInspectionsComments(
        {...GET_ALL_QUERY_PARAMS,system_message:isSystemMsg},
        initialValues?.id,
      ),
      getCurrentUser(),
    ]).then(([commentsResponse, userResponse]) => {      
      return {
        ...commentsResponse,
        data: {
          ...commentsResponse.data,
          results: commentsResponse.data.results.reduce((acc,comment)=>{
            if(comment.text!==" "){
              const val={
                ...comment,
              creator: `${userResponse.data.firstName} ${userResponse.data.lastName}`,
              createdAt: formatDateTime(
                comment?.createdAt,
                COMMENTS_DATETIME_FORMAT,
              ),
              }
              acc.push(val)
            }
            return acc;
          },[])
        },
      };
    });
  };

  const middlewareAdd = (
    submitFn: (data) => void,
  ) => data => {    
    if (
      Array.isArray(data.inspectionType) &&
      data.inspectionType?.length
    ) {      
      data.inspectionType.forEach(insp => {
        submitFn({
          report: inspectionReport,
          permit,
          title: data.activityTitle.id,
          inspectionType: insp.id,
          information: data.information,
          cellPhone: data.cellPhone,
          phoneNumber: data.phoneNumber,
          trades:tradesData.length? tradesData?.map(elem => elem?.id).filter(id => insp?.tradeIds?.includes(id)) : null,
          contact: data.activityContact?.id,
          isEmergency: data.emergency,
        })
      });
    }
  };  

  const isInspectionDateDisabled = () => {
    if(toDateValue && selectedStatus){
      if(selectedStatus.name !== InspectionStatuses.NOT_SCHEDULED)
        {
          return true;
        }
    }
    return isReadOnly || isInspectionCompleted || cameFromSearch || !permitData?.permitIssued
  }

  const addInspectionReport = async () => {
    
    if (inspectionReport) {
      const inspectordata = {
        report: inspectionReport,
        title: initialValues.title,
        inspection_type: inspectionType,
        status: selectedStatus.id,
        id: initialValues.id,
      };
      const empData = {
        report: inspectionReport,
        title: selectedTitle?.id,
        inspection_type: inspectionType,
        status: selectedStatus.id,
        id: initialValues.id,
      };

      const initialStatus = initialValues?.status;
        const status = statusData.filter(
          el => initialStatus === el.id || el.name,
        );

      
        const promises = []
        if(isInspector) {
          uploadInspectionReport(isInspector? inspectordata : empData).then((res) => { 
          if(!initialValues?.isCompleted && !isInspectionCompleted) promises.push(
          sendInspectionMessage({
          phone_number: initialValues?.cellPhone,
          inspector_id: initialValues?.inspector,
          address: initialValues?.projectAddress,
          report_status: res.data.report
            ? 'Attached'
            : 'Not Attached',
          inspection_status: selectedStatus?.name,
          inspection_id: initialValues?.id
        }))
          })
          
        } else {
          promises.push(uploadInspectionReport(isInspector? inspectordata : empData))
        }
      return Promise.all(promises).catch((err) => setModalErr(true))
      
    } else {
      const initialStatus = initialValues?.status;
      const status = statusData.filter(
       el => initialStatus === el.id || el.name,
      );

      const promises = []
      if(isInspector) {
        if(selectedStatus?.id !== initialValues?.status || inspectionReport) {
          if(!initialValues?.isCompleted && !isInspectionCompleted) promises.push(
          sendInspectionMessage({
          phone_number: initialValues?.cellPhone,
          inspector_id: initialValues?.inspector,
          address: initialValues?.projectAddress,
          report_status: initialValues?.report
            ? 'Attached'
            : 'Not Attached',
          inspection_status: selectedStatus?.name,
          inspection_id: initialValues?.id
        }))
        } 
      } 
    
      return Promise.all([
    ]).catch((err) => setModalErr(true))
    }
  };

  React.useEffect(() => {
    if(permitStatus === 32 && initialValues?.status !== InspectionStatuses.SCHEDULED && selectedStatus?.name == InspectionStatuses.SCHEDULED) {
      setHoldModalOpen(true)
    }
  
    if(isConditionOnHold && initialValues?.status !== InspectionStatuses.SCHEDULED && selectedStatus?.name == InspectionStatuses.SCHEDULED){
      setConditionHoldModalOpen(true)
    }
  }, [selectedStatus])

  const middlewareEdit = (
    submitFn: (data) => void,
  ) => data => {    
    
    if(data.isCompleted && !(initialValues.visibleFilename || inspectionReport)){
      setErrorReport("Inspection report is missing. Please attach before sending.")
      setIsSubmitting(false);
    }else{
      let completedBy;
      Promise.all([getCurrentUser()]).then(([{data}]) => {
        completedBy = data.id
      }).then(() => {
        const today = new Date();
        const contacts = data.additionalContacts?.map(
          contact => contact?.id,
        );
        
        const uploadPromise = addInspectionReport()    
        uploadPromise.then((val)=> {
          if(val) {
            const requestData = setInspectionData(
              data,
              initialValues,
              submitFn,
              permit,
              contacts,
            );        
            
            requestData["phone_number"] = data.phoneNumber 
            requestData["mobile_phone_number"] = data.cellPhone
            requestData["completed_at"] = (initialValues && initialValues?.isCompleted === false && isInspectionCompleted === true) ? `${today.toLocaleDateString()}` : null,
            requestData["completed_by"] = (initialValues && initialValues?.isCompleted === false && isInspectionCompleted === true) ? completedBy : null
            requestData["inspection_prompt"] = holdSubmit
            requestData["condition_inspection_prompt"] = conditionHoldSubmit
            if (
              data.inspectionType &&
              !Array.isArray(data.inspectionType)
              // && !checkboxesError
            ) {
              const _data = {
                ...requestData,
                inspectionDate: requestData.inspectionDate || null,
                followUp: !requestData.inspectionDate ? null : format(data.followUp,'MM/dd/yyyy'),
                inspector: !requestData.inspectionDate ? null : data.inspector === null ? null : data.inspector?.id,
                information: newInformation,
                inspectionPrompt, 
                conditionInspectionPrompt: conditionPrompt
              };
                submitFn(_data)
            }
            if (
              data.inspectionType &&
              Array.isArray(data.inspectionType)
              // && !checkboxesError
            ) {
              data.inspectionType.forEach(type => {
                const requestDataWithType = setInspectionData(
                  data,
                  initialValues,
                  submitFn,
                  permit,
                  contacts,
                  type,
                );            
                  submitFn(requestDataWithType)
              });
            }
          } 
          
          setIsSubmitting(false);
          dispatch(setModalOpen(false));             
          const dailyInspectionUpdated = dailyInspections.reduce((itemArr,item) => {
            //@ts-ignore
            if(item.id !== initialValues.id){
              itemArr.push(item);
            }
            return itemArr;
          },[])
          
          dispatch(setPermitInspectionsList(dailyInspectionUpdated))
        }).catch(err => setModalErr(true))
      })
  }
  };

  const handleCloseContactsModal = () => {
    setIsViewContactsModal(false);
  };

  const {
    isSuperAdmin,
    isFranchiseAdmin,
    isEmployee,
    isInspector,
  } = useUserRole();

  const inspectionType = selectedType?.id || [];
  const modalOpen = useSelector((s:RootState) => s.inspections.modalOpen);
  const formRef = useRef<HTMLFormElement>();
  const handleModalOpen = () => {
    if (
      selectedStatus?.id !== initialValues?.status ||
      inspectionReport
    ) {
      dispatch(setModalOpen(true));
    }
  };

  React.useEffect(() => {
    if(modalOpen)
    {
      //iterate over the parent elements up to 10 parents.
      //if the scrollTop > 0 then reset it to zero and leave 
      let parent = formRef.current.parentElement;
      let iterationCounter = 10;
      
      while(parent.scrollTop === 0){
        iterationCounter--;
        parent = parent.parentElement;
        if(parent.scrollTop > 0 || iterationCounter == 0){
          break;
        }
      }
      parent.scrollTop = 0;
    }
  },[modalOpen])
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false)
  const handleDeleteModalOpen = () => {
    setDeleteModalOpen(true)
  }
  const closeDeleteSuccessModal =()=>{
    setModalSuccess(false)
    setInspectionReport(null)
    const tempInitialValues={...initialValues}
    tempInitialValues.visibleFilename=null
    setInitialValues(tempInitialValues)
    updateTable()
   }

  const handleDeleteReport = () => {
    deleteInspectionReport(initialValues.id).then((res) => {
      setDeleteModalOpen(false)
      setModalSuccess(true)})
  }
  const DeleteReportButton = () => {
    return <>
      <DeleteOutline onClick={handleDeleteModalOpen} style={{padding: 0, margin: 0, cursor:'pointer'}} />
    {deleteModalOpen && (
      <ModalDefaultDraggable
        title="Confirmation"
        onClose={() => setDeleteModalOpen(false)}
      >
        <>
          <p>
          Are you sure you want to delete the inspection report?
          </p>
          <br />
          
          <ButtonWithLoader
            onClick={handleDeleteReport}
          >
            Delete
          </ButtonWithLoader>
        </>
      </ModalDefaultDraggable>
    )}
    {(modalSuccess || modalError) && (
       <ModalInfo
       timeToClose={5000}
       isError={modalError}
       onClose={closeDeleteSuccessModal}
     />
      )}
    </>
  }

  // #############
  return (
    <>
      {modalError || modalErr && (
        <ModalInfo
          // timeToClose={5000}
          isError={modalErr}
          onClose={() => {
            setModalErr(false)
          }}
        />
      )}
    
    <Container
      disabled={isDeleted || (isSubmitting && !modalError)}
    >
      <FormContainer
        autoComplete="off"
        aria-autocomplete="none"
        ref={formRef}
        onSubmit={initialValues
            ? handleSubmit(middlewareEdit(onSubmit))
            : handleSubmit(middlewareAdd(onSubmit))
        }
      >
        {(initialValues && !(isIpad && isInspector)) ? (
            <InspectionsInfoBlock info={readOnlyInfo} />
        ) : null}
        <InputBaseContainer>
          <Controller
            name="activityTitle"
            defaultValue=""
            control={control}
            render={props => (
              <InputSelectWithLabel
                placeholder=""
                menuPlacement="bottom"
                options={titlesData}
                isLoading={isLoading}
                isDisabled={isLoading || isReadOnly || isInspectionCompleted || cameFromSearch || isInspector}
                label={labels.activityTitle}
                error={errors.activityTitle}
                getOptionValue={(item: any) =>
                  item.id || Math.random()
                }
                getOptionLabel={(item: any) => item.name}
                isRequired
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            )}
          />
        </InputBaseContainer>
        <InputBaseContainer>
          <Controller
            name="inspectionType"
            defaultValue=""
            control={control}
            render={props => (
              <InputSelectWithLabel
                isMulti={!initialValues}
                placeholder=""
                menuPlacement="bottom"
                isLoading={isLoading || inspectionsTypeData.length === 0}
                label={labels.inspectionType}
                error={errors.inspectionType}
                isDisabled={IsInspectionTypeDisabled()}
                getOptionValue={(item: any) =>
                  item.id || Math.random()
                }
                getOptionLabel={(item: any) => item.name}
                options={inspectionsTypeData}
                isRequired={isYupFieldRequired(
                  'inspectionType',
                  schemaEdit,
                )}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            )}
          />
        </InputBaseContainer>

        {initialValues && (
          <>
           <StatusContainer></StatusContainer>
            {showFormPopup && <StatusChangeConfirmPopup setPromptValue={setPromptValue} undoStatusChange={undoStatusChange} />}
            <InputBaseContainer>
              <Controller
                name="activityStatus"
                defaultValue=""
                control={control}
                render={props => (
                  <InputSelectWithLabel
                    placeholder=""
                    menuPlacement="bottom"
                    isLoading={isLoading}
                    isDisabled={isLoading || isInspectionCompleted || cameFromSearch || isReadOnly}
                    label={labels.activityStatus}
                    error={errors.activityStatus}
                    getOptionValue={(item: any) =>
                      item.id || Math.random()
                    }
                    getOptionLabel={(item: any) =>
                      item.name
                    }
                    isRequired={isYupFieldRequired(
                      'activityStatus',
                      schemaEdit,
                    )}
                    options={statusData}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...props}
                  />
                )}
              />
            </InputBaseContainer>
            <InputBaseContainer>
              <Controller
                name="toDate"
                defaultValue=""
                valueName="selected"
                onChange={([selected]) => selected}
                control={control}
                render={props => (
                  <InputDatePickerWithLabel
                    label={labels.inspectionDate}
                    error={errors.inspectionDate}
                    dateFormat={DEFAULT_DATE_FORMAT}
                    disabled={isInspectionDateDisabled()}
                    isRequired={isYupFieldRequired(
                      'inspectionDate',
                      schemaEdit,
                    )}
                    // eslint-disable-next-line react/prop-types
                    selected={props.value}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...props}
                  />
                )}
              />
            </InputBaseContainer>
            {!isInspector && !permitData?.permitIssued && <p style={{display: 'block', textAlign:'start', color: 'red', margin: 0, fontSize: '12px'}}>
              INSPECTION CANNOT BE SCHEDULED UNTIL PERMIT IS ISSUED</p>}
            <InputBaseContainer>
              <Controller
                name="period"
                defaultValue=""
                control={control}
                render={props => (
                  <InputSelectWithLabel
                    placeholder=""
                    menuPlacement="bottom"
                    isLoading={isLoading}
                    isDisabled={isLoading || isReadOnly || isInspectionCompleted || cameFromSearch || isInspector}
                    label={labels.period}
                    error={errors.period}
                    getOptionValue={(item: any) =>
                      item.id || Math.random()
                    }
                    getOptionLabel={(item: any) =>
                      item.name
                    }
                    options={periodData}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...props}
                  />
                )}
              />
            </InputBaseContainer>
            {<InputBaseContainer hidden={isIpad && isInspector}>
              <Controller
                name="inspector"
                defaultValue=""
                control={control}
                render={props => (
                  <InputSelectWithLabel
                    placeholder=""
                    menuPlacement="bottom"
                    isLoading={isLoading}
                    isDisabled={isLoading || isReadOnly || isInspectionCompleted || cameFromSearch || isInspector}
                    label={labels.inspector}
                    error={errors.inspector}
                    getOptionValue={(item: any) =>
                      item.id || Math.random()
                    }
                    getOptionLabel={(item: any) =>
                      `${item?.firstName} ${item?.lastName}`
                    }
                    options={inspectorsData}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...props}
                  />
                )}
              />
            </InputBaseContainer>}
            { <InputBaseContainer hidden={isIpad && isInspector}>
              <Controller
                name="followUp"
                defaultValue=""
                valueName="selected"
                onChange={([selected]) => selected}
                control={control}
                render={props => (
                  <InputDatePickerWithLabel
                    label={labels.followUp}
                    error={errors.followUp}
                    dateFormat={DEFAULT_DATE_FORMAT}
                    disabled={isReadOnly || isInspectionCompleted || cameFromSearch || isInspector}
                    isRequired={isYupFieldRequired(
                      'followUp',
                      schemaEdit,
                    )}
                    // eslint-disable-next-line react/prop-types
                    selected={props.value}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...props}
                  />
                )}
              />
            </InputBaseContainer>}
          </>
        )}
        { <InputBaseContainer hidden={isIpad && isInspector}>
          <Controller
            name="activityContact"
            defaultValue=""
            control={control}
            render={({onChange,...props}) => (
              <InputSelectWithLabel
              onChange={(item)=>{
                onChange(item)
                setValue('cellPhone', item?.mobilePhoneNumber || "");
                setValue('phoneNumber', item?.phoneNumber || "");
              }}
                showCompany
                placeholder=""
                menuPlacement="bottom"
                label={labels.activityContact}
                error={errors.activityContact}
                isDisabled={isLoading || isReadOnly || isInspectionCompleted || cameFromSearch || isInspector}
                options={usersData}
                isRequired={isYupFieldRequired(
                  'activityContact',
                  initialValues ? schemaEdit : schemaAdd,
                )}
                getOptionValue={(item: any) => item.id}
                getOptionLabel={(item: any) =>
                  `${item?.firstName} ${item?.lastName}${
                    item.companyName || item.parentCompany
                      ? ` - ${
                          item.companyName ||
                          item.parentCompany
                        }`
                      : ''
                  }`
                }
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            )}
          />
        </InputBaseContainer>}
        {/* EA-177
        mritunjoy */}
        <InputBaseContainer>
          <TextAreaWithLabel
            name="information"
            error={errors.information}
            label={labels.information}
            inputRef={register}
            maxChar={2000}
            isRequired={isYupFieldRequired(
              'information',
              initialValues ? schemaEdit : schemaAdd,
            )}
            disabled={isInspectionCompleted || cameFromSearch}
          />
        </InputBaseContainer>
        {initialValues && (
          <InputBaseContainer hidden={isIpad && isInspector}>
            <MobileRemove>
            <Controller
              name="assignedTo"
              defaultValue=""
              control={control}
              render={props => (
                <InputSelectWithLabel
                  placeholder=""
                  menuPlacement="bottom"
                  isLoading={isLoading}
                  isDisabled={isLoading || isReadOnly || isInspectionCompleted || cameFromSearch || isInspector}
                  label={labels.assignedTo}
                  error={errors.assignedTo}
                  getOptionValue={(item: any) =>
                    item.id || Math.random()
                  }
                  getOptionLabel={(item: any) =>
                    `${item?.firstName} ${item?.lastName}`
                  }
                  options={assignedData}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...props}
                />
              )}
            />
            </MobileRemove>
          </InputBaseContainer>
        )}
        {/* ################ */}
        {/* mritunjoy */}
        {/* EA-56 */}
        {/* Adding Cell phone field non-editable in the update inspection form */}
        <Controller
            control={control}
            defaultValue=""
            name="cellPhone"
            render={props => (
              <ReactInputMask
                mask="999-999-9999"
                maskChar=" "
                disabled={isInspector || isInspectionCompleted || cameFromSearch || isReadOnly}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              >
                {() => (
          <InputTextWithLabel
          style={(isInspector || isInspectionCompleted || cameFromSearch || isReadOnly) ? {backgroundColor: '#F2F2F2', opacity: 0.7} : {}}
          error={errors.cellPhone}
          label={labels.cellPhone}
          labelRequired={initialValues?selectedStatus?.name===InspectionStatuses.NOT_SCHEDULED||selectedStatus?.name==="Void"||selectedStatus?.name===InspectionStatuses.NOT_APPLICABLE || selectedStatus?.name==="Cancelled"?false:true:false}
          isRequired={initialValues?selectedStatus?.name===InspectionStatuses.NOT_SCHEDULED||selectedStatus?.name==="Void"||selectedStatus?.name===InspectionStatuses.NOT_APPLICABLE|| selectedStatus?.name==="Cancelled"?false:isYupFieldRequired(
            'cellPhone',  
            initialValues ? schemaEdit : schemaAdd,
          ):false}
        />
        )}
        </ReactInputMask>
          )}
         />
            {/* adding hold popup warning */}
          {/* {showHoldWarning && <HoldWarning />} */}
         <Controller
          control={control}
          defaultValue=""
          name="phoneNumber"
          render={props => (
            <ReactInputMask
              mask="999-999-9999"
              maskChar=" "
              disabled={isInspector || isInspectionCompleted || cameFromSearch || isReadOnly}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
            >
              {() => (
        <InputTextWithLabel
            style={(isInspector || isInspectionCompleted || cameFromSearch || isReadOnly) ? {backgroundColor: '#F2F2F2', opacity: 0.7} : {} }
            error={errors.phoneNumber}
            label={labels.phoneNumber}
            labelRequired={initialValues?selectedStatus?.name===InspectionStatuses.NOT_SCHEDULED||selectedStatus?.name==="Void"||selectedStatus?.name===InspectionStatuses.NOT_APPLICABLE || selectedStatus?.name==="Cancelled"?false:true:false}
            isRequired={initialValues?selectedStatus?.name===InspectionStatuses.NOT_SCHEDULED||selectedStatus?.name==="Void"||selectedStatus?.name===InspectionStatuses.NOT_APPLICABLE || selectedStatus?.name==="Cancelled"?false:isYupFieldRequired(
              'phoneNumber',
              initialValues ? schemaEdit : schemaAdd,
            ):isYupFieldRequired(
              'phoneNumber',
              initialValues ? schemaEdit : schemaAdd,
            )}
          />
            )}
            </ReactInputMask>
          )}
        />

         {/* EA-390 */}
         {initialValues && !isInspector && <>
          <InputBaseContainer>
          <MobileRemove>
          <InputTextWithLabel
            style={{backgroundColor: '#F2F2F2', opacity: 0.8}}
            name="createdAt"
            error={errors.dateCreated}
            inputRef={register}
            label={labels.dateCreated}
            isRequired={false}
            disabled={true}
          />
          </MobileRemove>
        </InputBaseContainer>
        <InputBaseContainer>
         <MobileRemove>
          <InputTextWithLabel
            style={{backgroundColor: '#F2F2F2', opacity: 0.8}}
            name="createdBy"
            error={errors.createdBy}
            inputRef={register}
            label={labels.createdBy}
            isRequired={false}
            disabled={true}
          />
          </MobileRemove>
        </InputBaseContainer>
        {initialValues?.isCompleted && <>
          <InputBaseContainer>
          <MobileRemove>
          <InputTextWithLabel
            style={{backgroundColor: '#F2F2F2', opacity: 0.8}}
            name="completedAt"
            error={errors.dateCompleted}
            inputRef={register}
            label={labels.dateCompleted}
            isRequired={false}
            disabled={true}
          />
          </MobileRemove>
        </InputBaseContainer>
        <InputBaseContainer>
        <MobileRemove>
          <InputTextWithLabel
            style={{backgroundColor: '#F2F2F2', opacity: 0.8}}
            name="completedBy"
            error={errors.completedBy}
            inputRef={register}
            label={labels.completedBy}
            isRequired={false}
            disabled={true}
          />
          </MobileRemove>
        </InputBaseContainer>
        </>}
        </>}

        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
            }}
          >
            {initialValues && (
            <Controller
              name="isCompleted"
              control={control}
              valueName="checked"
              defaultValue={false}
              render={({ onChange, onBlur, value }) => (
                <CheckboxWithLabel
                  error={errors.isCompleted}
                  label={labels.isCompleted}
                  checked={value}
                  onBlur={onBlur}
                  onChange={(e, flag) => onChange(flag)}
                  disabled={isReadOnly || initialValues?.title==="Invoice" || cameFromSearch || isInspector}
                />
              )}
            />
          )}

            {/* Adding downlaod inspection report  */}
            {/* mritunjoy */}
              {(initialValues && (initialValues?.visibleFilename || inspectionReport)) && (
                <div style={{display: 'flex'}}>
                  <a
                  onClick={() => downloadReport()}
                style={{ margin: 0, width: '10rem', cursor: 'pointer' }}
              >
                {inspectionReport ? inspectionReport.name : initialValues?.visibleFilename} 
              </a>
                {initialValues?.visibleFilename && !initialValues?.isCompleted && !inspectionReport && !cameFromSearch && <DeleteReportButton />}
                </div>
            )}
          </div>
          {/* EA-149
          mritunjoy */}
          {(!initialValues && inspectionReport) && (
                <div style={{display: 'flex', justifyContent: 'end'}}>
                  <a
                  onClick={() => downloadReport()}
                style={{ margin: 0, width: '10rem', cursor: 'pointer' }}
              >
                {inspectionReport ? inspectionReport.name : initialValues?.visibleFilename} 
              </a>
                {initialValues?.visibleFilename && !initialValues?.isCompleted && !inspectionReport && <DeleteReportButton />}
                </div>
            )}
          {/* ############ */}
          {/* mritunjoy */}
          {/* EA-54 */}
          {/* Adding upload button to upload the inspection report */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
            }}
          >
            <Controller
              name="emergency"
              control={control}
              valueName="checked"
              defaultValue={false}
              render={({ onChange, onBlur, value }) => (
                <CheckboxWithLabel
                  error={errors.emergency}
                  label={labels.emergency}
                  checked={value}
                  onBlur={onBlur}
                  onChange={(e, flag) => onChange(flag)}
                  disabled={isReadOnly || isInspectionCompleted || cameFromSearch || isInspector}
                />
              )}
            />
            {/* EA-215
            mritunjoy */}
            {initialValues && <DesktopAddReport 
                       initialValues={{...initialValues,statusName:selectedStatusValue, isInspectionCompleted}} 
                       getValues={getValues}
                       cameFromSearch={cameFromSearch}
                       isReadOnly={isReadOnly}
                       setErrorReport={setErrorReport}
                       selectedStatusValue={selectedStatusValue}
                       watch={watch}
                       setInspectionReport={setInspectionReport}/>}
            
          </div>
          <div style={{color:"red"}}>{errorReport}</div>
        </>
        {initialValues && (
          <div style={{display:isIpad && isInspector?"none":"block"}}>
            <h2>Send To</h2>
            <CheckboxesWrapper
              checkboxesError={checkboxesError}
            >
              <InputBaseContainer hidden={isIpad && isInspector}>
                <Controller
                  name="activityContactCheckbox"
                  control={control}
                  valueName="checked"
                  defaultValue
                  render={({ onChange, onBlur, value }) => (
                    <CheckboxWithLabel
                      error={errors.activityContactCheckbox}
                      label={labels.activityContactCheckbox}
                      checked={value}
                      onBlur={onBlur}
                      onChange={(e, flag) => onChange(flag)}
                      disabled={
                        !isInspectionCompleted ||
                        initialValues?.isCompleted || 
                        cameFromSearch
                      }
                    />
                  )}
                />
                <Controller
                  name="jurisdictionContacts"
                  control={control}
                  valueName="checked"
                  defaultValue
                  render={({ onChange, onBlur, value }) => (
                    <CheckboxWithLabel
                      error={errors.jurisdictionContacts}
                      label={labels.jurisdictionContacts}
                      checked={value}
                      onBlur={onBlur}
                      onChange={(e, flag) => onChange(flag)}
                      disabled={
                        !isInspectionCompleted ||
                        initialValues?.isCompleted ||
                        cameFromSearch
                      }
                    />
                  )}
                />
                <Controller
                  name="projectContacts"
                  control={control}
                  valueName="checked"
                  defaultValue
                  render={({ onChange, onBlur, value }) => (
                    <CheckboxWithLabel
                      error={errors.projectContacts}
                      label={labels.projectContacts}
                      checked={value}
                      onBlur={onBlur}
                      onChange={(e, flag) => onChange(flag)}
                      disabled={
                        !isInspectionCompleted ||
                        initialValues?.isCompleted ||
                        cameFromSearch
                      }
                    />
                  )}
                />
              </InputBaseContainer>
            </CheckboxesWrapper>
            {initialValues && (
              <InputBaseContainer hidden>
                <Controller
                  name="hiddenCheck"
                  control={control}
                  valueName="checked"
                  defaultValue
                  render={({ onChange, onBlur, value }) => (
                    <CheckboxWithLabel
                      error={errors.hiddenCheck}
                      label={labels.projectContacts}
                      checked={value}
                      onBlur={onBlur}
                      onChange={(e, flag) => onChange(flag)}
                    />
                  )}
                />
              </InputBaseContainer>
            )}

            {checkboxesError && (
              <CheckboxesError>
                {checkboxesError}
              </CheckboxesError>
            )}

            <ContactsContainer>
              <ContactsInputWrapper>
                <Controller
                  name="additionalContacts"
                  defaultValue=""
                  control={control}
                  render={props => (
                    <InputSelectWithLabel
                      isMulti
                      // selectAllActive
                      placeholder=""
                      menuPlacement="top"
                      isLoading={isLoading}
                      label={labels.additionalContacts}
                      error={errors.additionalContacts}
                      isDisabled={
                        isLoading ||
                        !isInspectionCompleted ||
                        initialValues?.isCompleted ||
                        cameFromSearch
                      }
                      getOptionValue={(item: any) =>
                        item.id || Math.random()
                      }
                      getOptionLabel={(item: any) =>
                        item.companyName
                          ? item.companyName
                          : `${item.firstName} ${item.lastName}`
                      }
                      options={additionalContactsData}
                      isRequired={isYupFieldRequired(
                        'additionalContacts',
                        schemaEdit,
                      )}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...props}
                    />
                  )}
                />
              </ContactsInputWrapper>
              <ContactsButtonWrapper>
                <ButtonWithLoader
                  disabled={
                    isLoading ||
                    !isInspectionCompleted ||
                    initialValues?.isCompleted ||
                    cameFromSearch
                  }
                  loading={isLoading}
                  onClick={() =>
                    setIsViewContactsModal(true)
                  }
                >
                  View Contacts
                </ButtonWithLoader>
              </ContactsButtonWrapper>
            </ContactsContainer>
          </div>
        )}
        {/* adding onclick event to upload inspection report
                   mritunjoy
                   EA-54 */}
        {isInspector ? (
          <>
            <ButtonContainer>
              {!isDeleted && (
                <ButtonWithLoader
                  onClick={handleModalOpen}
                  disabled={isSubmitting && !modalError}
                  loading={isSubmitting && !modalError}
                  type={(selectedStatus?.id === initialValues?.status && !inspectionReport) ? 'submit' : 'button'}
                >
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {!initialValues?.isCompleted &&
                  isInspectionCompleted
                    ? 'Update & Send'
                    : (initialValues?.isCompleted &&
                        isInspectionCompleted) ||
                      (initialValues &&
                        !initialValues?.isCompleted &&
                        !isInspectionCompleted)
                    ? updateButtonText
                    : addButtonText}
                </ButtonWithLoader>
              )}
            </ButtonContainer>
            {modalOpen && (
              <ModalDefaultDraggable
                title="Confirmation"
                onClose={() => dispatch(setModalOpen(false))}
              >
                <>
                  <p>
                    This will send inspection completed
                    message to the inspection contact. Do
                    you want to continue?
                  </p>
                  <br />
                  <Button
                    onClick={() => dispatch(setModalOpen(false))}
                  >
                    Cancel
                  </Button>{' '}
                  <ButtonWithLoader
                  disabled={isSubmitting && !modalError}
                  loading={isSubmitting}
                  type="submit"
                  >
                    Ok
                  </ButtonWithLoader>
                </>
              </ModalDefaultDraggable>
            )}
          </>
        ) : isEmployee ? (
          <ButtonContainer>
            {!isDeleted && !isReadOnly &&(
              <ButtonWithLoader
                disabled={isSubmitting && !modalError || cameFromSearch}
                loading={isSubmitting && !modalError}
                type="submit"
              >
                {/* eslint-disable-next-line no-nested-ternary */}
                {!initialValues?.isCompleted &&
                isInspectionCompleted
                  ? 'Update & Send'
                  : (initialValues?.isCompleted &&
                      isInspectionCompleted) ||
                    (initialValues)
                  ? updateButtonText
                  : addButtonText}
              </ButtonWithLoader>
            )}
          </ButtonContainer>
        ) : null}

        {holdModalOpen && (
              <ModalDefaultDraggable
                title="Confirmation"
                onClose={() => setHoldModalOpen(false)}
              >
                <>
                  <p>
                  Permit Status is HOLD. Are you sure you want to schedule the inspection
                  </p>
                  <br />
                  <Button
                    onClick={() => {
                      setHoldModalOpen(false)
                      setHoldSubmit(false)
                      setValue('activityStatus', statusData.find(status => status?.name === initialValues?.status));
                    }}
                  >
                    No
                  </Button>{' '}
                  <ButtonWithLoader
                  disabled={isSubmitting && !modalError}
                  loading={isSubmitting && !modalError}
                  onClick={() => {
                    setHoldSubmit(true)
                    setHoldModalOpen(false)
                  }}
                  >
                    Yes
                  </ButtonWithLoader>
                </>
              </ModalDefaultDraggable>
            )}
            {/* EA-557 adding confirmation popup for hold permit */}
        {false && (
              <ModalDefaultDraggable
                title="Confirmation"
                onClose={() => setConditionHoldModalOpen(false)}
              >
                <>
                  <p>
                  There is a condition with either an Inspection Hold or Permit Hold. Are you sure you want to schedule the inspection?
                  </p>
                  <br />
                  <Button
                    onClick={() => {
                      setConditionHoldModalOpen(false)
                      setConditionHoldSubmit(false)
                      setValue('activityStatus', statusData.find(status => status?.name === initialValues?.status));
                    }}
                  >
                    No
                  </Button>{' '}
                  <ButtonWithLoader
                  disabled={isSubmitting && !modalError}
                  loading={isSubmitting && !modalError}
                  onClick={() => {
                    setConditionHoldSubmit(true)
                    setConditionHoldModalOpen(false)
                  }}
                  >
                    Yes
                  </ButtonWithLoader>
                </>
              </ModalDefaultDraggable>
            )}
      </FormContainer>

      {initialValues && (
        <CommentsChat
          commentsRequest={modifiedGetCommentsRequest}
          entityData={initialValues}
          postRequest={createPermitInspectionsComment}
          noFile
          title={'Comments List'}
          buttonText="Add Comment"
          isButtonHidden={isSimulateDeleted}
          isReadOnly={initialValues?.isCompleted || cameFromSearch || isReadOnly}
          commentCreated={commentCreated}
          setCommentCreated={setCommentCreated}
        />
      )}

      {isViewContactsModal && (
        <StyledModalDraggable
          onClose={handleCloseContactsModal}
          title="Contacts"
          centered
          wide
        >
          <PermitViewContacts
            permit={permit}
            setContactUpdated={() => {}}
            noEdit
          />
        </StyledModalDraggable>
      )}
    </Container>
    </>
  );
};

const StatusContainer = styled.div`
 position: relative;;
`;
const Container = styled.fieldset`
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  border: none;
  text-align: center;
`;

const FormContainer = styled.form`
  width: 100%;

  .MuiButton-root {
    margin-top: 24px;
  }
`;
const ContactsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  & button {
    margin-top: 6px !important;
  }
`;

const ContactsInputWrapper = styled.div`
  width: 75%;
  text-align: left;
`;

const ContactsButtonWrapper = styled.div`
  margin-top: 28px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const CheckboxesError = styled.p`
  color: rgb(244, 67, 54);
  font-size: 0.75rem;
  margin: 3px 14px 0px;
  text-align: left;
`;

const CheckboxesWrapper = styled.div<Error>`
  ${({ checkboxesError }) =>
    checkboxesError
      ? 'border: 1px solid rgb(244, 67, 54);'
      : ''}
`;

const StyledModalDraggable = styled(ModalDefaultDraggable)`
  height: 100%;
  overflow: inherit;
`;

const MobileRemove = styled.div`
  @media only screen and (max-width: 840px) {
    display: none;
  }
`;
interface Props {
  addButtonText?: string;
  updateButtonText?: string;
  initialValues?: any;
  allActivities?: boolean;
  onSubmit: (data) => void;
  initialErrors?: FormServerError<any>;
  modalError: boolean;
  permit: number;
  isReadOnly?: boolean;
  isSimulateDeleted?: boolean;
  cameFromSearch?:boolean;
}

interface Error {
  checkboxesError: string;
}

export default PermitInspectionsForm;