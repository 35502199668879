import { createPermit } from "../api/permits";
import { createProject, markProjectCandidateAsProcessed } from "../api/projects";
import { setModalOpen } from "../redux/slices/inspectionsSlice";
import { setNewProjectModalOpen } from "../redux/slices/projectSlice";

export const updatePMResults = (results: any, params: any) => {
  if (!results) {
    return [];
  }
  const token = params.search;
  const _results = results.reduce((arr, item) => {
    let inspectionCount = 0;
    const inspArr = item.inspections.split(',');
    if (inspArr.length === 1) {
      if (item.inspections !== '') {
        inspectionCount = inspArr.length;
      }
    } else {
      inspectionCount = inspArr.length;
    }
    if (item.managerName?.toLowerCase().indexOf(token.toLowerCase()) > -1) {
      arr.push({ ...item, inspections: `${inspectionCount}` });
    }
    return arr;
  }, []);
  return _results.sort((a, b) => {
    if (a?.managerEmail > b?.managerEmail) {
      return 1;
    }
    if (a?.managerEmail < b?.managerEmail) {
      return -1;
    }
    return 0;
  });
};

export const getAccountingInfo = (contacts, permitContactsRedux) => {
  return contacts.map(c => {    
    const contact = permitContactsRedux.find(contact => {
      const id = contact.user ? contact.user : contact.id;
      return id === c.id;
    });
    return {
      ...c,
      isAccounting: contact ? contact.isAccounting : c.isAccounting,
    };
  });
};

export const persistNewProjectData = (projectCandidate,selectedProject,payload1, payload2,dispatch, refetch) => {
  const create = async () => {
    if(selectedProject){
      //updating permit for this project
      await createPermit(payload2);
    } else {
      await createProject(payload1);
    }

    
    const _payload = {
      id:projectCandidate.id,
      is_visible:false,
      project_status:'New'
    }
    await markProjectCandidateAsProcessed(_payload);
    await refetch();
    dispatch(setModalOpen(false));
    dispatch(setNewProjectModalOpen(false));
  }
  create();
}