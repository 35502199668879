import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/root';
import { dispatch } from '../../redux/store';
import { setDeleteError, setShowDeleteMessage } from '../../redux/slices/inspectionsSlice';
import styled from 'styled-components';

const InspectionDeletePopup = () => {
  const { showDeleteMessage, deleteError } = useSelector((s: RootState) => s.inspections);
  const [message, setMessage] = useState<string>('');
  useEffect(() => {
    if (deleteError) {
      setMessage('Inspection cannot be deleted!');
    } else {
      setMessage('Inspection deleted successfully');
    }
  }, [deleteError]);
  const handleClose = () => {
    dispatch(setShowDeleteMessage(false));
    dispatch(setDeleteError(false));
  };
  return (
    <React.Fragment>
      <Dialog
        open={showDeleteMessage}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Header isError={deleteError}>{deleteError ? 'Exception' : 'Information'}</Header>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <span>{message}</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default InspectionDeletePopup;

const Header = styled.span<{ isError: boolean }>`
  color: ${({ isError }) => (isError ? 'red' : 'inherit')};
`;
