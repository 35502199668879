export const PM_PT_TITLE = 'PM/PT processing';
export const isActivityTitleDisabled = (
  selectedTitle,
  isLoading,
  isReadOnly,
  initialValues,
  completedStatus,
  cameFromSearch,
  isInspector,
) => {
  if (selectedTitle && selectedTitle.name === PM_PT_TITLE) {
    return true;
  }

  return (
    isLoading ||
    isReadOnly ||
    (initialValues?.activityType === 'Fee' && initialValues?.title === 'Invoice') ||
    completedStatus ||
    cameFromSearch ||
    isInspector
  );
};

export const isActivityContactDisabled = (selectedTitle, isLoading, isReadOnly, completedStatus, cameFromSearch, isInspector) => {
  if (selectedTitle && selectedTitle.name === PM_PT_TITLE) {
    return true;
  }
  return isLoading || isReadOnly || completedStatus || cameFromSearch || isInspector;
};

export const isFollowupDisabled = (selectedTitle, isReadOnly, completedStatus, cameFromSearch, isInspector) => {
  // if (selectedTitle && selectedTitle.name === PM_PT_TITLE) {
  //   return true;
  // }
  return isReadOnly || completedStatus || cameFromSearch || isInspector;
};

export const isAssigneeDisabled = (selectedTitle, isLoading, isReadOnly, initialValues, completedStatus, cameFromSearch, isInspector) => {
  if (selectedTitle && selectedTitle.name === PM_PT_TITLE) {
    return false;
  }

  return (
    isLoading ||
    isReadOnly ||
    completedStatus ||
    cameFromSearch ||
    isInspector
  );
};
