import * as React from 'react';
import { yupResolver } from '@hookform/resolvers';
import { useForm, Controller } from 'react-hook-form';
import styled from 'styled-components';
import * as yup from 'yup';
import useTablePage from '../../hooks/useTablePage';

import {
  ButtonWithLoader,
  InputBaseContainer,
  InputFileContainer,
  InputTextWithLabel,
} from '../../molecules';
import { FormServerError } from '../../types';
import CommentsChat from '../../organisms/CommentsChat';
import Table from '../../organisms/Table';
import {
  createPermitFee,
  createPermitFeesComment,
  getFeesList,
  getPermitFeesComments,
  manualUpdatePermitFees,
  voidUpdatePermitFees,
  setFeeAsPaid,
} from '../../api/fees';
import {
  DATETIME_FORMAT,
  GET_ALL_QUERY_PARAMS,
} from '../../constants';
import { formatDateTime } from '../../utils/formatDate';
import { convertTableAmountToCurrency } from '../../utils/convertTableValues';
import tableColumnsFormatter from '../../utils/tableColumnsFormatter';
import { getVocabulariesDataList } from '../../api/vocabulary';
import ModalInfo from '../../molecules/ModalInfo';
import { getCurrentUser } from '../../api/userSettings';
import isYupFieldRequired from '../../utils/isYupFieldRequired';
import CharCounter from '../../Components/CharCounter';
import StatusCheckboxes from './StatusCheckboxes';
import FeeFileAttachment from './FeeFileAttachment';
import PermitFeesForm1 from './PermitFeesForm1';
import BalanceControl from './BalanceControl';
import { useState } from 'react';

const labels = {
  feeName: 'Fee Name',
  notes: 'Fee Note',
  quantity: 'Quantity',
  amount: 'Amount',
  serviceType: 'Fee Service Type',
  isPaid: 'Paid',
  paidManually: 'Paid by another method',
  dateCreated: "Date Created",
  createdBy: "Created By",
  dateCompleted: "Date Completed",
  completedBy: "Completed By",,
  void : 'Void'
};

const schema = yup.object().shape({
  quantity: yup
    .number()
    .label(labels.quantity)
    .nullable()
    .typeError('Invalid quantity value'),
  amount: yup
    .string()
    .label(labels.amount)
    .nullable()
    .typeError('Invalid amount value'),
});

const PermitFeesForm = ({
  onSubmit,
  initialValues,
  modalError,
  addButtonText = 'Add new Fee',
  updateButtonText = 'Update the Fee',
  initialErrors,
  permit,
  jurisdiction,
  handleCloseModal,
  showSuccessModal,
  cameFromSearch,
  isReadOnly,
  updatePermitFeesTable,
  isPaidManuallySubmit,
  setIsPaidManuallySubmit
}: Props) => {
  const { updateTable, tableManuallyUpdate } = useTablePage(
    {},
  );

  const [fees, setFees] = React.useState([]);
  const [feesEntities, setFeesEntities] = React.useState(
    [],
  );

  const [
    quantifiedFees,
    setQuantifiedFees,
  ] = React.useState([]);

  const [
    allFeesSelected,
    setAllFeesSelected,
  ] = React.useState(false);

  const [selectedFees, setSelectedFees] = React.useState(
    [],
  );
  
  const [isSubmitting, setIsSubmitting] = React.useState<
    boolean
  >(false);
  const [
    isMoreThanMillion,
    setIsMoreThanMillion,
  ] = React.useState(false);
  const [modifiedSelectedData, setModifiedSelectedData] = React.useState([])
  const [commentCreated, setCommentCreated] = React.useState(null);
  const [balance, setBalance] = useState<number>(0);
  const [isReimbursement] = React.useState<boolean>(initialValues ? initialValues.name ==='Reimbursement' : false);
  const isDeleted = initialValues?.isDeleted;

  const {
    handleSubmit,
    errors,
    register,
    control,
    formState,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialValues || ({} as any),
  });

  const quantityValue = watch('quantity');
  const notes = watch('notes');
  React.useEffect(() => {
    // modifiedGetListFees()
    if (
      JSON.stringify(selectedFees) !== JSON.stringify(fees)
    ) {
      setAllFeesSelected(false);
    }
    if (
      selectedFees?.length &&
      fees?.length &&
      JSON.stringify(selectedFees?.sort()) ===
        JSON.stringify(fees?.sort())
    ) {
      setAllFeesSelected(true);
    }
    if (!selectedFees?.length) {
      setQuantifiedFees([]);
    }
  }, [selectedFees]);

  React.useEffect(() => {
    if (allFeesSelected) {
      const allSelectedFees = feesEntities?.filter(fee => fees?.includes(fee?.id)).map(el => {
        return {
          id: el?.id,
          feeQuantity: 1,
          amount: el?.amount
        }
      })      
      setModifiedSelectedData(allSelectedFees)      
      setSelectedFees(fees);
      updateTable();
      if (!quantifiedFees?.length && fees?.length) {
        
        setQuantifiedFees(
          fees.map(el => {
            return { id: el, feeQuantity: 1, amount: feesEntities?.find(elm => elm?.id === el)?.amount};
          }),
        );
      }
      if (quantifiedFees?.length && fees?.length) {
        const quantifiedIds = quantifiedFees.map(
          el => el?.id,
        );
        const nonQuantifiedIds = fees.filter(
          el => !quantifiedIds?.includes(el),
        );
        nonQuantifiedIds.forEach(el =>
          setQuantifiedFees([
            ...quantifiedFees,
            { id: el, feeQuantity: 1, amount: feesEntities?.find(elm => elm?.id === el)?.amount },
          ]));
      }
    }
    if (
      !allFeesSelected &&
      fees.length &&
      JSON.stringify(selectedFees?.sort()) !==
        JSON.stringify(fees?.sort())
    ) {
      updateTable();
    }
    if (
      !allFeesSelected &&
      fees.length &&
      JSON.stringify(selectedFees?.sort()) ===
        JSON.stringify(fees?.sort())
    ) {
      setSelectedFees([]);
      updateTable();
    }
  }, [allFeesSelected]);

  React.useEffect(() => {
    if (
      formState.isSubmitting &&
      !Object.keys(errors).length
    ) {
      setIsSubmitting(formState.isSubmitting);
    }
  }, [formState]);

  React.useEffect(() => {
    if (Object.keys(errors).length) {
      setIsSubmitting(false);
    }
  }, [errors, initialErrors]);

  const modifiedGetCommentsRequest = (isSystemMsg) => {
    return Promise.all([
      getPermitFeesComments(
        {...GET_ALL_QUERY_PARAMS,system_message:isSystemMsg},
        permit,
        initialValues?.id,
      ),
    ]).then(([commentsResponse]) => {
      return {
        ...commentsResponse,
        data: {
          ...commentsResponse.data,
          results: commentsResponse.data.results.map(
            comment => ({
              ...comment,
              creator: comment.username,
              createdAt: formatDateTime(
                comment?.createdAt,
                DATETIME_FORMAT,
              ),
            }),
          ),
        },
      };
    });
  };

 

  const modifiedGetListFees = params =>
    Promise.all([
      getFeesList({
        ...params,
        jurisdiction: String(jurisdiction),
      }),
      getVocabulariesDataList({
        ...GET_ALL_QUERY_PARAMS,
        vocabularyEntity: 11,
        ordering: 'title',
      }),
    ])
      .then(([feesResponse, vocabularyList]) => {      
        setFeesEntities(feesResponse.data.results);
        setFees(
          feesResponse.data.results.map(el => el?.id),
        );
        return {
          ...feesResponse,
          data: {
            ...feesResponse.data,
            results: feesResponse.data.results.map(elem => {
              const feeServiceTypeName = vocabularyList.data.results.find(
                vocab => vocab.id === elem.feeServiceType,
              );

              const indexElem = quantifiedFees?.findIndex(item=>item.id==elem.id);
              
              const modifiedElem = modifiedSelectedData?.findIndex(item=>item.id==elem.id);          
              return {
                ...elem,
                feeServiceType: feeServiceTypeName.title,
                checkBox: null,
                feeChecked: selectedFees.includes(elem?.id),
                // eslint-disable-next-line no-nested-ternary
                feeQuantity: selectedFees.includes(elem?.id)?modifiedSelectedData[modifiedElem]?.feeQuantity:quantifiedFees?.length ? 
                quantifiedFees?.find(
                  quantFee => quantFee?.id === elem?.id,
                )?.feeQuantity
              : selectedFees.includes(elem?.id)
              ? 1
              : '',
                amount: selectedFees.includes(elem?.id) ? modifiedSelectedData[modifiedElem]?.amount : indexElem > -1 ? quantifiedFees[indexElem].amount : elem.amount,
              };
            }),
          },
        };
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
      .finally(() => {});

      React.useEffect(() => {
        if(!initialValues)
          modifiedGetListFees()
      },[])

  const columns = column => {
    if (
      [
        'permit',
        'id',
        'creator',
        'franchise',
        'franchiseNames',
        'jurisdiction',
        'jurisdictionNames',
        'isActive',
        'skuCode',
        'description',
        'isDeleted',
        'reimburse',
        'employeeCanEdit',
        'feeType',
        'isFranchiseAll',
        'isDefault',
        'feeChecked',
      ].includes(column)
    ) {
      return null;
    }

    switch (column) {
      case 'name':
        return 'Fee name';
      case 'amount':
        return 'Amount';
      case 'feeServiceType':
        return 'Fee Service Type';
      case 'feeQuantity':
        return 'Quantity';
      default:
        return tableColumnsFormatter(column);
    }
  };

  const columnsOrder = [
    'checkBox',
    'name',
    'amount',
    'feeServiceType',
    'feeQuantity',
  ];

  const htmlToText = (html) => {
    const tempEl = document.createElement('div');
    tempEl.innerHTML = html;
    const plainText = tempEl.textContent;
    return plainText
  }

  const middleware = (submitFn: (data) => void) => data => {
    // adding paid with another method functionality    
    if (data?.isVoid && !initialValues?.isVoid) {
      submitFn({
        permit,
        name: initialValues?.name,
        id: initialValues?.id,
      }).then(() => {
        // taking data from initial values
        voidUpdatePermitFees({
          permit, id: initialValues?.id, method: 'manual', quantity: initialValues?.quantity,
          amount: parseFloat(initialValues?.amount?.includes("$") ? initialValues.amount.split("$")[1] : initialValues?.amount),
          status: "Void",
          internal_note: commentCreated ? htmlToText(commentCreated?.body) : null
        })
      });
    } else {

      if (data?.isPaid) {
        let completedBy;
        Promise.all([getCurrentUser()]).then(([{ data }]) => {
          completedBy = data.id
        }).then(() => {
          const today = new Date();
          Promise.all([manualUpdatePermitFees({ permit, id: initialValues?.id, method: 'stripe' })]).then(() => {
            updatePermitFeesTable();
          });
          submitFn({
            permit,
            name: initialValues?.name,
            quantity: data.quantity,
            amount: parseFloat(data.amount.includes("$") ? data.amount.split("$")[1] : data.amount),
            id: initialValues?.id,
            isPaid: data?.isPaid,
            isPaidManual: true,
            notes: data?.notes ? data.notes : null,
            file: data?.file ? data.file : null,
            fileName: data?.fileName ? data.fileName: null,
            completedAt: (initialValues && !initialValues?.status.toLowerCase().includes("paid") && data?.isPaid === true) ? `${today.toLocaleDateString()}` : null,
            completedBy: (initialValues && !initialValues?.status.toLowerCase().includes("paid") && data?.isPaid === true) ? completedBy : null
          });
        })

      } else if (data?.paidManually) {
        let completedBy = ""

        Promise.all([getCurrentUser()]).then(([{ data }]) => {
          completedBy = data?.firstName + " " + data?.lastName
        }).then(() => {
          const today = new Date();
          submitFn({
            permit,
            name: initialValues?.name,
            id: initialValues?.id,
            completedAt: (initialValues && !initialValues?.status.toLowerCase().includes("paid") && data?.paidManually === true) ? `${today.toLocaleDateString()}` : null,
            completedBy: (initialValues && !initialValues?.status.toLowerCase().includes("paid") && data?.paidManually === true) ? completedBy : null
          }).then(() => {
            setIsPaidManuallySubmit(true);
            Promise.all([
              manualUpdatePermitFees({
                permit, id: initialValues?.id,
                method: 'manual',
                quantity: initialValues?.quantity,
                amount: initialValues.amount ? parseFloat(`${initialValues.amount}`.replace(/\$/,'')) : 0,
                internal_note: commentCreated ? htmlToText(commentCreated?.body) : null
              })
            ]).then(() => {
              setIsPaidManuallySubmit(false);
              updatePermitFeesTable();
            });
          })
        })
      } else {

        const s_amount = data.amount ? data.amount : initialValues.amount;
        const amount = parseFloat(`${s_amount}`.replace(/[$,]/g, ''));
        submitFn({
          permit,
          name: initialValues?.name,
          quantity: data.quantity,
          amount: amount,
          id: initialValues?.id,
          isPaid: data?.isPaid,
          notes: data?.notes ? data.notes : null,
          file: data?.file ? data.file : null,
          fileName: data?.fileName ? data.fileName: null,
        });
      }
    }
  };

  const createFeeMiddleware = (
    submitFn: (data) => void,
  ) => {
    const quantifiedIds = quantifiedFees.map(el => el?.id);
    const selected = feesEntities?.filter(el =>
      quantifiedIds?.includes(el?.id),
    );
    const amountFees = modifiedSelectedData.map(el => el.amount);
    
    const totalAmountFees = amountFees.reduce(
      (previousValue, currentValue) =>
        Number(previousValue) + Number(currentValue),
    );
    
    modifiedSelectedData.forEach(el => {
      if (
        totalAmountFees * Number(el.feeQuantity) >
          99999.0 ||
        totalAmountFees > 99999.0
      ) {
        setIsSubmitting(false);
        setIsMoreThanMillion(true);
      } 
    });
        // EA-136
        // mritunjoy
        if (Array.isArray(selected) && selected?.length) {          
          selected.forEach(fee => {
            submitFn({
              permit,
              name: fee?.name,
              amount: modifiedSelectedData?.find(
                item => item?.id === fee?.id,
              )?.amount,
              feeSettings: fee?.id,
              quantity: modifiedSelectedData?.find(
                item => item?.id === fee?.id,
              )?.feeQuantity,
            });
          });
        }
        showSuccessModal(true);
        handleCloseModal();    
  };

  const handleFeeSelect = (e, cell) => {
    setModifiedSelectedData((prevState) => [...prevState?.filter(el => el.id !== cell?.row?.original?.id), {id: cell?.row?.original?.id, amount: cell?.row?.original?.amount, feeQuantity:1 }])
    if (!e?.target?.checked) {
      const updatedFees = selectedFees?.filter(
        el => el !== cell?.row?.original?.id,
      );
      const updatedQuantized = quantifiedFees?.filter(
        el => el?.id !== cell?.row?.original?.id,
      );
      setQuantifiedFees(updatedQuantized);
      setSelectedFees(updatedFees);
      // localStorage.setItem()
      updateTable();
    }
    if (e?.target?.checked) {
      const updatedFees = [
        ...selectedFees,
        cell?.row?.original?.id,
      ];

      const isNotQuantized = !quantifiedFees
        ?.map(el => el?.id)
        ?.includes(cell?.row?.original?.id);
      if (quantifiedFees?.length && isNotQuantized) {
        setModifiedSelectedData((prevState) => [...prevState?.filter(el => el.id !== cell?.row?.original?.id), {id: cell?.row?.original?.id, amount: cell?.row?.original?.amount, feeQuantity:1 }])
        setQuantifiedFees([
          ...quantifiedFees,
          { id: cell?.row?.original?.id, feeQuantity: 1,
             amount: cell?.row?.original?.amount,
             },
        ]);
        // setFees(feesEntities.map(el => el.id))
      }
      if (!quantifiedFees?.length) {
        setModifiedSelectedData([{id: cell?.row?.original?.id, amount: cell?.row?.original?.amount, feeQuantity:1 }])
        setQuantifiedFees([
          { id: cell?.row?.original?.id, feeQuantity: 1, amount: cell?.row?.original?.amount,},
        ]);
      }
      setSelectedFees(updatedFees);
      updateTable();
    }
  };

  const handleAllFeesSelect = () => {
    setAllFeesSelected(!allFeesSelected);
  };

  const handleQuantityChange = (e, cell, qty) => {
    const fee = cell?.row?.original;
    const feeQuantity = e?.target?.value;

    if (quantifiedFees.length) {
      setModifiedSelectedData((prevState) => [...prevState?.filter(el => el.id !== fee?.id), {id: cell?.row?.original?.id, amount: prevState.find(e => e.id === cell?.row?.original?.id)?.amount, feeQuantity:qty }])
      setQuantifiedFees([
        ...quantifiedFees?.filter(el => el.id !== fee?.id),
        { ...fee, feeQuantity: qty },
      ]);
      // updateTable();
    }

    if (!quantifiedFees.length) {
      setModifiedSelectedData([{id: cell?.row?.original?.id, amount: modifiedSelectedData[0]?.amount, feeQuantity:qty }])
      setQuantifiedFees([{ ...fee, feeQuantity: qty }]);
      // updateTable();
    }
  };
  
  const handleAmountChange = (e, cell, amt) => {
    const fee = cell?.row?.original;
    const amount = e?.target?.value;
    if (quantifiedFees.length) {
      setModifiedSelectedData((prevState) => [...prevState?.filter(el => el.id !== fee?.id), {id: cell?.row?.original?.id, amount: amt, feeQuantity:prevState.find(e => e.id === cell?.row?.original?.id)?.feeQuantity }])
      setQuantifiedFees([
        ...quantifiedFees?.filter(el => el.id !== fee?.id),
        { ...fee, amount: amt },
      ]);
      // updateTable();
    }

    if (!quantifiedFees.length) {
      setModifiedSelectedData([{id: cell?.row?.original?.id, amount: amt, feeQuantity:modifiedSelectedData[0]?.feeQuantity }])
      setQuantifiedFees([{ ...fee, amount: amt }]);
      // updateTable();
    }
  };

const isUpdateDisabled = () => {
  if(isNaN(quantityValue) || quantityValue < 1){
    return true;
  }

  return initialValues?.status?.toLowerCase().includes('paid') || initialValues?.status === 'Void' ||
  (isSubmitting && !modalError) || cameFromSearch || isReadOnly || isPaidManuallySubmit
}

  return (
    <Container
      disabled={isDeleted || (isSubmitting && !modalError ) || isReadOnly || isPaidManuallySubmit}
    >
      {initialValues ? (
        <FormContainer
          autoComplete="off"
          aria-autocomplete="none"
          onSubmit={handleSubmit(middleware(onSubmit))}
        >
          <InputBaseContainer>
            <InputTextWithLabel
              name="quantity"
              error={errors.quantity}
              inputRef={register}
              label={labels.quantity}
              isRequired
              disabled={initialValues?.status !== "Pending" || isReadOnly}
              InputProps={{
                readOnly:
                  initialValues?.status === 'Sent' ||
                  initialValues?.status === 'Paid' ||
                  initialValues?.status === 'Paid by Another Method' ||
                  cameFromSearch
              }}
            />
          </InputBaseContainer>
          <InputBaseContainer>
            <InputTextWithLabel
              name="amount"
              error={errors.amount}
              inputRef={register}
              label={labels.amount}
              isRequired
              disabled={initialValues?.status !== 'Pending' || isReadOnly}
              InputProps={{
                readOnly:
                  initialValues?.status === 'Sent' ||
                  initialValues?.status === 'Paid' ||
                  initialValues?.status === 'Paid by Another Method' 
                   ||
                  cameFromSearch
              }}
            />
          </InputBaseContainer>
          <BalanceControl feeId={initialValues.id} setUnpaidBalance={setBalance}/>
          {initialValues && <>
            <InputBaseContainer>
            <InputTextWithLabel
              style={{backgroundColor: '#F2F2F2', opacity: 0.8}}
              name="createdAt"
              error={errors.dateCreated}
              inputRef={register}
              label={labels.dateCreated}
              isRequired={false}
              disabled={true}
            />
          </InputBaseContainer>
          <input type="hidden" name="fileName" ref={register}/>
          <InputBaseContainer>
            <InputTextWithLabel
              style={{backgroundColor: '#F2F2F2', opacity: 0.8}}
              name="createdBy"
              error={errors.createdBy}
              inputRef={register}
              label={labels.createdBy}
              isRequired={false}
              disabled={true}
            />
          </InputBaseContainer>
          <InputBaseContainer>
            <InputTextWithLabel
              style={{backgroundColor: '#FFF'}}
              name="notes"
              error={errors.notes}
              inputRef={register}
              label={labels.notes}
              isRequired={false}
              disabled={false}
            />
          </InputBaseContainer>
          <CharCounter maxChars={140} notes={notes} setValue={setValue}/>
          </>}
          <CheckboxesWrapper>
              <StatusCheckboxes 
                  initialValues={initialValues}
                  control={control}
                  errors={errors}
                  labels={labels}
                  cameFromSearch={cameFromSearch}
                  isReadOnly={isReadOnly}
                  /> 
              { isReimbursement && 
                <FeeFileAttachment 
                initialValues={initialValues}
                control={control}
                errors={errors}
                schema={schema}
                isYupFieldRequired={isYupFieldRequired}
                setValue={setValue}
              />}
          </CheckboxesWrapper>
          
          {!isDeleted && (
            <ButtonWithLoader
              disabled={isUpdateDisabled()}
              loading={(isSubmitting && !modalError) || isPaidManuallySubmit}
              type="submit"
            >
              {initialValues
                ? updateButtonText
                : addButtonText}
            </ButtonWithLoader>
          )}
          {initialValues ? (
            <CommentsChat
           isReadOnly = {isReadOnly}
              commentsRequest={modifiedGetCommentsRequest}
              entityData={initialValues}
              postRequest={createPermitFeesComment}
              permit={permit}
              noFile
              title={'Comments List'}
              buttonText='Add Comment'
              isReadOnly={cameFromSearch}
              commentCreated={commentCreated}
              setCommentCreated={setCommentCreated}
            />
          ) : null}
        </FormContainer>
      ) : (
        <PermitFeesForm1 
           setIsMoreThanMillion={setIsMoreThanMillion} 
           setIsSubmitting={setIsSubmitting}
           showSuccessModal={showSuccessModal}
           handleCloseModal={handleCloseModal}/> 
      )}
      {isMoreThanMillion && (
        <ModalInfo
          timeToClose={5000}
          isError
          onClose={handleCloseModal}
          errorMsg="
          Total amount of fees is more than $99,999.99
          "
        />
      )}
    </Container>
  );
};

const CheckboxesWrapper = styled.div`
 display: flex;
 flex-direction: row;
 justify-content: space-between;
`;

const Container = styled.fieldset`
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  border: none;
  text-align: center;
`;

const FormContainer = styled.form`
  width: 100%;

  .MuiButton-root {
    margin-top: 24px;
  }
`;

interface Props {
  addButtonText?: string;
  updateButtonText?: string;
  initialValues?: any;
  onSubmit: (data) => void;
  initialErrors?: FormServerError<any>;
  modalError: boolean;
  permit: number;
  jurisdiction: number;
  handleCloseModal: () => void;
  showSuccessModal: (data: boolean) => void;
  updatePermitFeesTable?: () => void; 
  setIsPaidManuallySubmit?: (data: boolean) => void;
}

export default PermitFeesForm;
