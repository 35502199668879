import { error } from 'console';
import React, { FC, useEffect } from 'react';
import { StringKey } from 'react-table';
import styled from 'styled-components';

type Props = {
  register: any;
  name: string;
  dropdownItems: any[];
  setValue: any;
  trigger: any;
};
const FranchiseContacts: FC<Props> = ({dropdownItems,name,register}) => {
  return (
    <div>
      <Select name={name} ref={register}>
        <option></option>
        {dropdownItems.map((item, index)=><option key={index} value={item.id}>{item.title}</option>)}        
      </Select>
    </div>
  );
};

export default FranchiseContacts;

const Select = styled.select`
  width: 100%;
  margin-top: 20px;
  color: #000;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid var(--secondaryText);  
`;
