import React from 'react';
import PrivateRoute from '../../../../organisms/PrivateRoute';
import Conditions from '../pages/Conditions/Conditions';
import Documents from '../pages/Documents/Documents';
import Invoices from '../pages/Invoices/Invoices';
import Inspections from '../pages/Inspections/Inspections';
import Projects from '../pages/Projects/Projects';
import ClientContainer from '../pages/Projects/Projects';
import Tickets from '../pages/Tickets/Tickets';
import UserAccount from '../pages/UserAccount';
import HelpPage from '../pages/HelpPage/HelpPage';
import PayInvoice from '../pages/Invoices/PayInvoice';

export const CLIENT_PATH = '/client';
export const ROUTES = {
  DASHBOARD: `/dashboard`,
  PROJECTS: `${CLIENT_PATH}/projects`,
  INSPECTIONS: `${CLIENT_PATH}/inspections`,
  DOCUMENTS: `${CLIENT_PATH}/documents`,
  CONDITIONS: `${CLIENT_PATH}/conditions`,
  INVOICES: `${CLIENT_PATH}/invoices`,
  INVOICE: `${CLIENT_PATH}/invoice/`,
  TICKETS: `${CLIENT_PATH}/tickets`,
  ACCOUNT: `${CLIENT_PATH}/account`,
  HELP: `${CLIENT_PATH}/help`,
};

const ClientRoutes = () => {
  return (
    <>
      <PrivateRoute path={ROUTES.PROJECTS} component={Projects} />
      <PrivateRoute path={ROUTES.INSPECTIONS} component={Inspections} />
      <PrivateRoute path={ROUTES.DOCUMENTS} component={Documents} />
      <PrivateRoute path={ROUTES.CONDITIONS} component={Conditions} />
      <PrivateRoute path={ROUTES.INVOICES} component={Invoices} />
      <PrivateRoute path={ROUTES.INVOICE} component={PayInvoice} />
      {/* <PrivateRoute path={ROUTES.TICKETS} component={Tickets} /> */}
      <PrivateRoute path={ROUTES.ACCOUNT} component={UserAccount} />
      <PrivateRoute path={ROUTES.HELP} component={HelpPage} />
    </>
  );
};

export default ClientRoutes;
