
type InspectionItem = {
    inspectionDate: string;
    activityTypeName: string;
}
type SortedInspectionItemType = {
    inspectionDate: string;
    rows: InspectionItem[];
}
export const Sort = arr => {
    let buckets:SortedInspectionItemType[] = arr.reduce((buckArr,item:InspectionItem)=>{
        const bucket = buckArr.find(bi => bi.inspectionDate === item.inspectionDate);
        if(bucket){
            bucket.rows.push(item);
        } else {
            const _bucket:SortedInspectionItemType = {
                inspectionDate: item.inspectionDate,
                rows: [item]
            }
            buckArr.push(_bucket)
        }
        return buckArr;
    },[]);

    buckets = buckets.sort((a,b)=>{
        if(new Date(a.inspectionDate) > new Date(b.inspectionDate)) return 1;
        if(new Date(a.inspectionDate) < new Date(b.inspectionDate)) return -1;
        return 0;
    })

    let finalList = [];
    buckets = buckets.reduce((withFinalArr, item)=>{
        const _sortedBucket = item.rows.reduce((itemRowsArr,itemRow)=>{
            if(itemRow.activityTypeName.toLowerCase().indexOf('final building') > -1 ||
               itemRow.activityTypeName.toLowerCase().indexOf('building final') > -1){
                finalList.push(itemRow);
            } else {
                itemRowsArr.push(itemRow)
            }
            return itemRowsArr;
        },[]);


        withFinalArr.push({inspectionDate:item.inspectionDate, rows: [..._sortedBucket,...finalList]});
        finalList = [];
        return withFinalArr;
    },[]);
    
    const sortedArr = buckets.reduce((allItems,bucket)=>{
        const _allItems = [...allItems,...bucket.rows];
        return _allItems;
    },[]);
    return sortedArr;
}