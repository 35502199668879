import React from 'react';
import ClientContainer from '../../ClientContainer';
import BannerStrip from '../../Common/Containers/BannerStrip';
import WrapperWithRightDrawer from '../../Common/Containers/WrapperWithRightDrawer/WrapperWithRightDrawer';
import Toturial from '../Dashboard/Tutorial/Toturial';
import styled from 'styled-components';

const HelpPage = () => {
  return (
    <ClientContainer>
      <BannerStrip label="HELP"></BannerStrip>
      <WrapperWithRightDrawer>
        <Wrapper>
          <Toturial onHelpPage={true}></Toturial>
        </Wrapper>
      </WrapperWithRightDrawer>
    </ClientContainer>
  );
};

export default HelpPage;

const Wrapper = styled.div`
  width: 874px;
  position: relative;
  left: -121px;
`;
