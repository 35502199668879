import * as React from 'react';
import styled from 'styled-components';
import { DEFAULT_DATE_FORMAT } from '../../constants';
import { formatDateTime } from '../../utils/formatDate';
import tableColumnsFormatter from '../../utils/tableColumnsFormatter';
import { getReviewerComments } from '../../api/projectDocuments';
import { RootState } from '../../redux/root';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import ReviewCommentsPopup from './ReviewCommentsPopup';
import { generatePDFCalculateSplit } from '../../Components/InvoiceTool/InvoiceUtil';
import { setReviewComments } from '../../redux/slices/documentsSlice';

const ReviewCommentLetter = React.forwardRef(({ reviews,setShowReviewCommentLtr }: Props, ref: any) => {
  const reviewComments = useSelector((s:RootState) => s.documents.ReviewComments);
  const pdfRef = useRef<HTMLDivElement>();
  const headerRef = useRef<HTMLDivElement>();
  const header2Ref = useRef<HTMLDivElement>();
  // const ListHeaderRef = useRef<HTMLDivElement>();
  const tablesRef = useRef([]);
  const counterRef = useRef(-1);
  const dispatch = useDispatch();

  const info = useSelector((s: RootState) => s.userAuth.info);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [franchiseName, setFranchiseName] = useState<string>('');

  useEffect(() => {
    if (info && info.franchise) {
      const selectedFranchise = info.franchise.find(f => f.id === info.selectedFranchise);
      if (selectedFranchise) {
        setFranchiseName(selectedFranchise.name);
      }
    }
  }, [info]);

  React.useEffect(() => {
    const promises = [];
    reviews?.map(review => promises.push(getReviewerComments(review.id,true)));
    Promise.all(promises).then(response => {
      const arr = response.map(res => res.data);
      dispatch(setReviewComments(arr));
    });
  }, [reviews]);

  const marginTop = '50px';
  const marginRight = '5px';
  const marginBottom = '10px';
  const marginLeft = '5px';
  const getPageMargins = () => {
    return `@page { margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }`;
  };

  const generatePDF = async () => {
    return await generatePDFCalculateSplit(headerRef,header2Ref,tablesRef)
  };

  return (
    <Wrapper>
      <PrintButton>
        <div onClick={e => setShowPopup(true)}>Save / Email</div>
      </PrintButton>
      {showPopup && <ReviewCommentsPopup setShowReviewCommentLtr={setShowReviewCommentLtr} setShowPopup={setShowPopup} generatePDFAutoPageSplit={generatePDF} />}     
      <PrintedArea ref={pdfRef}>
        <HeadWrapper ref={headerRef}>
          <h1>ELITE PERMITS of {franchiseName}</h1>
          <h3>Exceptional Service For All Your Permitting Needs</h3>
          <p>Permitting | Private Provider Review and Inspections | Building Code Consultants</p>
          <hr />
        </HeadWrapper>
        <div ref={header2Ref}>
        <Title>Review Comments</Title>

        <List>
          <li>
            <Bold>Project Name: </Bold>
            {reviews && reviews[0]?.projectName}
          </li>
          <li>
            <Bold>Permit Name: </Bold>
            {reviews && reviews[0]?.permitName}
          </li>
          <li>
            <Bold>Project Location: </Bold>
            {reviews && reviews[0]?.projectAddress}
          </li>
          <li>
            <Bold>Date: </Bold>
            {formatDateTime(new Date(), DEFAULT_DATE_FORMAT)}
          </li>
        </List>
        <p>
          Please make all applicable changes to the permit set and provide (1) letter responding to the comments to expedite the review
          process. For electronic permitting we will need one (1) electronically sealed set of all applicable construction documents.{' '}
        </p>
        </div>
        {reviewComments &&
          reviewComments?.map((item, idx) => {
            if (item?.length) {
              counterRef.current = counterRef.current + 1;
              const ListCnt = Number(counterRef.current);
              return (
                <>                
                <div ref={el => (tablesRef.current[ListCnt] = el)}>
                  <List >
                    <li>
                      <Bold>{reviews[idx]?.reviewType} Review</Bold>
                    </li>
                    <li>
                      <Bold>
                        Reviewed By: {reviews[idx]?.inspectorFirstName} {reviews[idx]?.inspectorLastName}
                      </Bold>
                    </li>
                    <li>
                      <Bold>Office Phone: {reviews[idx]?.franchisePhoneNumber}</Bold>
                    </li>
                    <li>
                      <Bold>Email: {reviews[idx]?.inspectorEmail}</Bold>
                    </li>
                  </List>
                </div>
                  <CommentsTable>
                      <HeaderRow ref={el => (tablesRef.current[ListCnt + 1] = el)}>
                          <Header>#</Header>
                          <Header>Comments</Header>
                          <Header>Code</Header>
                      </HeaderRow>
                      {item?.map(({ id, reviewerComment, reference }, idx) => {
                          counterRef.current = idx === 0 ? counterRef.current + 2 : counterRef.current + 1;
                          const cnt = Number(counterRef.current);
                          return (
                        <HeaderRow key={idx} ref={el => (tablesRef.current[cnt] = el)}>
                              <Cell >{idx + 1}</Cell>
                              <Cell >{reviewerComment}</Cell>
                              <Cell >{reference}</Cell>
                        </HeaderRow>
                          )
                        })}
                  </CommentsTable>
                </>
              );
            }
            return null;
          })}
      </PrintedArea>      
    </Wrapper>
  );
});

const CommentsTable = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderRow = styled.div`
  display: grid;
  grid-template-columns: 58px 1fr 200px;
  border: 1px solid grey;
  border-right: none;
`;

const Header = styled.div`
  font-weight: bold;
  border-right: 1px solid grey;
`;

const Cell = styled.div`
border-right: 1px solid grey;
`;

const Wrapper = styled.div`

    padding-bottom: 2cm;

`;

const PageBreak = styled.td`
  page-break-after: always;
`;

const Dummy = styled.div`
  position: absolute;
  background-color: #ddd;
  z-index: 3;
`;
const List = styled.ul`
  list-style: none;
  padding: 0;
  li {
    padding: 8px 0;
    font-size: 18px;
    color: var(--primary);
  }
`;

const PrintedArea = styled.div`
 padding-left: 40px;
 padding-right: 40px;
`;

const HeadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const PrintButton = styled.div`
  position: absolute;
  border: 1px solid var(--secondaryText);
  border-radius: 8px;
  top: 28px;
  left: 22px;
  padding: 5px 35px;
  font-size: 17px;
  cursor: pointer;
  &:hover {
    border: 2px solid var(--secondaryText);
  }
`;

const Bold = styled.span`
  color: black;
  font-weight: bold;
`;

const Title = styled.h1`
  margin: 0;
  text-align: center;
`;

const InfoText = styled.div`
  margin: 0 auto;
  font-size: 24px;
  color: var(--mainText);
`;

const TableWrapper = styled.table`
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
`;
const StyledTD = styled.td`
  border: 1px solid #030303;
  text-align: left;
  padding: 8px;
  width: 33.33%;
`;
const StyledTH = styled.th`
  border: 1px solid #030303;
  text-align: left;
  padding: 8px;
`;

interface Props {
  jurisdiction: object;
  setIsValidForExport?: (boolean) => void;
  setShowReviewCommentLtr: any;
}

export default ReviewCommentLetter;
