import React, { useEffect, useState } from 'react';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import TableDashboard, { DashboardTableHeaders } from '../../../Common/TableDashboard/TableDashboard';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../redux/root';
import { setSelectedPermit } from '../../../../../../redux/slices/inspectionsSlice';
import { setShowDrawerContent } from '../../../../../../redux/slices/generalSlice';
import { useHistory } from 'react-router';
import { ROUTES } from '../../../Routes/ClientRoutes';
import useGetCleanRecrods from './useGetCleanRecrods';
import { useGetAllPermitInspectionsQuery } from '../../../../../../redux/rtkQuery/apiSlice';
import { PermitInspectionsType } from '../../../../types';

const InspectionTable = () => {
  const _records = useSelector((s:RootState) => s.inspections.permitInspectionsList);
  const records = useGetCleanRecrods(_records);
  const [permit, setPermit] = useState<number>(-1);
  const {data } = useGetAllPermitInspectionsQuery(permit,{skip: permit === -1})
  
  const dispatch = useDispatch();

  useEffect(() => {
    if(data){    
      const selected = records.find(r => r.permitId === permit); 
      const _selected = {
        ...selected,
        inspections: data,
      }
      
      dispatch(setSelectedPermit(_selected));
      history.push(`${ROUTES.INSPECTIONS}?openDrawer=true`);
    }
  }, [data])
  const history = useHistory();
  const onClick = (id: number) => {
    setPermit(id);
  }
  return (
    <TableDashboard
      headers={DashboardTableHeaders('Request')}
      records={records}      
      iconTitle="Request Inspection"
      onClick={onClick}
     >
        <AssignmentIndIcon />
    </TableDashboard>
  );
};

export default InspectionTable;