import { api } from './api';
import { PaginatedList, PaginatedRequest } from '../types';
import { JurisdictionType } from './jurisdiction';
import { FranchiseType } from './franchise';
import { UserType } from './userAuth';
import { PermitType } from './permits';
import { getAccessToken } from '../auth';
import { DocumentConditionItem } from '../modules/clientModule/types';

export const getProjects = (params: PaginatedRequest) => {
  return api.request<ProjectsList>({
    method: 'GET',
    url: '/projects/',
    params,
  });
}

export const getContactProjects = (params: PaginatedRequest) => {
  return api.request<ProjectsList>({
    method: 'GET',
    url: `projects/project/associated_contacts/`,
    params
  });
}


export const getProjectByID = (id: number) =>
  api.request<ProjectType>({
    method: 'GET',
    url: `/projects/${id}/`,
  });

export const createProject = (data: ProjectType) =>
  api.request<ProjectType>({
    method: 'POST',
    url: '/projects/',
    data,
  });

export const getClientDocuments = (clientId: number) => {
  return api.request<DocumentConditionItem[]>({
    method: 'GET',
    url: `/client_projects/get-all-documents`,
    params: {
      clientId: clientId,
      offset:0,
      limit:100000000,
      is_active:true,
      is_deleted:false
    }
  });
}

export const markProjectCandidateAsProcessed = (data) =>
  api.request<{is_visible:boolean,project_status:string}>({
    method: 'PATCH',
    url: `/client_projects/${data.id}/`,
    data,
  });

export const getClientPendingProjects = (clientId: number) => {
  return api.request<ProjectsList>({
    method: 'GET',
    url: `/client_projects/`,
    params: {
      clientId: clientId,
      offset:0,
      limit:100000000,
      is_active:true,
      is_deleted:false
    }
  });
}

export const createClientProject = (data: ClientProjectType) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    if (key !== 'documentFile') {
      formData.append(key, data[key]);
    }
  });
  if (data.documentFile) {
    let index = 0;
    data.documentFile.forEach(d => {
      formData.append('attachments_files', data.documentFile[index]);
      index++;
    })
  }

  return api.post(
    `/client_projects/`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        'Content-Type': 'multipart/form-data',
      },
    },
  );
}

export const updateProject = (data: ProjectType) =>
  api.request<ProjectType>({
    method: 'PATCH',
    url: `/projects/${data.id}/`,
    data,
  });

export const deleteProject = (id: number) =>
  api.request<ProjectType>({
    method: 'DELETE',
    url: `/projects/${id}/`,
  });

type ProjectsList = PaginatedList<ProjectType>;

export interface ProjectType {
  id?: number;
  address: string;
  street: string;
  city: string;
  state: string;
  zipCode: string;
  name: string;
  jurisdiction: number | JurisdictionType;
  franchise: number | FranchiseType;
  manager: number | UserType;
  contacts: number | UserType[];
  contact: any;
  serviceType: any[];
  serviceTypes: any;
  work: any | number;
  building: any | number;
  permit: PermitType;
  permits: PermitType[];
  permitIssued: boolean;
}

export interface ClientProjectType {
  address: string;
  street: string;
  city: string;
  state: string;
  zip: string;
  applicationType: any;
  building: number;
  description: string;
  documentFile: any[];
  franchise: any;
  manager: number;
  projectName: string;
  serviceType: any;
  work: number;
}

export interface ProjectTableType {
  id: number;
  address: string;
  street: string;
  city: string;
  state: string;
  zipCode: string;
  name: string;
  jurisdiction: JurisdictionType | number;
  franchise: FranchiseType | number;
  manager: number | UserType;
  contacts: UserType[] | number;
  permit: PermitType;
}
