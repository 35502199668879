import React, { FC } from 'react';
import { DashboardTableRecord, PermitFeeItem, PermitInspectionsType } from '../../../types';
import styled from 'styled-components';
import { Logo_Gold, Logo_Sky } from '../../../../../constants/menu';
import { DailyInspectionType } from '../../../../../api/inspectionTypes';
import { showTableLoading } from '../../../utils';

const GridTemplate = '18vw 17vw 4vw';
export const DashboardTableHeaders = (lastHeader: string) => ['Project Name/Permit Name', 'Address', lastHeader];

type Props = {
  headers: string[];
  records: DashboardTableRecord[] | PermitFeeItem[] | PermitInspectionsType[] | DailyInspectionType[];
  iconTitle: string;
  onClick: Function;
};

const TableDashboard: FC<Props> = ({ headers, records, iconTitle, onClick, children }) => {
 
  return (
    <TabelWrapper>
      <HeaderRow>
        {headers.map((item: string, index: number) => (
          <HeaderCell isCentered={index === headers.length - 1} key={index}>
            {item}
          </HeaderCell>
        ))}
      </HeaderRow>
      <RecordsWrapper>
        {showTableLoading(records) && <LoadingWrapper>Loading ...</LoadingWrapper>}
        {!showTableLoading(records) && records.map((item, index) => {
          const { address, namePermitName, permitId } = item;
          return (
            <Row key={index} odd={index % 2 === 0}>
              <Cell>
                <CellLabel>Project Name/Permit Name</CellLabel>
                <CellText title={`${namePermitName}`}>{`${namePermitName}`}</CellText>
              </Cell>
              <Cell>
                <CellLabel>Address</CellLabel>
                <CellText title={address}>{address}</CellText>
              </Cell>
              <Cell title={iconTitle} onClick={e => onClick(permitId)}>
                <Iconwrapper>{children}</Iconwrapper>
              </Cell>
              <MobileIcon onClick={e => onClick(permitId)}>{children}</MobileIcon>
            </Row>
          );
        })}
      </RecordsWrapper>
    </TabelWrapper>
  );
};

export default TableDashboard;

const TabelWrapper = styled.div`
  margin: 25px;
  max-height: 315px;
  overflow: auto;
  @media only screen and (max-width: 840px) {
    max-height: 325px;
  }
  @media only screen and (max-width: 540px) {
    max-height: 250px;
  }
`;

const MobileIcon = styled.div`
  display: none;
  @media only screen and (max-width: 840px) {
    display: flex;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 5px;
    right: -8px;
    justify-content: center;
    align-items: center;
    zoom: 2;
  }
`;
const RecordsWrapper = styled.div`
  height: 23vh;
  overflow: auto;
  overflow-x: hidden;
`;

export const HeaderRow = styled.div`
  display: grid;
  grid-template-columns: ${GridTemplate};
  border-bottom: 1px solid #ccc;
  @media only screen and (max-width: 840px) {
    display: none;
  }
`;
const Row = styled.div<{ odd: boolean }>`
  position: relative;
  display: grid;
  grid-template-columns: ${GridTemplate};
  border-bottom: 1px solid #ccc;
  background-color: ${({ odd }) => (odd ? '#FCF9FC' : '#fff')};
  @media only screen and (max-width: 840px) {
    grid-template-columns: 1fr;
  }
`;

export const HeaderCell = styled.div<{
  isCentered: boolean;
}>`
  font-size: 16px;
  font-weight: bold;
  color: ${Logo_Sky};
  text-align: ${({ isCentered }) => (isCentered ? 'center' : 'left')};
`;

const Cell = styled.div`
  font-size: 0.8vw;
  height: 45px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  @media only screen and (max-width: 840px) {
    height: 60px;
    font-size: 17px;
  }
`;

const CellLabel = styled.div`
  display: none;
  font-weight: bold;
  font-size: 12px;
  @media only screen and (max-width: 840px) {
    display: block;
    margin-top: 17px;
  }
`;

const CellText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 16vw;

  @media only screen and (max-width: 540px) {
    width: 235px;
  }
`;

const Iconwrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${Logo_Gold};
  @media only screen and (max-width: 840px) {
    display: none;
  }
`;

const LoadingWrapper = styled.div`
  font-size: 30px;
  margin-left: 45px;
  margin-top: 19px;
`;
