import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { InspectionItemType, PermitInspectionsType } from '../../modules/clientModule/types';
import { InspectionsType, InspectionsTypeList } from '../../api/inspections';
import { CommentType, DailyInspectionType } from '../../api/inspectionTypes';

export type popupType = 'confirmation' | 'saving' | 'contacts' | 'none';
export interface InspectionsState {
  inspections: any[];
  permitInspections: any[];
  searchInspections: any[];
  permitInspectionsList: PermitInspectionsType[] | DailyInspectionType[];
  selectedPermit: PermitInspectionsType;
  selectedInspection: InspectionItemType;
  apiMessage?: string;
  isApiError: boolean;
  showSuccessAndPrint: boolean;
  modalOpen: boolean;
  titlesData: any[];
  inspectionsTypeData: InspectionsType[];
  project: any;
  statusData: any;
  trades: any[];
  permitUsers: any[];
  comments: CommentType[];
  isSystemMessages: boolean;
  newComment: string;
  pdfReport: any;
  formData: InspectionItemType;
  showPopup: popupType;
  disabledGlobalSearch: boolean;
  showAllPendingInspections: boolean;
  inspectorName: string;
  showMergingForms: boolean;
  mergingFormsArray: InspectionItemType[];
  showDeleteMessage: boolean;
  deleteError: boolean,
}

const initialState: InspectionsState = {
  inspections: [],
  inspectorName: '',
  permitInspections: [],
  searchInspections: [],
  permitInspectionsList: [{} as PermitInspectionsType | {} as DailyInspectionType],
  selectedPermit: {} as PermitInspectionsType,
  selectedInspection: {} as InspectionItemType,
  apiMessage: undefined,
  isApiError: false,
  showSuccessAndPrint: false,
  modalOpen: false,
  titlesData: [],
  inspectionsTypeData: [],
  project: {},
  statusData: [],
  trades: [],
  permitUsers: [],
  comments: [],
  isSystemMessages: false,
  newComment: '',
  pdfReport: '',
  formData: {} as InspectionItemType,
  showPopup: 'none',
  disabledGlobalSearch: false,
  showAllPendingInspections: false,
  showMergingForms: false,
  mergingFormsArray: [],
  showDeleteMessage: false,
  deleteError: false,
};

export const inspectionsSlice = createSlice({
  name: 'inspections',
  initialState,
  reducers: {
    setInspections: (state, action: PayloadAction<any[]>) => {
      state.inspections = action.payload;
    },
    setPermitInspections: (state, action: PayloadAction<any[]>) => {
      state.permitInspections = action.payload;
    },
    setSearchInspections: (state, action: PayloadAction<any[]>) => {
      state.searchInspections = action.payload;
    },
    setPermitInspectionsList: (state, action: PayloadAction<PermitInspectionsType[]>) => {
      state.permitInspectionsList = action.payload;
    },
    setSelectedPermit: (state, action: PayloadAction<PermitInspectionsType>) => {
      state.selectedPermit = action.payload;
    },
    setSelectedInspection: (state, action: PayloadAction<InspectionItemType>) => {
      state.selectedInspection = action.payload;
    },
    setInspectionApiResults: (state, action: PayloadAction<{ apiMessage: string; isApiError: boolean }>) => {
      const { apiMessage, isApiError } = action.payload;
      state.apiMessage = apiMessage;
      state.isApiError = isApiError;
    },
    setShowSuccessAndPrint: (state, action: PayloadAction<boolean>) => {
      state.showSuccessAndPrint = action.payload;
    },
    setModalOpen: (state, action: PayloadAction<boolean>) => {
      state.modalOpen = action.payload;
    },
    setTitlesData: (state, action: PayloadAction<any[]>) => {
      state.titlesData = action.payload;
    },
    setInspectionsTypeData: (state, action: PayloadAction<InspectionsType[]>) => {
      state.inspectionsTypeData = action.payload;
    },
    setProject: (state, action: PayloadAction<any>) => {
      state.project = action.payload;
    },
    setStatusData: (state, action: PayloadAction<any>) => {
      state.statusData = action.payload;
    },
    setTrades: (state, action: PayloadAction<any>) => {
      state.trades = action.payload;
    },
    setPermitUsers: (state, action: PayloadAction<any>) => {
      state.permitUsers = action.payload;
    },
    setComments: (state, action: PayloadAction<CommentType[]>) => {
      state.comments = action.payload;
    },
    setIsSystemMessages: (state, action: PayloadAction<boolean>) => {
      state.isSystemMessages = action.payload;
    },
    setNewComment: (state, action: PayloadAction<string>) => {
      state.newComment = action.payload;
    },
    setPdfReport: (state, action: PayloadAction<any>) => {
      state.pdfReport = action.payload;
    },
    setFormData: (state, action: PayloadAction<InspectionItemType>) => {
      state.formData = action.payload;
    },
    setShowPopup: (state, action: PayloadAction<popupType>) => {
      state.showPopup = action.payload;
    },
    setDisabledGlobalSearch: (state, action: PayloadAction<boolean>) => {
      state.disabledGlobalSearch = action.payload;
    },
    setShowAllPendingInspections: (state, action: PayloadAction<boolean>) => {
      state.showAllPendingInspections = action.payload;
    },
    setInspectorName: (state, action: PayloadAction<string>) => {
      state.inspectorName = action.payload;
    },
    setShowMergingForms: (state, action: PayloadAction<boolean>) => {
      state.showMergingForms = action.payload;
    },
    setMergingFormsArray: (state, action: PayloadAction<InspectionItemType[]>) => {
      state.mergingFormsArray = action.payload;
    },
    setShowDeleteMessage: (state, action: PayloadAction<boolean>) => {
      state.showDeleteMessage = action.payload;
    },
    setDeleteError: (state, action: PayloadAction<boolean>) => {
      state.deleteError = action.payload;
    },
    resetInspections: state => {
      state.inspections = [];
      state.permitInspections = [];
      state.searchInspections = [];
      state.permitInspectionsList = [];
      state.selectedPermit = {} as PermitInspectionsType;
      state.selectedInspection = {} as InspectionItemType;
      state.apiMessage = undefined;
      state.isApiError = false;
      state.titlesData = [];
      state.inspectionsTypeData = [];
      state.project = {};
      state.statusData = [];
      state.trades = [];
      state.permitUsers = [];
      state.comments = [];
      state.isSystemMessages = false;
      state.pdfReport = '';
      state.formData = {} as InspectionItemType;
      state.showPopup = 'none';
      state.disabledGlobalSearch =false;
      state.showAllPendingInspections = false;
      state.inspectorName = '';
      state.showMergingForms = false;
      state.mergingFormsArray = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setInspections,
  setPermitInspections,
  setSearchInspections,
  setPermitInspectionsList,
  setSelectedPermit,
  setSelectedInspection,
  resetInspections,
  setInspectionApiResults,
  setShowSuccessAndPrint,
  setModalOpen,
  setTitlesData,
  setInspectionsTypeData,
  setProject,
  setStatusData,
  setTrades,
  setPermitUsers,
  setComments,
  setIsSystemMessages,
  setNewComment,
  setPdfReport,
  setFormData,
  setShowPopup,
  setDisabledGlobalSearch,
  setShowAllPendingInspections,
  setInspectorName,
  setShowMergingForms,
  setMergingFormsArray,
  setShowDeleteMessage,
  setDeleteError,
} = inspectionsSlice.actions;

export default inspectionsSlice.reducer;
