import React, { useEffect, useState } from 'react';
import ClientContainer from '../../ClientContainer';
import GridArea from './GridArea';
import NewProject from './NewProject/NewProject';
import WrapperWithRightDrawer from '../../Common/Containers/WrapperWithRightDrawer/WrapperWithRightDrawer';
import Drawer from '../../Common/Containers/WrapperWithRightDrawer/Drawer';
import BannerStrip from '../../Common/Containers/BannerStrip';
import ProjectCreatedModal from './NewProject/ProjectCreatedModal';
import { useLocation } from 'react-router';
import { setShowDrawerContent, setShowTutorial } from '../../../../../redux/slices/generalSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/root';
import styled from 'styled-components';
import TutorialPopup from './Tutorial/TutorialPopup';

const Dashboard = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const showTutorial = useSelector((s: RootState) => s.general.showTutorial);
  const dispatch = useDispatch();
  const info = useSelector((s: RootState) => s.userAuth.info);
  const search = useLocation().search;
  const params = new URLSearchParams(search);
  const NEW_PROJECT = 'newProject';
  const newProject = params.get(NEW_PROJECT);
  useEffect(() => {
    return () => {
      dispatch(setShowTutorial(false));
    };
  }, []);
  useEffect(() => {
    if(info && !info.loginBefore){
      dispatch(setShowTutorial(true));
    }
  }, [info])
  useEffect(() => {
    if (newProject) {
      const updatedUrl = window.location.protocol + '//' + window.location.host + window.location.pathname;
      window.history.pushState({ path: updatedUrl }, '', updatedUrl);
      dispatch(setShowDrawerContent(true));
    }
  }, [newProject]);
  return (
    <Wrapper>
      <ClientContainer topCaption={`Welcome to Elite Permits, ${info.firstName}!`} bottomCaption="Explore your personal dashboard">
        <BannerStrip label="DASHBOARD" />
        {showTutorial && (
          <>
            <Backgroung />
            <TutorialPopup />
          </>
        )}
        <WrapperWithRightDrawer>
          <GridArea />
          <Drawer>
            <NewProject setShowModal={setShowModal} />
          </Drawer>
        </WrapperWithRightDrawer>
      </ClientContainer>
      <ProjectCreatedModal showModal={showModal} setShowModal={setShowModal} />
    </Wrapper>
  );
};

export default Dashboard;

const Wrapper = styled.div`
  position: relative;
`;

const Backgroung = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(100, 100, 100, 0.8);
`;
