import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch, useSelector } from 'react-redux';
import { setShowPartialPaymentPopup } from '../redux/slices/feesSlice';
import { RootState } from '../redux/root';
import { currencyFormat } from '../pages/Permits/FeesPage';
import { ButtonMain } from '../atoms';
import { useParams } from 'react-router';
import { getPermitsFeesStatistics, postPartialPayment } from '../api/fees';
import { setPulse } from '../redux/slices/generalSlice';

const SubPermitMenuPartialPayment = () => {
  const dispatch = useDispatch();
  const permitFeesToManualyPay = useSelector((s: RootState) => s.fees.permitFeesToManualyPay);
  const pulse = useSelector((s: RootState) => s.general.pulse);
  const [balance, setBalance] = useState<number>(0);
  const [orig, setOrig] = useState<number>(0);
  const [amount, setAmount] = useState<string>('');
  const [memo, setMemo] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  //@ts-ignore
  const { permitId } = useParams();

  useEffect(() => {
    const load = async () => {
      const result = await getPermitsFeesStatistics({
        permit: permitId,
      });

      if (result && result.data) {
        //@ts-ignore
        const total = result.data.outstanding;
        setBalance(total);
        setOrig(total);
      }
    };
    if (permitId) {
      load();
    }
  }, [permitId]);

  const onChange = e => {
    const val = e.target.value;
    let _error = false;
    if (!isNaN(val)) {
      setAmount(val);
      const bal = orig - Number(val);
      setBalance(bal);
    }
    if (Number(val) > orig) {
      _error = true;
      setBalance(orig);
    }

    if (val === '') {
      setBalance(orig);
    }
    setError(_error);
  };

  const isPayDisabled = () => {
    if (!amount || !memo) {
      return true;
    }

    if (Number(amount) > orig) {
      return true;
    }
    return false;
  };

  const onPay = async () => {
    const invoiceIds = permitFeesToManualyPay
      .filter(p => p.invoiceNumber.startsWith('INV'))
      .reduce((ids, fee) => {
        if (ids.indexOf(fee.invoiceId) === -1) {
          ids.push(fee.invoiceId);
        }
        return ids;
      }, []);
    const payload = {
      payment_amount: amount,
      memo: memo,
      invoices: invoiceIds,
      permit: permitId,
    };
    await postPartialPayment(payload);
    dispatch(setPulse(pulse + 1));
    onClose();
  };

  const onClose = () => {
    dispatch(setShowPartialPaymentPopup(false));
  };
  return (
    <Wrapper>
      <PopupArea>
        <IconWrapper onClick={onClose}>
          <CloseIcon />
        </IconWrapper>
        <Header>Partial Payment</Header>
        <Balance>Outstanding Amount: {currencyFormat.format(balance)}</Balance>
        <Payments>
          <Entries>
            <InputEntry>
              <Line>Payment Amount:</Line>
              <Input>
                <input type="text" value={amount} name="amount" onChange={onChange} />
              </Input>
            </InputEntry>
            <InputEntry>
              <Line>Payment Memo:</Line>
              <Input wide={true}>
                <input type="text" value={memo} name="memo" onChange={e => setMemo(e.target.value)} />
              </Input>
            </InputEntry>
          </Entries>

          <ButtonMain disabled={isPayDisabled()} onClick={onPay}>
            Pay
          </ButtonMain>
        </Payments>
        {error && <Error>Amount can not be higher than the outstanding balance</Error>}
      </PopupArea>
    </Wrapper>
  );
};

export default SubPermitMenuPartialPayment;

const InputEntry = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 18px;
  margin-bottom: 16px;
  padding-left: 49px;
`;

const Entries = styled.div`
  display: flex;
  flex-direction: column;
`;

const Payments = styled.div`
  font-size: 18px;
  display: flex;
  padding-top: 24px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  button {
    width: 100px;
  }
`;

const Error = styled.div`
  color: red;
  font-size: 14px;
  width: 100%;
  text-align: center;
`;

const Line = styled.div``;
const Input = styled.div<{ wide?: boolean }>`
  input {
    width: ${({ wide }) => (wide ? '135px' : '68px')};
  }
`;

const Wrapper = styled.div`
  position: relative;
`;

const Balance = styled.div`
  font-size: 18px;
  width: 100%;
  text-align: center;
  margin-top: 20px;
`;

const IconWrapper = styled.div`
  top: 10px;
  right: -10px;
  position: relative;
  svg {
    border-radius: 50%;
    border: 2px solid black;
    padding: 2px;
    cursor: pointer;
    position: absolute;
    right: 20px;
  }
`;
const PopupArea = styled.div`
  position: absolute;
  top: 52px;
  right: -15px;
  width: 415px;
  height: 264px;
  background-color: #fff;
  z-index: 3;
  border: 1px solid grey;
  border-radius: 8px;
  box-shadow: 7px 7px #aaa;
`;

const Header = styled.div`
  width: 100%;
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  margin-top: 16px;
`;
