import * as React from 'react';
import { useDispatch } from 'react-redux';
import { clearRequestError } from '../redux/ducks/userAuth';
import { registerAccount } from '../api/userAuth';
import { COMPANY_NAME, APPLICATION_NAME } from '../constants';
import AuthLayout from '../layouts/AuthLayout';
import { apiSlice } from '../redux/rtkQuery/apiSlice';
import styled from 'styled-components';
import { InputBaseContainer, InputTextWithLabel, PasswordInput } from '../molecules';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { ButtonMain } from '../atoms';
import { ROUTES } from '../constants/routes';
import { useHistory } from 'react-router';
import { emailRegex, schema } from './RegisterPageSchema';
import { yupResolver } from '@hookform/resolvers';
import { checkAvailableEmail } from '../api/userSettings';
import ReactInputMask from 'react-input-mask';

const RegisterPage = () => {
  const dispatch = useDispatch();
  const [password, setPassword] = useState<string>('');
  const [repassword, setRePassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const [success, setSuccess] = useState<string>('');
  const [num1, setNum1] = useState<number>(0);
  const [num2, setNum2] = useState<number>(0);
  const [regDisabled, setRegDisabled] = useState<boolean>(true);
  const [typedResult, setTypedResult] = useState<string>('');
  const history = useHistory();
  const { handleSubmit, setError, getValues, errors, clearErrors, register, control, formState, watch, setValue } = useForm({
    resolver: yupResolver(schema),
    // defaultValues: {
    //   firstName: 'John',
    //   lastName: 'Doe',
    //   phone: '222-222-2222',
    //   address: '123 Main st. Naples FL 22222',
    //   email: 'a@b.com',
    // },

  });

  useEffect(() => {
    const num = Number(typedResult);
    if (!isNaN(num) && num !== 0) {
      setRegDisabled(num !== num1 + num2);
    } else if( num1 === 0 && num2 === 0 && typedResult === '0') {
      setRegDisabled(false);
    } else {
      setRegDisabled(true);
    }
  }, [typedResult]);

  const onResultChange = e => {
    const x = typedResult;
    if (/^\d+$/.test(e.target.value) || !e.target.value) {
      setTypedResult(e.target.value);
    }
  };
  React.useEffect(() => {
    dispatch(clearRequestError());
    dispatch(apiSlice.util.resetApiState());
    const _n1 = Math.floor(Math.random() * 10);
    const _n2 = Math.floor(Math.random() * 10);
    setNum1(_n1);
    setNum2(_n2);
  }, []);

  const onSubmit = data => {
    if (!password) {
      setPasswordError('Password is required.');
      return;
    }
    if (password.length < 8) {
      setPasswordError('Password must be at least 8 characters.');
      return;
    }
    if (password !== repassword) {
      setPasswordError('Password confirmation mismatch.');
      return;
    }

    setPasswordError('');
    const payload = {
      ...data,
      password,
    };

    const sendData = async () => {
      try {
        const result = await registerAccount(payload);
        setSuccess(result?.data?.message);
      } catch (error) {
        console.log(error);
      }
    };
    sendData();
  };

  const validateEmail = async () => {
    const email = getValues('email');
    if (!emailRegex.test(email)) {
      setError('email', { message: 'Invalid email format' });
      return;
    }
    clearErrors('email');

    const result = await checkAvailableEmail(email);
    if (result.email_exists) {
      setError('email', { message: 'Email is not available' });
    }
  };

  const goBack = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(ROUTES.LOG_IN);
  };
  return (
    <AuthLayout mainText={APPLICATION_NAME} companyText={COMPANY_NAME} secondaryText="Regiter a new account.">
      <FormContainer autoComplete="off" aria-autocomplete="none" onSubmit={handleSubmit(onSubmit)}>
        {!success && (
          <>
            <InputBaseContainer>
              <InputTextWithLabel name="firstName" error={errors.firstName} inputRef={register} label="First Name" />
            </InputBaseContainer>
            <InputBaseContainer>
              <InputTextWithLabel name="lastName" error={errors.lastName} inputRef={register} label="Last Name" />
            </InputBaseContainer>
            <InputBaseContainer>
              <ReactInputMask mask="999-999-9999" maskChar=" " name="phone">
                {() => <InputTextWithLabel error={errors.phone} name="phone" inputRef={register} label="Phone Number" />}
              </ReactInputMask>
            </InputBaseContainer>
            <InputBaseContainer>
              <InputTextWithLabel name="address" error={errors.address} inputRef={register} label="Address" />
            </InputBaseContainer>
            <InputBaseContainer>
              <InputTextWithLabel name="email" error={errors.email} inputRef={register} onBlur={validateEmail} label="Email" />
            </InputBaseContainer>
            <InputBaseContainer>
              <PasswordContainer>
                <Label>Password</Label>
                <PasswordInput
                  id="password"
                  label=""
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  isValid={!passwordError}
                  helperText={passwordError}
                />
              </PasswordContainer>
            </InputBaseContainer>
            <InputBaseContainer>
              <PasswordContainer>
                <Label>Confirm Password</Label>
                <PasswordInput id="password_test" label="" onChange={e => setRePassword(e.target.value)} value={repassword} />
              </PasswordContainer>
            </InputBaseContainer>
            <SpanProtection>
              {`Spam Protection Question. What is the result of ${num1} + ${num2}?`}
              <input name="result" value={typedResult} onChange={onResultChange} />
            </SpanProtection>
          </>
        )}
        {!success && (
          <Buttons>
            <ButtonMain onClick={goBack}>Back</ButtonMain>
            <RegisterButton>
              <ButtonMain type="submit" disabled={regDisabled}>
                Register
              </ButtonMain>
            </RegisterButton>
          </Buttons>
        )}

        {success && (
          <SuccessMessage>
            <div>{`${success}`}</div>
            <Goto>
              Go to <LoginLink onClick={() => history.push(ROUTES.LOG_IN)}>Login Page</LoginLink> to continue
            </Goto>
          </SuccessMessage>
        )}
      </FormContainer>
    </AuthLayout>
  );
};

export default RegisterPage;

const SuccessMessage = styled.div`
  margin-top: 38px;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  color: green;
`;

const Goto = styled.div`
  display: flex;
  gap: 11px;
  color: var(--secondaryText);
  font-size: 15px;
  margin-top: 5px;
`;
const LoginLink = styled.div`
  text-decoration: underline;
  font-style: italic;
  cursor: pointer;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  gap: 50px;
  margin-top: 30px;
  margin-bottom: 7px;
  width: 100%;
`;

const RegisterButton = styled.div`
  width: 115px;
`;

const FormContainer = styled.form`
  width: 100%;
  @media only screen and (max-width: 840px) {
    width: 75vw !important;
  }
  .MuiButton-root {
    margin-top: 24px;
  }
  .MuiChip-outlined {
    margin-right: 5px;
    margin-top: 5px;
  }
`;

const PasswordContainer = styled.div`
  display: flex;
  flex-direction: column;
  .MuiOutlinedInput-root {
    height: 38px;
  }
`;

const Label = styled.div`
  font-size: 17px;
  margin-bottom: 6px;
`;

const SpanProtection = styled.div`
  display: flex;
  font-size: 0.7rem;
  justify-content: space-between;
  margin-top: 10px;
`;
