import * as React from 'react';
import styled from 'styled-components';
import {
  Controller,
  useFormContext,
} from 'react-hook-form';
import isYupFieldRequired from '../utils/isYupFieldRequired';
import {
  InputTextWithLabel,
  InputBaseContainer,
  TextAreaWithLabel,
  InputSelectWithLabel,
  InputDatePickerWithLabel,
  // InputCurrencyWithLabel,
} from '../molecules';
import { getUsersCustom, UserType } from '../api/users';
import { useSelector } from 'react-redux';
import { ContactTypesType } from '../api/contactType';
import { DEFAULT_DATE_FORMAT, GET_ALL_QUERY_PARAMS } from '../constants';
import { USER_TYPES_VALUES } from '../constants/forms';
import { ButtonMain } from '../atoms';
import { getVocabulariesDataList } from '../api/vocabulary';
import ContactsTable from '../organisms/ContactsTable';
import { fontFamily } from '../theme';
import { stepFourLabels } from './ProjectFormContainer/ProjectFormLabels';
import { RootState } from '../redux/root';
import { useEffect } from 'react';

const ProjectFormStepFour = ({
  currentValidationSchema,
  setIsSecondModalOpen,
  setIsSomeStepLoading,
  isContactFormSubmitting,
  setIsContactFormSubmitting,
  setContactModalOpen,
  selectedContacts,
  setSelectedContacts,
  contactTypes,
}: Props) => {
  const {
    control,
    errors,
    clearErrors,
    setValue,
    getValues,
    setError,
  } = useFormContext();
  
  const [
    contractorData,
    setContractorData,
  ] = React.useState([]);
  const [buildingData, setBuildingData] = React.useState(
    [],
  );
  const projectCandidate = useSelector((s:RootState) => s.projectsSlice.projectCandidate);
  const [
    serviceTypeData,
    setServiceTypeData,
  ] = React.useState([]);
  const [
    workScopeTypeData,
    setWorkScopesTypeData,
  ] = React.useState([]);
  const [managersData, setManagersData] = React.useState(
    [],
  );

  const labels=stepFourLabels;
  const [isLoading, setIsLoading] = React.useState(false);

  const projectManager = getValues('manager');
  const permitManager = getValues('permitManager');

  React.useEffect(() => {
    if (projectManager && !permitManager) {
      setValue('permitManager', projectManager);
    }
  }, [projectManager]);

  useEffect(() => {
    if(projectCandidate && 
      workScopeTypeData.length > 0 && 
      buildingData.length > 0 &&
      serviceTypeData.length > 0){
      const workId = projectCandidate.work;
      const wrkObj = workScopeTypeData.find(w => w.id === workId);
      setValue('workScope',wrkObj);

      const buildingId = projectCandidate.building;
      const buildingObj = buildingData.find(b => b.id === buildingId);
      setValue('building',buildingObj);

      let svcType = getValues('serviceType');
      if(!svcType){
          svcType = serviceTypeData.filter(d => projectCandidate.serviceTypes.indexOf(d.id) > -1);
          setValue('serviceType',svcType)
      }
     
      if(projectCandidate){
        setSelectedContacts([projectCandidate.contact]);
      }
    }
  },[projectCandidate,workScopeTypeData,buildingData, serviceTypeData])
  React.useEffect(() => {
    setIsLoading(true);
    setIsSomeStepLoading(true);

    Promise.all([
      getVocabulariesDataList({
        ...GET_ALL_QUERY_PARAMS,
        ordering: 'title',
      }), // buildings types
      getUsersCustom({
        userRole: USER_TYPES_VALUES.EMPLOYEE,
        project: 'true'
      }), // employee
    ])
      .then(
        ([
          buildingsTypesResponse,
          projectManagersResponse,
        ]) => {
          setBuildingData(
            buildingsTypesResponse.data.results?.filter(
              el => (el?.isActive && el?.vocabularyEntity === 1),
            ),
          );
          setServiceTypeData(
            buildingsTypesResponse.data.results?.filter(
              el => (el?.isActive && el?.vocabularyEntity === 5),
            ),
          );
          setWorkScopesTypeData(
            buildingsTypesResponse.data.results?.filter(
              el => (el?.isActive && el?.vocabularyEntity === 2),
            ),
          );
          setManagersData(
            projectManagersResponse.data.filter(
              el => el?.isActive,
            ),
          );
        },
      )
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
        setIsSomeStepLoading(false);
        setIsContactFormSubmitting(false);
      });
  }, [isContactFormSubmitting]);

  React.useEffect(() => {
    if (
      selectedContacts &&
      contactTypes &&
      contactTypes.length
    ) {
      clearErrors('contacts');
    }
    setValue('contacts', selectedContacts);
  }, [selectedContacts]);

  // const onCurrencyChange = (value, name) => {
  //   setValue(name, value);
  // };

  // get contractors data on menu open event 
  const getContractors = () => {
    Promise.all([
      getUsersCustom({
        userRole: USER_TYPES_VALUES.CLIENT,
        project: 'true'
      })
    ]).then(([individualClientsResponse]) => {
      setContractorData([
        ...individualClientsResponse.data?.filter(
          el => el?.isActive && (el?.clientType === 1 || el?.clientType === 2),
        ),
      ]);
    })
  }

  return (
    <>
      <InputBaseContainer>
        <Controller
          as={InputTextWithLabel}
          name="permitName"
          control={control}
          helperText={errors.permitName?.message}
          error={errors.permitName?.message}
          label={labels.permitName}
          isRequired={isYupFieldRequired(
            'permitName',
            currentValidationSchema,
          )}
        />
      </InputBaseContainer>
      <InputBaseContainer>
        <Controller
          as={TextAreaWithLabel}
          name="description"
          control={control}
          error={errors.description?.message}
          label={labels.description}
          isRequired={isYupFieldRequired(
            'description',
            currentValidationSchema,
          )}
        />
      </InputBaseContainer>
      <InputBaseContainer>
        <Controller
          name="serviceType"
          defaultValue=""
          control={control}
          render={props => (
            <InputSelectWithLabel
              isMulti
              selectAllActive
              placeholder=""
              isLoading={isLoading}
              isDisabled={isLoading}
              label={labels.serviceType}
              helperText={errors.serviceType?.message}
              error={errors.serviceType?.message}
              getOptionValue={(item: any) =>
                item.id || Math.random()
              }
              getOptionLabel={(item: any) =>
                item.name || item.title
              }
              isRequired={isYupFieldRequired(
                'serviceType',
                currentValidationSchema,
              )}
              options={serviceTypeData}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
            />
          )}
        />
      </InputBaseContainer>
      <InputBaseContainer>
        <Controller
          name="workScope"
          defaultValue=""
          control={control}
          render={props => (
            <InputSelectWithLabel
              placeholder=""
              menuPlacement="bottom"
              label={labels.workScope}
              helperText={errors.workScope?.message}
              error={errors.workScope?.message}
              options={workScopeTypeData}
              getOptionValue={(item: any) =>
                item.id || Math.random()
              }
              getOptionLabel={(item: any) => item.title}
              isRequired={isYupFieldRequired(
                'workScope',
                currentValidationSchema,
              )}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
            />
          )}
        />
        <Controller
          name="building"
          defaultValue=""
          control={control}
          render={props => (
            <InputSelectWithLabel
              placeholder=""
              menuPlacement="bottom"
              label={labels.building}
              isLoading={isLoading}
              isDisabled={isLoading}
              helperText={errors.building?.message}
              error={errors.building?.message}
              options={buildingData}
              getOptionValue={(item: any) =>
                item.id || Math.random()
              }
              getOptionLabel={(item: any) => item.title}
              isRequired={isYupFieldRequired(
                'building',
                currentValidationSchema,
              )}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
            />
          )}
        />
      </InputBaseContainer>
      <StyledInputContainer>
        <HiddenInput>
          <Controller
            name="contacts"
            defaultValue=""
            control={control}
            render={props => (
              <InputSelectWithLabel
                placeholder=""
                menuPlacement="bottom"
                label=""
                isLoading={isLoading}
                isDisabled={isLoading}
                helperText={errors.contacts?.message}
                error={errors.contacts?.message}
                // @ts-ignore
                options={selectedContacts}
                getOptionValue={(item: any) =>
                  item?.id || Math.random()
                }
                getOptionLabel={(item: any) => item?.title}
                isRequired={isYupFieldRequired(
                  'contacts',
                  currentValidationSchema,
                )}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            )}
          />
        </HiddenInput>
        <ContactsWrapper>
          <ContactsLabel>
            Contacts <span style={{ color: 'red' }}>*</span>
          </ContactsLabel>
          <ContactsBlock>
            <ContactsTable
              contacts={selectedContacts}
              setContacts={setSelectedContacts}
              contactTypes={contactTypes}
              error={errors.contacts?.message}
            />
            <ButtonContainer>
              <StyledButtonMain
                variant="outlined"
                onClick={() => setContactModalOpen(true)}
              >
                Select Contacts
              </StyledButtonMain>
            </ButtonContainer>
            <ButtonContainer>
          <StyledButtonMain
            variant="outlined"
            onClick={() => setIsSecondModalOpen(true)}
          >
            Add new Contact
          </StyledButtonMain>
        </ButtonContainer>
          </ContactsBlock>
          {errors.contacts?.message && (
            <ErrorMessage>
              {errors.contacts?.message}
            </ErrorMessage>
          )}
        </ContactsWrapper>
      </StyledInputContainer>
      <InputBaseContainer>
        <Controller
          name="permitManager"
          defaultValue=""
          control={control}
          render={props => (
            <InputSelectWithLabel
              placeholder=""
              menuPlacement="bottom"
              label={labels.permitManager}
              isLoading={isLoading}
              isDisabled={isLoading}
              helperText={errors.permitManager?.message}
              error={errors.permitManager?.message}
              options={managersData}
              getOptionValue={(item: any) =>
                item.id || Math.random()
              }
              getOptionLabel={(item: any) =>
                `${item.firstName} ${item.lastName}`
              }
              isRequired={isYupFieldRequired(
                'permitManager',
                currentValidationSchema,
              )}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
            />
          )}
        />
      </InputBaseContainer>
      <InputBaseContainer>
        <Controller
          as={InputTextWithLabel}
          name="inspectionsNumber"
          control={control}
          helperText={errors.inspectionsNumber?.message}
          error={errors.inspectionsNumber?.message}
          label={labels.inspectionsNumber}
          isRequired={isYupFieldRequired(
            'inspectionsNumber',
            currentValidationSchema,
          )}
        />
      </InputBaseContainer>
      <InputBaseContainer>
              <Controller
                    name="expirationDate"
                    defaultValue=""
                    valueName="selected"
                    onChange={([selected]) => selected}
                    control={control}
                    menuPlacement="top"
                    render={props => (
                      <InputDatePickerWithLabel
                        label={labels.expirationDate}
                        error={errors.expirationDate}
                        dateFormat={DEFAULT_DATE_FORMAT}
                        isRequired={isYupFieldRequired(
                          'expirationDate',
                          currentValidationSchema,
                        )}
                        selected={props.value}
                        {...props}
                      />
                    )}
                  />
             </InputBaseContainer>
    </>
  );
};

const StyledInputContainer = styled.div`
  display: flex;
  align-items: flex-end;
  padding: 8px 0;
`;

const ButtonContainer = styled.div`
  width: min-content;
  margin-left: 20px;
`;

const StyledButtonMain = styled(ButtonMain)`
  &.MuiButton-root {
    line-height: 1.15;
  }
`;

const ContactsBlock = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
`;

const ContactsWrapper = styled.div`
  width: 100%;
`;

const ErrorMessage = styled.p`
  color: #f44336;
  font-size: 0.75em;
  margin: 3px 14px 0 14px;
`;

const HiddenInput = styled.div`
  width: 0;
  height: 0;
  opacity: 0;
  div {
    box-sizing: border-box;
    border: none;
  }
`;

const ContactsLabel = styled.p`
  font-family: ${fontFamily};
  font-style: normal;
  font-weight: normal;
  font-size: 1em;
  line-height: 21px;
  margin-top: 0;
`;

interface Props {
  currentValidationSchema: any;
  setIsSecondModalOpen: (data: boolean) => void;
  setIsContactModalOpen: (data: boolean) => void;
  isContactFormSubmitting: boolean;
  setIsSomeStepLoading: (data: boolean) => void;
  setIsContactFormSubmitting: (data: boolean) => void;
  setContactModalOpen: (data: boolean) => void;
  setSelectedContacts: (data: UserType[]) => void;
  selectedContacts: Array<UserType>;
  contactTypes: Array<ContactTypesType>;
}

interface FieldsType {
  permitName: string;
  description: string;
  contractor: string;
  building: string;
  serviceType: string;
  workScope: string;
  inspectionsNumber: string;
  totalContract: string;
  permitManager: string;
}

export default ProjectFormStepFour;
