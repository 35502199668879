import React, { FC, useEffect, useState } from 'react';
import { Card, ErrorLable, ServiceTypeLabel } from './MultiCheckbox';
import styled from 'styled-components';
import { ServiceTypeItemType, ServiceTypeItems } from '../../../../types';
import SwitchCheckbox from '../../../Common/Controls/SwitchCheckbox';
import { Required, RequiredWrapper } from './ProjectControlsArea';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../redux/root';

type Props = {
  setValue: Function;
  errors: unknown;
  trigger: Function;
  register: Function;
};

const ServiceType: FC<Props> = ({ setValue, errors, trigger, register }) => {
  const [itemsArr, setItemsArr] = useState<ServiceTypeItemType[]>(ServiceTypeItems);
  const [initLoad, setInitLoad] = useState<boolean>(true);
  const showDrawerContent = useSelector((s:RootState) => s.general.showDrawerContent);
  const name = 'serviceType';

  useEffect(() => {
    if(!showDrawerContent){
      const unchecked = itemsArr.map(i => ({...i, checked:false}));
      setItemsArr(unchecked);
    }
  }, [showDrawerContent])
  useEffect(() => {
    if (initLoad) {
      setInitLoad(false);
      return;
    }
    register(name);
    setValue(name, itemsArr);
    const performTrigger = async () => {
      await trigger(name);
    };

    performTrigger();
  }, [itemsArr]);
  const handleCheck = e => {
    const value = e.target.name;
    let changedItem;
    const _items = itemsArr.map(item => {
      if (item.title === value) {
        item.checked = e.target.checked;
        changedItem = {
          name: value,
          isChecked: e.target.checked,
        };
      }

      //unchecked all the disabled boxes
      if (item.parent && changedItem && item.parent === changedItem.name) {
        if (!changedItem.checked) {
          item.checked = false;
        }
      }
      return item;
    });

    setItemsArr(_items);
  };

  const checkIsDisabled = (item: ServiceTypeItemType): boolean => {
    if (item.parent) {
      const parent = itemsArr.find(i => i.title === item.parent);
      if (parent && !parent.checked) {
        return true;
      }
    }
    return false;
  };

  return (
    <Card error={errors[name]}>
      <ServiceTypeLabel error={errors[name]}>
        <RequiredWrapper>
          <Required left={102} top={3}>
            (Required)
          </Required>
          SERVICE TYPE
        </RequiredWrapper>
        <Hover>(hover for more info)</Hover>
      </ServiceTypeLabel>
      {errors[name] !== undefined && <ErrorLable>{errors[name].message}</ErrorLable>}
      <BoxesWrapper>
        {itemsArr.map((item, index) => (
          <CellContainer row={item.row} key={index} ident={item.parent !== undefined}>
            <SwitchCheckbox
              label={item.title}
              disabled={checkIsDisabled(item)}
              checked={item.checked}
              name={item.title}
              value={item.title}
              title={item.description}
              onChange={handleCheck}
            />
            <Description>{item.description}</Description>
          </CellContainer>
        ))}
      </BoxesWrapper>
    </Card>
  );
};

export default ServiceType;

const CellContainer = styled.div<{
  row: string;
  ident: boolean;
}>`
  grid-row: ${({ row }) => row};
  margin-left: ${({ ident }) => (ident ? '30px' : '0')};
`;

export const BoxesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 30px;
  @media only screen and (max-width: 540px) {
    display: flex;
    flex-direction: column;
  }
`;

const Hover = styled.div`
  font-size: 13px;
  @media only screen and (max-width: 840px) {
    display: none;
  }
`;

const HoverAfter = styled.div`
  &:hover:after {
    font-size: 60px !important;
  }
`;

const Description = styled.div`
  display: none;
  @media only screen and (max-width: 840px) {
    display: block;
    font-size: 15px;
    margin-right: 35px;
    margin-bottom: 28px;
  }
`;
