import { ProjectType } from '../../api/projects';
import { InspectionStatuses } from '../../constants';

export type ServiceTypes = 'Permitting' | 'Private Provider' | 'Reviews' | 'Inspection' | 'Consulting' | 'Other';
export type ServiceTypeItemType = {
  title: ServiceTypes;
  row: string;
  checked: boolean;
  parent?: ServiceTypes;
  description?: string;
};

export type CheckBoxItemStatus = {
  id: number,
  checked: boolean,
}
export const ServiceTypeItems: ServiceTypeItemType[] = [
  {
    title: 'Permitting',
    row: '1/2',
    checked: false,
    description:
      'Pre-fill permit forms, provide a list of required items, compile the permit application paperwork, and submit on the client’s behalf. Clients will need to sign and notarize select forms. Permit documents are not reviewed for code compliance as part of this process',
  },
  { title: 'Private Provider', row: '2/3', checked: false },
  {
    title: 'Reviews',
    row: '3/4',
    parent: 'Private Provider',
    checked: false,
    description:
      'Our licensed plan reviewers review the project plans for building code compliance (Building & MEP) prior to permit submission. This is done in lieu of the building department; however, the building department may choose to audit our reviews',
  },
  {
    title: 'Inspection',
    row: '4/5',
    parent: 'Private Provider',
    checked: false,
    description:
      'Our licensed inspectors complete the required building, mechanical, electrical, and plumbing inspections in lieu of the local building department. Next-day service with greater 2exibility, same day service is dependent on jurisdiction and workload',
  },
  {
    title: 'Consulting',
    row: '1/2',
    checked: false,
    description:
      'For complex projects or permits after the fact, we can provide services that will provide guidance about how to best process the permit and what the necessary steps will be needed for after the fact permits. These projects may require a worksite visit prior to drawing a proposal for work',
  },
  {
    title: 'Other',
    row: '2/3',
    checked: false,
    description: 'Do you need help with something not listed in our services? Please select “Other.”',
  },
];

export type ManagerType = {
  id: number;
  managerName: string;
};

export type FranchiseUser = {
  first_name: string;
  id: number;
  last_name: string;
  status: boolean;
  user_type: string;
};

export type DashboardTableRecord = {
  namePermitName: string;
  address: string;
  permitId: number;
  status: string;
  quantity: number;
};

export type TicketItem = {
  permitId: string;
  permitName: string;
  projectName: string;
  projectAddress: string;
  permitStatus: string;
  ticketQuantity: string;
};
export type PermitTicketItem = DashboardTableRecord & {
  tickets: any[];
};
export type FranchiseUsersType = {
  id: string;
  value: FranchiseUser[];
};

export type ProjectPermit = ProjectType & {
  permitName: string;
  permitId: number;
  permitDescription: string;
  status: string;
};

export type PermitInspectionsType = DashboardTableRecord & {
  inspections: InspectionItemType[];
  id?: number;
  lastComment?: any
};

export type InspectionItemType = {
  activityTitleName: string;
  activityTypeName: string;
  assignedTo: number;
  assignedToFirstName: string;
  assignedToLastName: string;
  cellPhone: string;
  companyName: string;
  completedAt: string | null;
  completedBy: string | null;
  contact: number;
  contactFirstName: number;
  contactId: number;
  contactLastName: string;
  createdAt: string;
  createdBy: number | null;
  followUp: string;
  id: number;
  information: string;
  inspectionDate: string;
  inspectionType: number;
  inspector: number | null;
  inspectorFirstName: string | null;
  inspectorLastName: string | null;
  isCompleted: boolean;
  isDeleted: boolean;
  isEmergency: boolean;
  lastComment: string | null;
  managerFirstName: string;
  managerLastName: string;
  period: number;
  permit: number;
  permitName: string;
  permitStatus: string;
  permitNumber: string;
  phoneNumber: string;
  projectAddress: string;
  projectId: string;
  projectJurisdiction: string;
  projectName: string;
  report: string | null;
  status: number;
  statusName: string;
  title: number;
  trades: number[];
  visibleFilename: string | null;
  attachments: any;
  activityStatus?: {id: number, name: string}
  createdById?: number,
};

export type DocumentConditionItem = DashboardTableRecord & {
  quantity: number;
  status: string;
};

export type DocumentFileItem = {
  statusTitle: string;
  adminFiles: any[];
  adminFileName: string;
  building: number;
  clientFile: string;
  clientFiles?: FileEntryType[]
  clientFileName: string;
  isCompleted: boolean;
  jurisdiction: number;
  lastComment: string;
  name: string;
  permit: number;
  pk: number;
  settingDoc: number;
  status: number;
  type: string;
  work: number;
};

export type PermitFeeItem = DashboardTableRecord & {
  fees: FeeItemType[];
};
export type FeeItemType = {
  projectName: string;
  permitName: string;
  projectAddress: string;
  amount: number;
  completedAt: string;
  completedBy: string;
  createdAt: string;
  createdBy: string;
  creator: number;
  dateInvoiced: string;
  datePaid: string;
  feeSettings: number;
  id: number;
  invoiceId: string;
  invoiceNumber: string;
  invoicePdf: string;
  isPaidManual: boolean;
  isVoid: boolean;
  lastComment: string;
  name: string;
  permitId: number;
  permitStatus: string;
  quantity: number;
  status: string;
  total: number;
  file?: string;
  fileName? : string;
};

export type TicketItemType = {
  completedAt: string;
  completedBy: string;
  contact: number;
  contactFirstName: string;
  contactLastName: string;
  createdAt: string;
  createdBy: string;
  creatorUsername: string;
  description: string;
  id: number;
  isDeleted: boolean;
  lastUpdated: string;
  permit: number;
  status: number;
  subject: string;
};

export type TicketComment = {
  createdAt: string;
  creatorUsername: string;
  id: number;
  text: string;
  ticket: number;
};

export type CommentItem = {
  createdAt: string;
  text: string;
  username: string;
};

export type PercentComplete = { percent: number; completedCount: number; allCount: number; rejectCount: number };

export type ConditionItemType = {
  name: string;
  status: number;
  id: number;
  attachments: any[];
  title: number;
};

export type ReviewCommentStatus = {
  label: 'Approved' | 'Rejected' | 'Not Applicable',
  value: number;
}
export type ReviewCommentType = {
  reviewerComment: string;
  reference: string;
  status: ReviewCommentStatus;
  id: number;
  isClientCommentVisible: boolean;
  clientComment?: string;
  pristine: boolean;
}

export const ReviewCommentStatuses: ReviewCommentStatus[] = [
  {
    label: InspectionStatuses.APPROVED,
    value: 1
  },
  {
    label: InspectionStatuses.REJECTED,
    value: 0
  },
  {
    label: InspectionStatuses.NOT_APPLICABLE,
    value: 2
  }
]

export type FileEntryType = {
  file: string;
  fileName: string;
  id: number;
  isVisible: boolean;
}

export type ProjectDocumentEntry = {
  adminFile: string;
  adminFileName: string;
  building: number;
  clientFiles: FileEntryType[];
  isClientAvailable: boolean;
  isCompleted: boolean;
  isLocked: boolean;
  jurisdiction: number;
  lastComment: string;
  name: string;
  permit: number;
  pk: number;
  settingDoc: number;
  status: number;
  work: number;
}

export type MarkPaidParams = {
  permit_id: string;
  fee_ids: string;
  invoice_number: string;
  send_to_email: string;
}

export type StripeResponse = {
  payment_intent_id: string;
  client_secret: string;
}

export type CustomParams = {
  is_deleted: boolean,
  limit: number,
  offset: number,
  permit_id: number,
  event_type:string,
  activity_type_name: string
}

export type DocumentStatusType = {
  documentsIds: string,
  status: string,
}