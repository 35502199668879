import * as React from 'react';
import {
  Controller,
  useFormContext,
} from 'react-hook-form';
import { checkValidStateName } from '../utils/parseGoogleApiAddress';
import isYupFieldRequired from '../utils/isYupFieldRequired';
import {
  InputAutocompletePlaces,
  InputTextWithLabel,
  InputBaseContainer,
} from '../molecules';
import { stepOneLabels } from './ProjectFormContainer/ProjectFormLabels';

const ProjectFormStepOne = ({
  currentValidationSchema,
}: Props) => {
  const { control, errors, setValue } = useFormContext();
  const labels = stepOneLabels;
  const handlePlaceSelect = (address, isSelect) => {
    if (isSelect) {
      return Object.entries(address).map(([key, value]) => {
        return setValue(key, value);
      });
    }
    return setValue('address', address);
  };
  return (
    <>
      <InputBaseContainer>
        <Controller
          name="address"
          as={InputAutocompletePlaces}
          defaultValue=""
          control={control}
          helperText={errors.address?.message}
          error={errors.address?.message}
          label={labels.address}
          handleFieldChange={handlePlaceSelect}
          isRequired={isYupFieldRequired(
            'address',
            currentValidationSchema,
          )}
        />
      </InputBaseContainer>
      <InputBaseContainer>
        <Controller
          as={InputTextWithLabel}
          name="street"
          control={control}
          helperText={errors.street?.message}
          error={errors.street?.message}
          label={labels.street}
          isRequired={isYupFieldRequired(
            'street',
            currentValidationSchema,
          )}
        />
        <Controller
          as={InputTextWithLabel}
          name="city"
          control={control}
          helperText={errors.city?.message}
          error={errors.city?.message}
          label={labels.city}
          isRequired={isYupFieldRequired(
            'city',
            currentValidationSchema,
          )}
        />
      </InputBaseContainer>
      <InputBaseContainer>
        <Controller
          name="state"
          defaultValue=""
          control={control}
          render={props => (
            <InputTextWithLabel
              name="state"
              error={errors.state?.message}
              helperText={errors.state?.message}
              label={labels.state}
              isRequired={isYupFieldRequired(
                'state',
                currentValidationSchema,
              )}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              // eslint-disable-next-line react/prop-types
              value={checkValidStateName(props.value)}
            />
          )}
        />
        <Controller
          as={InputTextWithLabel}
          name="zipCode"
          control={control}
          helperText={errors.zipCode?.message}
          error={errors.zipCode?.message}
          label={labels.zipCode}
          isRequired={isYupFieldRequired(
            'zipCode',
            currentValidationSchema,
          )}
        />
      </InputBaseContainer>
    </>
  );
};

interface Props {
  currentValidationSchema: any;
}

interface FieldsType {
  address: string;
  city: string;
  street: string;
  state: string;
  zipCode: string;
}

export default ProjectFormStepOne;
