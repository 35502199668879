import React from 'react';
import styled from 'styled-components';
import Toturial from './Toturial';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch, useSelector } from 'react-redux';
import { setShowTutorial } from '../../../../../../redux/slices/generalSlice';
import userSettings from '../../../../../../api/userSettings';
import { RootState } from '../../../../../../redux/root';

const TutorialPopup = () => {
  const dispatch = useDispatch();
  const info = useSelector((s:RootState) => s.userAuth.info);
  const onClose = async () => {
    const userId = info.id;
    const data:any = { loginBefore: true};
    await userSettings.sendNewUserData(userId,data);
    dispatch(setShowTutorial(false))
  }
  return (
    <Wrapper>
      <IconWrapper onClick={onClose}>
        <CloseIcon />
      </IconWrapper>
      <Toturial></Toturial>
      <Footer>
        To see this list again, click <HELP>HELP</HELP> on the left navigation
      </Footer>
    </Wrapper>
  );
};

export default TutorialPopup;

const Wrapper = styled.div`
  position: absolute;
  width: 650px;
  height: 603px;
  border: 1px solid grey;
  background-color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f0f0f0; /* Optional: for visibility */
  padding: 20px; /* Optional: for spacing */
  z-index: 3;
  display: flex;
  box-shadow: 7px 7px rgb(50, 50, 50);
  border-radius: 8px;
`;

const HELP = styled.span`
  font-style: italic;
`;

const Footer = styled.div`
  font-size: 17px;
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 101px;
  left: 0px;
`;
const IconWrapper = styled.div`
  svg {
    border-radius: 50%;
    border: 2px solid black;
    padding: 2px;
    cursor: pointer;
    position: absolute;
    right: 20px;
  }
`;
