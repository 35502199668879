import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { setPageIndex, setRowsCount } from '../../redux/slices/paginationSlice';
import { Pagination } from '../../modules/clientModule/components/Common/Table/types';
import { useGetClientProjectsQuery, useGetProjectsQuery, useGetVocabularyQuery } from '../../redux/rtkQuery/apiSlice';
import PaginationMobile from '../Responsive/PaginationMobile';
import { RootState } from '../../redux/root';
import { Loader } from '../../atoms';
import newProject from '../../resources/icons/new-project.svg';
import { setNewProjectModalOpen, setProjectCandidate } from '../../redux/slices/projectSlice';
import { ProjectType } from '../../api/projects';

const ProjectsTable = ({ handleCellClick }) => {
  const dispatch = useDispatch();
  const [params, setParams] = useState<any>();
  const { data, isFetching, refetch } = useGetProjectsQuery(params, { skip: params === undefined });
  const { data: clientProject } = useGetClientProjectsQuery({ project_request: true });
  const { pageCount, pageIndex, pageSize } = useSelector((s: RootState) => s.pagination);
  const searchToken = useSelector((s: RootState) => s.filters.searchToken);
  const { data: voca } = useGetVocabularyQuery(6);
  const [rows, setRows] = useState([]);
  const [pendingPrj, setPendingPrj] = useState([]);
  
  useEffect(() => {
    dispatch(setProjectCandidate(null));
  }, []);
  useEffect(() => {
    const refresh = async () => {
      await refetch();
    }
    if (clientProject && !searchToken) {
      setPendingPrj(clientProject.results);
      refresh();      
    } else if(clientProject) {
      const _filtered = clientProject.results.filter(cp => cp.address.toLowerCase().indexOf(searchToken.toLowerCase()) > -1);
      setPendingPrj(_filtered);
    }
  }, [clientProject,searchToken]);
  useEffect(() => {
    let _params: any = { limit: pageSize };
    if (searchToken && pageIndex > 0) {
      _params.offset = pageIndex * pageSize;
      _params.search = searchToken;
    } else {
      _params = searchToken
        ? { ..._params, search: searchToken }
        : {
            ..._params,
            offset: pageIndex * pageSize,
          };
    }

    setParams(_params);
  }, [pageSize, pageIndex, pageCount, searchToken]);

  useEffect(() => {
    if (data && data.count > -1) {
      dispatch(setRowsCount(data.count));
      setRows(data.results);
    }
  }, [data]);

  useEffect(() => {
    dispatch(setPageIndex(0));
  }, [searchToken]);

  const getStatus = (id: number) => {
    if (!voca) {
      return '';
    }

    const item = voca.results.find(v => v.id === id);
    if (item) {
      return item.title;
    }

    return '';
  };

  const openAddNewProjectPopup = (prjCadidate: ProjectType) => {
    dispatch(setNewProjectModalOpen(true));
    dispatch(setProjectCandidate(prjCadidate));
  };
  return (
    <div>
      {isFetching && pendingPrj.length === 0 ? (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      ) : (
        <>
          <GridBase>
            <HeaderCell>Project Name</HeaderCell>
            <HeaderCell>Permit Name</HeaderCell>
            <HeaderCell>Permit Number</HeaderCell>
            <HeaderCell>Permit Status</HeaderCell>
            <HeaderCell>Address</HeaderCell>
            <HeaderCell>Jurisdiction</HeaderCell>
            <HeaderCell>Project Manager</HeaderCell>
          </GridBase>
          <Rows>
            {pendingPrj.map((l, ndx) => {
              return (
                <RowBase key={ndx} newProject={true}>
                  <TableCell>{l.name}</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>{l.address}</TableCell>
                  <TableCell></TableCell>
                  <TableCell full={true}>
                    <LastCell>
                      <Manager>{l.projectManagerName}</Manager>
                      <IconWrapper onClick={() => openAddNewProjectPopup(l)}>
                        <img title="Create new project" src={newProject} />
                      </IconWrapper>
                    </LastCell>
                  </TableCell>
                </RowBase>
              );
            })}

            {rows.map((row, index) => {
              return (
                <RowBase key={index}>
                  <TableCell>
                    <Underlined onClick={e => handleCellClick(row, 'name')}>{row.name}</Underlined>
                  </TableCell>
                  <TableCell>
                    <FlexCol>
                      {row.permits.map((permit, index) => (
                        <Underlined
                          key={index}
                          onClick={e => handleCellClick({ ...row, permitItemId: permit.id, projectId: row.id }, 'permit name')}
                        >
                          <PermitSpace>{permit.name}</PermitSpace>
                        </Underlined>
                      ))}
                    </FlexCol>
                  </TableCell>
                  <TableCell>
                    <FlexCol>
                      {row.permits.map((permit, index) => (
                        <PermitSpace>{permit.number}</PermitSpace>
                      ))}
                    </FlexCol>
                  </TableCell>
                  <TableCell>
                    <FlexCol>
                      {row.permits.map((permit, index) => (
                        <PermitSpace>{getStatus(permit.status)}</PermitSpace>
                      ))}
                    </FlexCol>
                  </TableCell>
                  <TableCell>{row.address}</TableCell>
                  <TableCell>{row.jurisdictionName}</TableCell>
                  <TableCell><Manager>{row.managerName}</Manager></TableCell>
                </RowBase>
              );
            })}
          </Rows>
        </>
      )}

      <Pagination>
        <PaginationMobile showEverywhere={true} />
      </Pagination>
    </div>
  );
};

export default ProjectsTable;

const IconWrapper = styled.div`
  zoom: 0.05;
  cursor: pointer;
`;

const Manager = styled.div`
  padding-left:15px;
`;

const PermitSpace = styled.div`
  margin-bottom: 5px;
`;

const LastCell = styled.div`
  display: flex;
  justify-content: space-between;
`;

const GridBase = styled.div<{ newProject?: boolean }>`
  display: grid;
  grid-template-columns: repeat(3, 1fr) 177px repeat(3, 1fr);
  width: 100%;
  padding-right: 21px;
  background-color: ${({ newProject }) => (newProject ? '#D6ECBB' : 'inherit')};
`;

const RowBase = styled(GridBase)`
  border: 1px solid var(--border);
  border-radius: 6px;
  border-bottom: 4px double var(--border);
`;

const Rows = styled.div`
  max-height: 600px;
  overflow-y: auto;
`;

const Underlined = styled.div`
  text-decoration: underline;
  cursor: pointer;
`;

const HeaderCell = styled.div`
  font-weight: bold;
  padding: 15px;
  border-radius: 6px;
  color: var(--defaultTableCellTitle);
  background-color: var(--defaultTableCell);
  font-size: 0.875rem;
  font-family: 'Segoe UI', -apple-system, BlinkMacSystemFont, 'Helvetica Neue', Arial, Roboto, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
`;

const TableCell = styled.div<{ full?: boolean }>`
  margin: 0;
  text-align: left;
  padding: 18px 16px;
  border-bottom: 1px solid var(--border);
  max-width: ${({ full }) => (full ? '100%' : '200px')};
  overflow: auto;
  color: var(--mainText);
  font-size: 0.875rem;
  font-family: 'Segoe UI', -apple-system, BlinkMacSystemFont, 'Helvetica Neue', Arial, Roboto, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center; ;
`;

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`;
