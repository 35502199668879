export const stepOneLabels = {
  address: 'Address',
  street: 'Street',
  city: 'City',
  state: 'State',
  zipCode: 'Zip',
};

export const stepThreeLabels = {
  jurisdiction: 'Jurisdiction',
  franchise: 'Franchise',
  manager: 'Project Manager',
  contacts: 'Contacts',
  name: 'Project Name',
};

export const stepFourLabels = {
  permitName: 'Permit Name',
  description: 'Description',
  contractor: 'Contractor Name',
  building: 'Building',
  inspectionsNumber: 'Count of Inspections',
  totalContract: 'Contract Total',
  serviceType: 'Service Type',
  permitManager: 'Project Manager',
  workScope: 'Work',
  contacts: 'Contacts',
  expirationDate: 'Expiration Date',
};

export const defaultValues = {
  selectContactsField: '',
  address: '',
  street: '',
  city: '',
  state: '',
  zipCode: '',
  name: '',
  jurisdiction: '',
  franchise: '',
  manager: '',
  contact: '',
  permitName: '',
  description: '',
  contractor: '',
  building: '',
  inspectionsNumber: '',
  totalContract: '',
  serviceType: '',
  permitType: '',
  workScope: '',
};