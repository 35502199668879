import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { FranchiseType } from '../../api/franchise';
import { FranchiseUsersType, ManagerType, ProjectPermit } from '../../modules/clientModule/types';
import { UserType } from '../../api/users';
import { ProjectType } from '../../api/projects';

export interface projectSliceI {
  projects: ProjectPermit[];
  activeProjects: ProjectPermit[];
  franchises: FranchiseType[];
  franchiseUsers: FranchiseUsersType[];
  managers: ManagerType[];
  selectedPermit: ProjectPermit;
  selectedFranchise: FranchiseType;
  disableSave: boolean;
  permitContacts: UserType[];
  isViewContactsModal: boolean;
  newProjectModalOpen: boolean;
  projectCandidate: ProjectType
}

const initialState: projectSliceI = {
  //@ts-ignore
  projects: [],
  activeProjects: [],
  franchises: [] as FranchiseType[],
  franchiseUsers: [],
  managers: [] as ManagerType[],
  selectedPermit: {} as ProjectPermit,
  disableSave: false,
  selectedFranchise: {} as FranchiseType,
  permitContacts: [],
  isViewContactsModal: false,
  newProjectModalOpen: false,
  projectCandidate: null,
};

export const projectSlice = createSlice({
  name: 'projectSlice',
  initialState,
  reducers: {
    setProjects: (state, action: PayloadAction<ProjectPermit[]>) => {
      state.projects = action.payload;
      state.activeProjects = action.payload;
    },
    setActiveProjects: (state, action: PayloadAction<ProjectPermit[]>) => {
      state.activeProjects = action.payload;
    },
    addProject: (state, action: PayloadAction<ProjectPermit>) => {
      state.projects = [...state.projects, action.payload];
    },
    setSelectedPermit: (state, action: PayloadAction<ProjectPermit>) => {
      state.selectedPermit = action.payload;
    },
    setDisableSave: (state, action: PayloadAction<boolean>) => {
      state.disableSave = action.payload;
    },
    setFranchises: (state, action: PayloadAction<FranchiseType[]>) => {
      state.franchises = action.payload;
    },
    setManagers: (state, action: PayloadAction<ManagerType[]>) => {
      state.managers = action.payload;
    },
    setSelectedFranchise: (state, action: PayloadAction<FranchiseType>) => {
      state.selectedFranchise = action.payload;
    },
    setFranchiseUsers: (state, action: PayloadAction<FranchiseUsersType>) => {
      const existingUsers = state.franchiseUsers;
      state.franchiseUsers = [...existingUsers, action.payload];
    },
    setPermitContacts: (state, action: PayloadAction<UserType[]>) => {
      state.permitContacts = action.payload;
    },
    setIsViewContactsModal: (state, action: PayloadAction<boolean>) => {
      state.isViewContactsModal = action.payload;
    },
    setNewProjectModalOpen: (state, action: PayloadAction<boolean>) => {
      state.newProjectModalOpen = action.payload;
    },
    setProjectCandidate: (state, action: PayloadAction<ProjectType>) => {
      state.projectCandidate = action.payload;
    },
    resetProjects: state => {
      state.projects = [];
      state.activeProjects = [];
      state.franchises = [];
      state.franchiseUsers = [];
      state.managers = [];
      state.selectedPermit = {} as ProjectPermit;
      state.disableSave = false;
      state.selectedFranchise = {} as FranchiseType;
      state.permitContacts = [],
      state.isViewContactsModal = false;
      state.projectCandidate = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setProjects,
  setActiveProjects,
  addProject,
  setSelectedPermit,
  setDisableSave,
  setFranchises,
  setManagers,
  setSelectedFranchise,
  setFranchiseUsers,
  resetProjects,
  setPermitContacts,
  setIsViewContactsModal,
  setNewProjectModalOpen,
  setProjectCandidate
} = projectSlice.actions;

export default projectSlice.reducer;
