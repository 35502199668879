import { useEffect, useState } from 'react';

const useGetCleanRecrods = (_records) => {
    const [records, setRecords] = useState([]);
      
      useEffect(() => {
        if(_records && _records.length === 1 && Object.keys(_records[0]).length === 0){
          return;
        }
        setRecords(_records);
      }, [_records])
    return records;
};

export default useGetCleanRecrods;