import axios from 'axios';
import { getAccessToken } from '../auth';
import { PaginatedList, PaginatedRequest } from '../types';
import { formatToFormData } from '../utils';
import { api } from './api';
import { FranchiseType } from './franchise';
import {
  onResponseErrorInterceptor,
  onResponseInterceptor,
} from './interceptors';
import { JurisdictionType } from './jurisdiction';
import { ProjectPermitInvoiceType } from '../Components/InvoiceTool/types';

export const getAllInvoices = (clientId: number) =>
  api.request<ProjectPermitInvoiceType[]>({
    method: 'GET',
    url: `/app_invoice/invoices/contact/${clientId}/`,
});

export const getFeesList = (params: PaginatedRequest) =>
  api.request<FeesTypeList>({
    method: 'GET',
    url: '/fees/',
    params,
  });

export const createFees = (data: FeesType) =>
  api.request<FeesType>({
    method: 'POST',
    url: '/fees/',
    data,
  });

export const deleteFees = (id: number) =>
  api.request<FeesType>({
    method: 'DELETE',
    url: `/fees/${id}/`,
  });

export const updateFees = (data: FeesType) =>
  api.request<FeesType>({
    method: 'PATCH',
    url: `/fees/${data.id}/`,
    data,
  });

export const getPermitsFees = params =>
  api.request<FeesTypeList>({
    method: 'GET',
    url: `/fees/permits/${params.permit}/fees/`,
    params,
  });

export const getPermitVoidStatus = params =>
  api.request<FeesTypeList>({
    method: 'GET',
    url: `/fees/permits/${params.feeId}/partial-payment-status/`;//?fee_id=${params.feeId}`,
  });

export const getUnpaidPermitsFees = params =>{
  const data= params.feeId ? `?fee_id=${params.feeId}` : "";
  return api.request<FeesTypeList>({
    method: 'GET',
    url: `/fees/permits/${params.permit}/get-payment-details/${data}`,
    data
  });
}
  
  

export const createPermitFee = data => {
  return api.request({
    method: 'POST',
    url: `/fees/permits/${data.permit}/fees/`,
    data,
  });
};

export const postPartialPayment = data => {
  return api.request({
    method: 'POST',
    url: `/fees/permits/${data.permit}/partial-payment/`,
    data,
  });
};

export const setFeeAsPaid = data => {
  return api.request({
    method: 'POST',
    url: `/fees/permits/${data.permit}/fees/${data.id}/set_as_paid/`,
    data,
  });
};

export const getPermitsFeesStatistics = params =>
  api.request<FeesTypeList>({
    method: 'GET',
    url: `/fees/permits/${params.permit}/fees/get_fee_stats/`,
    params,
  });

  export const getNextInvoiceNumber = params => {
    return api.request<FeesTypeList>({
      method: 'GET',
      url: `/app_invoice/next-invoice-number/`,
      params,
    });
  }

  export const getStripeClientSecret = params => {
    return api.request<FeesTypeList>({
      method: 'GET',
      url: `/app_invoice/get_payment_intent/`,
      params,
    });
  }

export const updatePermitFees = data => {
  return api.request({
    method: 'PATCH',
    url: `/fees/permits/${data.permit}/fees/${data.id}/`,
    data,
  });
};

// creating update permit fess for manual
export const manualUpdatePermitFees = data => {

  return api.request({
    method: 'POST',
    url: `/fees/permits/${data.permit}/fees/${data.id}/set_as_paid/?payment_method=${data.method}`,
    data,
  });
};
export const voidUpdatePermitFees = data => {
  return api.request({
    method: 'POST',
    url: `/fees/permits/${data.permit}/fees/${data.id}/set_as_paid/?payment_method=${data.method}&status=Void`,
    data,
  });
};

export const getPermitFeesComments = (params, permit, id) =>
  api.request({
    method: 'GET',
    url: `/fees/permits/${permit}/fees/${id}/comments/`,
    params,
  });

export const createPermitFeesComment = (data: any) => {
  axios.interceptors.response.use(
    onResponseInterceptor,
    onResponseErrorInterceptor,
  );

  const body = formatToFormData({
    body: data.text,
    file: data.file,
    visibleFilename: data.file?.name,
  });

  const { id, permit } = data;

  return axios.request<DocumentType>({
    method: 'POST',
    url: `/api/v1/fees/permits/${permit}/fees/${id}/comments/`,
    data: body,
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};

export const sendFeesInvoice = (data: any) => {
  return api.request({
    method: 'POST',
    url: `/fees/permits/${data.permit}/fees/send_invoice/?franchise_id=${data?.franchise}`,
    data,
  });
};

export type FeesTypeList = PaginatedList<FeesType>;

export type FeesButtonTypeLink = FeesButtonType & {
  linkTo: string;
};

export interface FeesButtonType {
  id: number;
  isActive: boolean;
  isDeleted?: boolean;
  title: string;
  vocabularyEntity: number;
}

export interface FeesType {
  id?: number;
  name: string;
  isActive: boolean;
  isDeleted?: boolean;
  franchiseNames: string;
  franchise: number[] | FranchiseType[];
  jurisdiction: number[] | JurisdictionType[];
  jurisdictionNames: string;
  amount: string;
  feeServiceType: string;
  skuCode: string;
  description: string;
  isFranchiseAll: boolean;
  feeType?: number | string;
  reimburse: boolean;
  employeeCanEdit: boolean;
  isDefault?: boolean;
}
