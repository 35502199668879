import * as React from 'react';
import styled from 'styled-components';

import { PERMIT_MENU_ACTIVE_TAB } from '../constants/permitMenu';
import { GET_ALL_QUERY_PARAMS } from '../constants';

import { ButtonMain } from '../atoms';

import { InputSelect } from '../molecules';
import ModalDefaultDraggable from '../molecules/ModalDefaultDraggable';
import ModalInfo from '../molecules/ModalInfo';

import PermitActivitiesForm from '../forms/PermitActivitiesForm';

import {
  getVocabulariesDataList,
  VocabularyDataType,
} from '../api/vocabulary';
import {
  createPermitActivity,
  updatePermitActivity,
} from '../api/activities';

import useTablePage from '../hooks/useTablePage';
import { getUnLockDocuments } from '../api/activities';
import { Lock } from '@material-ui/icons';
import { LockOpen } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import DownloadAllPermitsButton from './DownloadAllInspectionsForms/DownloadAllPermitsButton';
import ReviewCommentsLetterButton from './ReviewCommentsLetterButton';
import { dispatch } from '../redux/store';
import { setShowPartialPaymentPopup } from '../redux/slices/feesSlice';

const selectStyles = {
  control: (provided, state) => ({
    ...provided,
    minWidth: '240px',
    minHeight: '30px',
    height: '30px',
    maxWidth: '400px',
    borderRadius: '5px !important',
    boxShadow: state.isFocused ? null : null,
  }),
  valueContainer: provided => ({
    ...provided,
    height: '30px',
    padding: '0 6px 2px 6px',
  }),
  placeholder: provided => ({
    ...provided,
    top: '45%',
  }),
  input: provided => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: '30px',
  }),
  singleValue: provided => ({
    ...provided,
    top: '45%',
  }),
};

const SubPermitMenu = ({
  activeTab,
  onActivityChange,
  permit,
  selectedPermit,
  setAddNote,
  setAddTicket,
  setAddInspection,
  setAddFees,
  setActivityCreated,
  setAddCondition,
  isFeeSelected,
  setIsSendingSelectedFees,
  workTypes,
  buildingTypes,
  selectWork,
  selectBuilding,
  setActivityTypes,
  activityTypes,
  setActivityValue,
  activityValue,
  selectedWork,
  selectedBuilding,
  setAddDocument,
  setDownloadAllDocs,
  isDocsAvailable,
  downloadAllDocs,
  isDocsWaitingOnClient,
  disableSend,
  setSendDocs,
  cameFromSearch,
  setLocked,
  isLocked,
  documentsData,
  disableReviewCommentBtn,
  setShowReviewCommentLtr,
}: Props) => {  
  const openPopup = (e) => {
    dispatch(setShowPartialPaymentPopup(true))
  }
  if (activeTab === PERMIT_MENU_ACTIVE_TAB.ACTIVITIES) {
    const {
      isModalOpen,
      handleCloseModal,
      createOrUpdate,
      modalError,
      modalSuccess,
      modalTitle,
      values,
      errors,
      handleAdd,
      handleCloseModalInfo,
      modalErrorTitle,
    } = useTablePage({
      createEndpoint: createPermitActivity,
      updateEndpoint: updatePermitActivity,
      modalCreateTitle: 'Add Activity',
      modalUpdateTitle: 'Edit Activity',
    });

    const handleCloseActivitiesModal = () => {
      if (!modalError && !modalSuccess) {
        handleCloseModal();
        setActivityCreated(false);
      }
    };

    React.useEffect(() => {
      if (modalSuccess) {
        setActivityCreated(true);
        setActivityValue('');
        onActivityChange('');
      }
    }, [modalSuccess]);

    React.useEffect(() => {
      if (activeTab === PERMIT_MENU_ACTIVE_TAB.ACTIVITIES) {
        Promise.all([
          getVocabulariesDataList({
            ...GET_ALL_QUERY_PARAMS,
            vocabularyEntity: 3,
            ordering: 'title',
          }),
        ])
          .then(([activityTypesResponse]) => {
            const activeTypes = activityTypesResponse.data.results?.filter(
              el =>
                el?.isActive && el?.title !== 'Inspection' && el?.title !== 'Conditions',
            );
            setActivityTypes([
              { value: '*', title: 'All types' },
              ...activeTypes,
            ]);
          })
          // eslint-disable-next-line no-console
          .catch(err => console.log(err))
          .finally(() => {});
      }
    }, [activeTab]);

    return (
      <Container>
        <Part>
          <>
            {activityTypes?.length ? (
              <InputSelect
                placeholder="Select Activity Type"
                isSearchable={false}
                selectAllActive
                styles={selectStyles}
                onChange={selected => {
                  setActivityValue(selected);
                  onActivityChange(
                    // @ts-ignore
                    selected?.id,
                  );
                }}
                value={activityValue as any}
                options={activityTypes as any}
                getOptionValue={(item: any) =>
                  item?.value
                    ? item?.value
                    : item.id || Math.random()
                }
                getOptionLabel={(item: any) =>
                  item?.name ? item?.name : item?.title
                }
                defaultStyles
              />
            ) : null}
            {isModalOpen && (
              <ModalDefaultDraggable
                title={modalTitle}
                onClose={handleCloseActivitiesModal}
              >
                <PermitActivitiesForm
                  onSubmit={createOrUpdate}
                  initialValues={values}
                  modalError={modalError}
                  addButtonText="Add"
                  updateButtonText="Update"
                  initialErrors={errors}
                  permit={permit}
                  updateTable={onActivityChange}
                  setActivityCreated={setActivityCreated}
                  selectedPermit={selectedPermit}
                  activeTab={activeTab}
                />
              </ModalDefaultDraggable>
            )}
            {(modalSuccess || modalError) && (
              <ModalInfo
                timeToClose={5000}
                isError={modalError}
                onClose={handleCloseModalInfo}
                errorMsg={modalErrorTitle}
              />
            )}
          </>
        </Part>
        <ButtonsWrapper>
          <ReviewCommentsLetterButton              
               setShowReviewCommentLtr={setShowReviewCommentLtr} 
               cameFromSearch={cameFromSearch}
               disableReviewCommentBtn={disableReviewCommentBtn}
               />                   
          <ButtonMain disabled={cameFromSearch} onClick={handleAdd}>
            Add Activity
          </ButtonMain>
          </ButtonsWrapper>
      </Container>
    );
  }

  if (activeTab === PERMIT_MENU_ACTIVE_TAB.INSPECTIONS) {
    const shouldInspectionDisable = () => {
      return !selectedPermit?.serviceType?.find(type => (type?.title?.toLowerCase() === "inspection" || type?.title?.toLowerCase() === "consulting" || type?.title?.toLowerCase() === "phase permit")) || cameFromSearch
    }
    return (
      <Container>
        <Part />
        <Buttons>
          <DownloadAllPermitsButton permit={permit}/>
          <Part>
            <ButtonMain
            disabled={shouldInspectionDisable()}
              onClick={() => setAddInspection(true)}
            >
              Add inspection
            </ButtonMain>
          </Part>
        </Buttons>
       
      </Container>
    );
  }

  if (activeTab === PERMIT_MENU_ACTIVE_TAB.FEES) {
    return (
      <Container>
        <Part />
        <Part>
          <FeesButtonsWrapper>
            <ButtonMain
                disabled={ cameFromSearch}
                onClick={openPopup}
              >
              Accept Payment
            </ButtonMain>
            <ButtonMain
              disabled={!isFeeSelected || cameFromSearch}
              onClick={() => setIsSendingSelectedFees(true)}
            >
              Send
            </ButtonMain>
            <ButtonMain onClick={() => setAddFees(true)} disabled={cameFromSearch}>
              Add Fees
            </ButtonMain>
          </FeesButtonsWrapper>
        </Part>      
      </Container>
    );
  } else {
    dispatch(setShowPartialPaymentPopup(false));
  }

  if (activeTab === PERMIT_MENU_ACTIVE_TAB.NOTES) {
    return (
      <Container>
        <Part />
        <Part>
          <ButtonMain onClick={() => setAddNote(true)} disabled={cameFromSearch}>
            Add Note
          </ButtonMain>
        </Part>
      </Container>
    );
  }

  if (activeTab === PERMIT_MENU_ACTIVE_TAB.TICKETS) {
    return (
      <Container>
        <Part />
        <Part>
          <ButtonMain onClick={() => setAddTicket(true)} disabled={cameFromSearch}>
            Add Ticket
          </ButtonMain>
        </Part>
      </Container>
    );
  }

  if (activeTab === PERMIT_MENU_ACTIVE_TAB.DOCUMENTS) {    
    return (
      <Container>
        <Part>
          
        </Part>
        <ButtonsWrapper large>
          <ButtonMain onClick={() => setAddDocument(true)} disabled={cameFromSearch}>
            Add
          </ButtonMain>
          <ButtonMain
            disabled={!isDocsAvailable || cameFromSearch}
            onClick={e => downloadAllDocs()}
          >
            <Tooltip  placement="left" title="All completed documents will be downloaded">
            <p style={{margin: 0, padding: 0}}>Download</p>
            </Tooltip>
          </ButtonMain>
          <ButtonMain
            // disabling send button as per ticket no EA-650
            // disabled={cameFromSearch || disableSend}
            disabled={true}
            onClick={() => setSendDocs(true)}
          >
            Send
          </ButtonMain>
          <ButtonMain
          disabled={documentsData?.length === 0 || cameFromSearch}
          onClick={()=>{
            getUnLockDocuments({is_locked:documentsData?.find(item=>item?.isLocked === true)?.isLocked? false : true},permit,(result)=>{
              if(result?.data)
              {
               setLocked(!isLocked)
              }
           });
         
          }}
          >
            <Tooltip placement="left" title={documentsData?.find(item=>item?.isLocked === true)?.isLocked ? 'Click here to unlock for Client' : 'Click here to lock for Client'}>
            {
              documentsData?.find(item=>item?.isLocked === true)?.isLocked?
              <Lock/>:
              <LockOpen/>
            }
            </Tooltip>
          </ButtonMain>
        </ButtonsWrapper>
      </Container>
    );
  }

  if (activeTab === PERMIT_MENU_ACTIVE_TAB.CONDITIONS) {
    return (
      <Container>
        <Part />
        <Part>
          <ButtonMain onClick={() => setAddCondition(true)} disabled={cameFromSearch}>
            Add Condition
          </ButtonMain>
        </Part>
      </Container>
    );
  }

  return <Container />;
};

const Buttons = styled.div`
  display: flex;
  width: 466px;
  justify-content: space-between;
`;

const FeesButtonsWrapper = styled.div`
  display: flex;
  width: 408px;
  gap: 10px;
  position: relative;
  left: -199px;
`;
const Container = styled.div`
  width: calc(100% + 48px);
  background: var(--secondary);
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: -24px;
  padding: 0 24px;
`;

const Part = styled.div`
  width: 200px;
`;

const ButtonsWrapper = styled.div<BtnWrpProps>`
  display: flex;
  width: ${({ small, large }) => (small ? '240px' : large ? '450px' : '400px')};
  justify-content: space-between;
  margin-left: -60px;
  .MuiButtonBase-root {
    min-width: 90px;
  }
`;

const DocTypesWrapper = styled.div`
  display: flex;
  width: 460px;
  justify-content: space-between;
`;

const DocSelectType = styled.div`
  width: 210px;
`;

interface Props {
  activeTab: PERMIT_MENU_ACTIVE_TAB;
  onActivityChange: (data: any) => void;
  setSendDocs: (data: any) => void;
  setAddNote: (data: boolean) => void;
  setAddInspection: (data: boolean) => void;
  setAddFees: (data: boolean) => void;
  setActivityCreated: (data: boolean) => void;
  setIsSendingSelectedFees: (data: boolean) => void;
  setAddCondition: (data: boolean) => void;
  permit: number;
  isFeeSelected: boolean;
  workTypes: VocabularyDataType;
  buildingTypes: VocabularyDataType;
  selectWork: (data: any) => void;
  selectBuilding: (data: any) => void;
  setActivityTypes: (data: any) => void;
  setAddDocument: (data: boolean) => void;
  activityTypes: any;
  setActivityValue: (data: any) => void;
  setDownloadAllDocs: (data: boolean) => void;
  activityValue: any;
  selectedWork: any;
  selectedBuilding: any;
  isDocsAvailable: boolean;
  downloadAllDocs?: Function;
  isDocsWaitingOnClient: boolean;
  isLocked: boolean;
  documentsData:any;
  setLocked: (data: boolean) => void;
}

interface BtnWrpProps {
  small?: boolean;
  large?: boolean;
}

export default SubPermitMenu;
