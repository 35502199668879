import * as yup from 'yup';
import { stepFourLabels, stepOneLabels, stepThreeLabels } from './ProjectFormLabels';
import { zipCode } from '../../regex';

export const validationSchema = [
    // validation for step1
    yup.object().shape({
      address: yup
        .string()
        .required()
        .label(stepOneLabels.address),
      state: yup
        .string()
        .required()
        .label(stepOneLabels.state),
      street: yup
        .string()
        .required()
        .label(stepOneLabels.street),
      city: yup.string().required().label(stepOneLabels.city),
      zipCode: yup
        .string()
        .matches(
          zipCode,
          'Invalid Code number. The code number must be in format 55555 or 55555-5555',
        )
        .required()
        .label(stepOneLabels.zipCode),
    }),
    // validation for step3
    yup.object().shape({
      name: yup
        .string()
        .required()
        .label(stepThreeLabels.name),
      jurisdiction: yup
        .object()
        .shape({
          value: yup.string(),
          label: yup.string(),
        })
        .required()
        .typeError('Select a Jurisdiction from the list')
        .label(stepThreeLabels.jurisdiction),
      manager: yup
        .object()
        .shape({
          value: yup.string(),
          label: yup.string(),
        })
        .required()
        .typeError('Select a Project Manager from the list')
        .nullable()
        .label(stepThreeLabels.manager),
      contacts: yup
        .array()
        .of(
          yup.object().shape({
            value: yup.string(),
            label: yup.string(),
          }),
        )
        .typeError('Select a Contact Names from the list')
        .nullable()
        .label(stepThreeLabels.contacts),
    }),
    // validation for step4
    yup.object().shape({
      permitName: yup
        .string()
        .required()
        .label(stepFourLabels.permitName),
      description: yup
        .string()
        .required()
        .label(stepFourLabels.description),
      contractor: yup
        .object()
        .shape({
          value: yup.string(),
          label: yup.string(),
        })
        .nullable()
        .label(stepFourLabels.contractor),
      building: yup
        .object()
        .shape({
          value: yup.string(),
          label: yup.string(),
        })
        .required()
        .typeError('Select a building from the list')
        .label(stepFourLabels.building),
      inspectionsNumber: yup
        .number()
        .transform((cv, ov) => {
          return ov === '' ? undefined : cv;
        })
        // .positive()
        // .typeError('Count of inspections must be a number')
        .min(0)
        .typeError('Count of inspections must be a number')
        .nullable()
        .label(stepFourLabels.inspectionsNumber),
      totalContract: yup
        .string()
        .label(stepFourLabels.totalContract)
        .nullable(),
      serviceType: yup
        .array()
        .of(
          yup.object().shape({
            value: yup.string(),
            label: yup.string(),
          }),
        )
        .required()
        .typeError('Select a Service Types from the list')
        .nullable()
        .label(stepFourLabels.serviceType),
      permitManager: yup
        .object()
        .shape({
          value: yup.string(),
          label: yup.string(),
        })
        .required()
        .typeError('Select a Project Manager from the list')
        .nullable()
        .label(stepFourLabels.permitManager),
      workScope: yup
        .object()
        .shape({
          value: yup.string(),
          label: yup.string(),
        })
        .required()
        .typeError('Select a Scope of Work from the list')
        .nullable()
        .label(stepFourLabels.workScope),
      contacts: yup
        .array()
        .of(yup.object())
        .required()
        .typeError('Select permit contacts'),
    }),
  ];