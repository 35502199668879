import * as yup from 'yup';
import { phoneRegex } from '../regex';

export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const schema = yup.object().shape({
    firstName: yup.string().required().label("First Name"),
    lastName: yup.string().required().label("Last Name"),
    phone: yup.string().required().matches(
        phoneRegex,
        'Invalid phone number. The phone number must be in format 2XX-XXX-XXXX'
    ).label("Phone"),
    address: yup.string().required().label("Address"),
    email: yup.string().matches(
        emailRegex,
        'Invalid email format'
      ).required().label("Email")
});