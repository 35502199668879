import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import LoopIcon from '@material-ui/icons/Loop';
import { yupResolver } from '@hookform/resolvers';
import schema from './Schema';
import { Button } from '@material-ui/core';
import ProjectControlsArea from './ProjectControlsArea';
import { GET_ALL_QUERY_PARAMS } from '../../../../../../constants';
import { getVocabulariesDataList } from '../../../../../../api/vocabulary';
import { useDispatch, useSelector } from 'react-redux';
import { setShowDrawerContent, setVocabulary } from '../../../../../../redux/slices/generalSlice';
import { RootState } from '../../../../../../redux/root';
import useGetNewProjectData from '../../../../hooks/useGetNewProjectData';
import { persistNewProject } from '../../../../utils';
import { useHistory } from 'react-router';
import { ButtonWithLoader } from '../../../../../../molecules';

type FormValue = {
  address: string;
  street: string;
  city: string;
  state: string;
  zipCode: string;
  contact: string;
  phone: string;
  projectName: string;
  description: string;
  serviceType: string;
  applicantType: string;
  documentFile: any;
  franchise: any;
  manager: any;
};

type Props = {
  setShowModal: Function;
};

const NewProject: FC<Props> = ({ setShowModal }) => {
  const { handleSubmit, control, register, errors, trigger, setValue, getValues, clearErrors, reset } = useForm<FormValue>({
    resolver: yupResolver(schema),
    defaultValues: {} as FormValue,
  });

  useGetNewProjectData();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const disableSave = useSelector((s: RootState) => s.projectsSlice.disableSave);
  const vocabularies = useSelector((state: RootState) => state.general.vocabulary);

  useEffect(() => {
    const loadVocabulary = async () => {
      const payload = await getVocabulariesDataList({
        ...GET_ALL_QUERY_PARAMS,
        ordering: 'title',
      });
      const vocabularyItems = payload.data.results;
      dispatch(setVocabulary(vocabularyItems));
    };
    if (vocabularies.length === 0) {
      loadVocabulary();
    }
  }, []);
  const onSubmit = async data => {
    setIsLoading(true);
    const result = await persistNewProject(data, vocabularies);
    //@ts-ignore
    if (result.status === 201) {
      setShowModal(true);
      setIsLoading(false);
      dispatch(setShowDrawerContent(false));
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" style={{ width: '100%', overflow: 'auto' }}>
      <Controls>
        <ProjectWrapper>
          <GroupSection>
            <ProjectControls>
              <ProjectControlsArea
                register={register}
                errors={errors}
                control={control}
                setValue={setValue}
                trigger={trigger}
                getValues={getValues}
                clearErrors={clearErrors}
                reset={reset}
              />
              <ButtonWrapper>
              <Button type="submit" color="primary" style={{ width: '200px' }} variant="contained" disabled={disableSave}>
                Submit Request
                {isLoading && (
                  <LoadingIconWrapper>
                    <LoopIcon />
                  </LoadingIconWrapper>
                )}
              </Button>
            </ButtonWrapper>
            </ProjectControls>            
          </GroupSection>
        </ProjectWrapper>
      </Controls>
    </form>
  );
};

export default NewProject;

const Controls = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
`;

const GroupSection = styled.div`
  border: 1px solid grey;
  border-radius: 8px;
  box-shadow: 7px 8px 12px grey;
  width: calc(100% - 40px);
  margin: 20px;
`;

const AddressWrapper = styled.div`
  height: 250px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ProjectWrapper = styled.div`
  flex-grow: 1;
`;

const ProjectControls = styled.div`
  height: 79vh;
  overflow-y: auto;
  @media only screen and (max-width: 840px) {
    height: 71vh;
  }
  @media only screen and (max-width: 540px) {
    height: 79vh;
  }
`;

const ButtonWrapper = styled.div`
  display: grid;
  justify-items: center;
  align-items: center;
  padding: 8px;
  position: relative;
`;

const LoadingIconWrapper = styled.div`
  position: absolute;
  color: white;
  right: 9px;
  top: 7px;
  svg {
    animation: rotate 1s infinite;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }
`;
