import React, { FC } from 'react';
import styled from 'styled-components';
import { Logo } from '../../../atoms';
import { SidebarMenuLink } from '../../../molecules';
import { createIcon } from '../../../organisms/EmployeeSidebar';
import InspectionsIcon from '../../../resources/icons/hard-hat3.svg';
import Fees from '../../../resources/icons/dollar.svg';
import Documents from '../../../resources/icons/list.svg';
import Projects from '../../../resources/icons/checkmark.svg';
import Home from '../../../resources/icons/home_page.svg';
import Tickets from '../../../resources/icons/tickets.svg'
import Conditions from '../../../resources/icons/stop_sign.svg';
import Help from '../../../resources/icons/help.svg';
import { ROUTES } from './Routes/ClientRoutes';

const ClientSidebar:FC<{logoClassName?: string; isMobile?:boolean}> = ({ logoClassName, isMobile }) => {

  const MenuItems = {
    Dashboard: 'Dashboard',
    MyProjects: 'My Projects/Permits',
    Inspections: 'Inspections',
    Documents: 'Documents',
    Conditions: 'Conditions',
    Fees: 'Fees',
    Tickets: 'Tickets',
    Help: 'Help',
  };

  return (
    <Wrapper isMobile={isMobile}>
      <RootLogo className={logoClassName}>
        <Logo />
      </RootLogo>

      <MenuWrapper>
        <SidebarMenuLink
          icon={() => createIcon(Home)}
          to={ROUTES.DASHBOARD}
          title={MenuItems.Dashboard}
        />
        <SidebarMenuLink
          icon={() => createIcon(Projects,0.8)}
          to={ROUTES.PROJECTS}
          title={MenuItems.MyProjects}
        />
        <SidebarMenuLink
          icon={() => createIcon(InspectionsIcon,0.8)}
          to={ROUTES.INSPECTIONS}
          title={MenuItems.Inspections}
        />
        <SidebarMenuLink
          icon={() => createIcon(Documents,0.7)}
          to={ROUTES.DOCUMENTS}
          title={'Documents & Reviews'}
        />
        <SidebarMenuLink
          icon={() => createIcon(Conditions,0.7)}
          to={ROUTES.CONDITIONS}
          title={MenuItems.Conditions}
        />
        <SidebarMenuLink
          icon={() => createIcon(Fees,0.7)}
          to={ROUTES.INVOICES}
          title={MenuItems.Fees}
        />
        {/* <SidebarMenuLink
          icon={() => createIcon(Tickets)}
          to={ROUTES.TICKETS}
          title={MenuItems.Tickets}
        /> */}
        <SidebarMenuLink
          icon={() => createIcon(Help,0.8)}
          to={ROUTES.HELP}
          title={MenuItems.Help}
        />
      </MenuWrapper>
    </Wrapper>
  );
};

export default ClientSidebar;

const RootLogo = styled.div`
  padding: 20px 14px;
  margin-bottom: 40px;
`;

const Wrapper = styled.div<{ isMobile?: boolean }>`
  z-index: 10;
  position: relative;
  width: 80px;
  background: var(--primary);
  padding-top: 15px;
  transition: width 0.5s;
  height: 100vh;
  @media only screen and (max-width: 840px) {
    display:  ${({ isMobile }) =>
    isMobile ? 'inherit' : 'none'};
  }
`;

const MenuWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 48px;
  width: 100%;
`;

const IconWrapper = styled.div`
  .MuiIcon-root {
    margin: 0;
    display: block;

    svg {
      width: 40px;
      height: 40px;
    }
  }
`;

interface Props {
  logoClassName?: string;
}
