import React, { FC } from 'react';
import styled from 'styled-components';

const WrapperWithRightDrawer:FC = ({children}) => {
    return (
        <SlideArea>
            {children}
        </SlideArea>
    );
};

export default WrapperWithRightDrawer;

const SlideArea = styled.div`
  display: flex;
  width: 92vw;
  height: 84vh;
  overflow: hidden;
  position: relative;
  height: 84vh;
  @media only screen and (max-width: 840px) {
    width: 100%;
    height: 81vh;
  }
  @media only screen and (max-width: 540px) {
    
  }
`;