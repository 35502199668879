/* eslint-disable function-paren-newline */
import * as React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { generatePath } from 'react-router';
import tableColumnsFormatter from '../utils/tableColumnsFormatter';
import { TradeType } from '../api/trades';
import Table from '../organisms/Table';
import ActionsPanel from '../organisms/ActionsPanel';
import useTablePage from '../hooks/useTablePage';
import { convertTableBoolean } from '../utils/convertTableValues';
import {
  ModalDefaultDraggable,
  LoaderWithContainer,
} from '../molecules';
import {
  getProjects,
  createProject,
  updateProject,
} from '../api/projects';
import { getPermits, createPermit } from '../api/permits';
import ModalInfo from '../molecules/ModalInfo';
import ProjectFormContainer from '../forms/ProjectFormContainer';
import { ROUTES } from '../constants/routes';
import { getVocabulariesDataList } from '../api/vocabulary';
import ProjectEditForm from '../forms/ProjectEditForm';
import { getJurisdictions } from '../api/jurisdiction';
import { GET_ALL_QUERY_PARAMS } from '../constants';
import { getUsers, getUsersByProject, getUsersCustom } from '../api/users';
import { USER_TYPES_VALUES } from '../constants/forms';
import DashboardBlocks from './DashBoardBlocks';
import useDidUpdateEffect from '../hooks/useDidUpdate';
import ProjectsTable from './ProjectsPage/ProjectsTable';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/root';
import { useEffect } from 'react';
import { setNewProjectModalOpen, setProjectCandidate } from '../redux/slices/projectSlice';

const getStatuses = (permits, statuses) =>
  permits.map(
    permit =>
      statuses.find(status => permit.status === status.id)
        ?.title,
  );

const modifiedGetProjectsWithPermits = params =>
  Promise.all([
    getProjects({...params, ordering: null}),
    getVocabulariesDataList({
      ...GET_ALL_QUERY_PARAMS,
      vocabularyEntity: 6,
      ordering: 'title',
    }), // statuses
  ]).then(
    ([
      projectsResponse,
      statusesResponse,
    ]) => {      
      
      return {
        ...projectsResponse,
        data: {
          ...projectsResponse.data,
          results: projectsResponse.data.results.map(
            project => {
              const projectPermits = project?.permits
              
              return {
                ...project,
                permitsNames: projectPermits.map(
                  permit => permit.name,
                ),
                permits: projectPermits,
                permitsNumbers: projectPermits.map(permit =>
                  permit.number ? permit.number : '',
                ),
                permitsStatuses: getStatuses(
                  projectPermits,
                  statusesResponse.data.results,
                ),
                address: `${project.street}, ${project.city}, ${project.state}, ${project.zipCode}`,
              };
            },
          ),
        },
      };
    },
  );

const ProjectsPage = () => {
  const [
    isExistingProjectChosen,
    setIsExistingProjectChosen,
  ] = React.useState(false);

  const [
    addContactModal,
    setAddContactModal,
  ] = React.useState(false);

  const [modalTitle, setModalTitle] = React.useState(
    'Add Project',
  );
 const newProjectModalOpen = useSelector((s:RootState) => s.projectsSlice.newProjectModalOpen);
  const [
    isEditProjectModal,
    setEditProjectModal,
  ] = React.useState(false);

  const [projectData, setProjectData] = React.useState({});

  const [
    isEditProjectMode,
    setEditProjectMode,
  ] = React.useState(false);

  const [isLoading, setIsLoading] = React.useState(false);
  const [
    jurisdictionsData,
    setJurisdictionsData,
  ] = React.useState([]);
  const [
    projectManagersData,
    setProjectManagersData,
  ] = React.useState([]);
  const [contactData, setContactsData] = React.useState([]);
  const [defaultValues, setDefaultValues] = React.useState(
    {},
  );
  const HandleAddContactModal=(flag)=>{
    // window.scrollTo({ top: 0, behavior: 'smooth', });
    setAddContactModal(flag)
  }
  const setInitialProjectValues = (
    initial,
    jurisdiction,
    manager,
    contacts,
  ) => {    
    
    if (Object.keys(projectData).length) {
      setDefaultValues({
        ...initial,
        jurisdiction: jurisdiction.filter(
          el => el.id === initial.jurisdiction,
        )[0],
        manager: manager.find(
          el => el.id === initial?.manager,
        ),
        contacts: contacts.filter(el =>
          initial.contacts.includes(el.id),
        ),
      });
    }
  };

  useDidUpdateEffect(() => {
    setIsLoading(true);
if(Object.keys(projectData).length > 0) {
    Promise.all([
      getJurisdictions({
        ...GET_ALL_QUERY_PARAMS,
        ordering: 'name',
      }),
      getUsers({
        ...GET_ALL_QUERY_PARAMS,
        userRole: USER_TYPES_VALUES.EMPLOYEE,
      }),
      getUsersByProject(projectData?.id)
    ])
      .then(
        ([
          jurisdictionsResponse,
          projectManagersResponse,
          individualClientsResponse,
          // companyClientsResponse,
        ]) => {
          
          setJurisdictionsData(
            jurisdictionsResponse.data.results,
          );
          setProjectManagersData(
            projectManagersResponse.data.results,
          );
          setContactsData([
            ...individualClientsResponse.data.filter(
              el => el?.isActive,
            ),
            // ...companyClientsResponse.data.results.filter(
            //   el => el?.isActive,
            // ),
          ]);
          setInitialProjectValues(
            projectData,
            jurisdictionsResponse.data.results,
            projectManagersResponse.data.results,
            [
              ...individualClientsResponse.data.filter(
                el => el?.isActive,
              ),
            ],
          );
        },
      )
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
    }
  }, [projectData]);

  React.useEffect(() => {
    if (isExistingProjectChosen) {
      setModalTitle('Add Permit');
    }
  }, [isExistingProjectChosen]);

  const history = useHistory();
  const dispatch = useDispatch();
  const {
    search,
    setSearch,
    modalError,
    modalSuccess,
    isModalOpen,
    // modalTitle,
    modalErrorTitle,
    tableManuallyUpdate,
    createOrUpdate,
    handleAdd,
    handleCloseModal,
    handleCloseModalInfo,
    updateTable,
  } = useTablePage<TradeType>({
    createEndpoint: isExistingProjectChosen
      ? createPermit
      : createProject,
    updateEndpoint: updateProject,
    modalCreateTitle: modalTitle,
  });

  useEffect(() => {
    dispatch(setNewProjectModalOpen(isModalOpen))
  }, [isModalOpen])
  const columnsOrder = [
    'name',
    'permitsNames',
    'permitsNumbers',
    'permitsStatuses',
    'address',
    'jurisdictionName',
    'franchiseName',
  ];

  const columns = column => {
    if (
      [
        'id',
        'isDeleted',
        'franchise',
        'jurisdiction',
        'street',
        'city',
        'state',
        'zipCode',
        'serviceType',
        'contacts',
        'manager',
        'createdAt',
        'franchiseName',
        'permits',
        'numPermits',
      ].includes(column)
    ) {
      return null;
    }

    switch (column) {
      case 'name':
        return 'Project Name';
      case 'jurisdictionName':
        return 'Jurisdiction';
      case 'managerName':
        return 'Project Manager';
      case 'permitsNames':
        return 'Permit Name';
      case 'permitsNumbers':
        return 'Permit Number';
      case 'permitsStatuses':
        return 'Permit Status';
      default:
        return tableColumnsFormatter(column);
    }
  };

  const handleCloseForm = () => {
    if (!modalError && !modalSuccess) {
      handleCloseModal();
    }
    dispatch(setNewProjectModalOpen(false));
    dispatch(setProjectCandidate(null));
  };

  const handleCloseEditProject = () => {
    setProjectData({});
    setDefaultValues({});
    setEditProjectMode(false);
    setEditProjectModal(false);
  };

  const handlePermitClick = (data, column) => {
    if (column === 'name') {
      if (Object.keys(data).length) {
        setProjectData(data);
        setEditProjectModal(true);
      }
    } else {
      if (data.permitItemId) {
        const path = generatePath(ROUTES.PERMIT, {
          projectId: data.projectId,
          permitId: data.permitItemId,
        });
        history.push(path);
      }
      if (data.permits?.length === 1) {
        const path = generatePath(ROUTES.PERMIT, {
          projectId: data.id,
          permitId: data.permits[0].id,
        });
        history.push(path);
      }
    }
  };

  return (
    <>

      <Container>
        <DashboardBlocks />
        <ActionsPanel
        onSearch={setSearch}
        onAdd={handleAdd}
        isBreadCrumbsVisible={false}
        buttonTitle="Add Project"
        />
        <ProjectsTable handleCellClick={(data, column) =>
            handlePermitClick(data, column)
          }/>
      </Container>

      {isEditProjectModal && (
        <ModalDefaultDraggable
          title={
            isEditProjectMode
              ? 'Edit Project'
              : 'Project Details'
          }
          addContactModal={addContactModal}
          onClose={handleCloseEditProject}
        >
          {isLoading &&
            !Object.keys(defaultValues).length && (
              <LoaderWithContainer />
            )}
          {!isLoading &&
            !!Object.keys(defaultValues).length && (
              <ProjectEditForm
              HandleAddContactModal={HandleAddContactModal}
                editMode={isEditProjectMode}
                initialValues={defaultValues}
                changeMode={setEditProjectMode}
                jurisdiction={jurisdictionsData}
                manager={projectManagersData}
                contacts={contactData}
                handleCloseEditProject={
                  handleCloseEditProject
                }
                updateTable={updateTable}
              />
            )}
        </ModalDefaultDraggable>
      )}

      {(isModalOpen || newProjectModalOpen) && (
        <ModalDefaultDraggable
          onClose={handleCloseForm}
          title={modalTitle}
          wide
        >
          <ProjectFormContainer
            handleCloseMainForm={handleCloseForm}
            isExistingProjectChosen={
              isExistingProjectChosen
            }
            setIsExistingProjectChosen={
              setIsExistingProjectChosen
            }
            isModalError={modalErrorTitle.length > 0}
            onSubmit={createOrUpdate}
            setModalTitle={setModalTitle}
            jurisdictionsData={jurisdictionsData}
          />
        </ModalDefaultDraggable>
      )}

      {(modalSuccess || modalError) && (
        <ModalInfo
          timeToClose={5000}
          isError={modalError}
          onClose={handleCloseModalInfo}
          errorMsg={modalErrorTitle}
        />
      )}
    </>
  );
};

const Container = styled.div`
  margin: 20px 0 0 0;
  width: 100%;
`;

export default ProjectsPage;
