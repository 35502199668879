import React, { useState } from 'react';
import ClientContainer from '../../ClientContainer';
import WrapperWithRightDrawer from '../../Common/Containers/WrapperWithRightDrawer/WrapperWithRightDrawer';
import ProjectsTable from './ProjectsTable';
import Drawer from '../../Common/Containers/WrapperWithRightDrawer/Drawer';
import useGetNewProjectData from '../../../hooks/useGetNewProjectData';
import BannerStrip from '../../Common/Containers/BannerStrip';
import styled from 'styled-components';
import SwitchCheckbox from '../../Common/Controls/SwitchCheckbox';
import ProjectDetails from './projectDetails';
import useCloseDrawers from '../../../hooks/useCloseDrawers';

type titleOption = 'ACTIVE PROJECTS' | 'ALL PROJECTS';
const Projects = () => {  
  useGetNewProjectData();
  useCloseDrawers();
  const [checked, setChecked] = useState<boolean>(false);


  const onChange = (e) => {
    setChecked(e.target.checked);   
  }
  return (
    <ClientContainer>
      <BannerStrip label={!checked ? 'ACTIVE PROJECTS' : 'ALL PROJECTS'}>
        <SelectorWrapper>
          <SwitchCheckbox
            label={''}
            checked={checked}
            name="showAll"
            value={''}
            onChange={onChange}
            isRadio={false}
            disabled={false}
            title=''
          />
        </SelectorWrapper>
      </BannerStrip>
      <WrapperWithRightDrawer>
        <ProjectsTable showActiveOnly={!checked}/>
        <Drawer>
          <ProjectDetails />
        </Drawer>
      </WrapperWithRightDrawer>
    </ClientContainer>
  );
};

export default Projects;

const SelectorWrapper = styled.div`  
  position: absolute;
  top:-5px;
  left: 280px;
  margin-left: 30px;
  @media only screen and (max-width: 540px) {
    display: none;
  }
`;
