import React from 'react';
import ReceiptIcon from '@material-ui/icons/Receipt';
import TableDashboard, { DashboardTableHeaders } from '../../../Common/TableDashboard/TableDashboard';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../redux/root';
import { useHistory } from 'react-router';
import { ROUTES } from '../../../Routes/ClientRoutes';
import { setSelectedPermitTicket } from '../../../../../../redux/slices/ticketsSlice';
import useGetCleanRecrods from './useGetCleanRecrods';

const TicketsTable = () => {
  const _records = useSelector((s: RootState) => s.tickets.permitTicketsList);
  const records = useGetCleanRecrods(_records);
  const dispatch = useDispatch();
  const history = useHistory();
  const onClick = (id: number) => {
    const _selected = records.find(r => r.permitId === id);
    dispatch(setSelectedPermitTicket(_selected));
    history.push(`${ROUTES.TICKETS}?openDrawer=true`);
  }
  return (
    <TableDashboard headers={DashboardTableHeaders('Tickets')} records={records} iconTitle="Tickets" onClick={onClick}>
      <ReceiptIcon />
    </TableDashboard>
  );
};

export default TicketsTable;
