import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { getUnpaidPermitsFees } from '../../api/fees';
import { currencyFormat } from '../../pages/Permits/FeesPage';

const BalanceControl = ({feeId,setUnpaidBalance}) => {
    const [balance, setBalance] = useState<number>(0);
     const {permitId} = useParams();
    useEffect(() => {
      const load = async () => {
         const results:any = await getUnpaidPermitsFees({permit:permitId,feeId});
         if(results && results.data){            
            const bal = results.data.feesList[0].balanceAmount 
            setBalance(bal);
            setUnpaidBalance(bal);
         }         
      }
      load();
    }, [])
    return (
        <Wrapper>
            Balance: 
            <div>{currencyFormat.format(balance)}</div>
        </Wrapper>
    );
};

export default BalanceControl;
const Wrapper = styled.div`
  display: flex;
  gap: 9px;
  margin: 12px 0;
`;