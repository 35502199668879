import React, { useEffect } from 'react';
import styled from 'styled-components';
import DahsboardPanel from '../../Common/Containers/DahsboardPanel';
import DocumentsTable from './DashboardTables/DocumentsTable';
import InspectionTable from './DashboardTables/InspectionTable';
import FeesTable from './DashboardTables/FeesTable';
import TicketsTable from './DashboardTables/TicketsTable';

const GridArea = () => {
  return (
    <Wrapper>
      <MainGrid>
        <LeftPanelSpacer>
          <DahsboardPanel caption="Documents Needed">
            <DocumentsTable />
          </DahsboardPanel>
        </LeftPanelSpacer>

        <PanelSpacer>
          <DahsboardPanel caption="Request Inspections">
            <InspectionTable />
          </DahsboardPanel>
        </PanelSpacer>

        <LeftPanelSpacer>
          <DahsboardPanel caption="Pay Invoices" >
            <FeesTable />
          </DahsboardPanel>
        </LeftPanelSpacer>
        <PanelSpacer>
          <DahsboardPanel caption="Tickets" >
            <TicketsTable />
          </DahsboardPanel>
        </PanelSpacer>
      </MainGrid>
    </Wrapper>
  );
};

export default GridArea;

const Wrapper = styled.div`
  width: 100%;
  overflow-y: auto;
`;

const MainGrid = styled.div`
  display: grid;
  grid-template-columns: 49% 49%;
  height: 100%;
  grid-gap: 18px;
  margin: auto;
  width: 92svw;
  overflow-y: auto;
  height: 69vh;
  @media only screen and (max-width: 840px) {
    grid-template-columns: 1fr;
    overflow-y: inherit;
    width: 99vw;
  }
`;

const PanelSpacer = styled.div`
  padding-right: 13px;
  margin-right: 21px;
  @media only screen and (max-width: 840px) {
    padding-right: 0;
    margin-right: 0;
  }
`;

const LeftPanelSpacer = styled.div`
  margin-left: 30px;
  @media only screen and (max-width: 840px) {
    margin-left: 0;
  }
`;
