import React, { useEffect, useState } from 'react';
import { useGetFeesListQuery, useGetProjectQuery, useGetVocabularyQuery } from '../../redux/rtkQuery/apiSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/root';

type FeeType = {
  isChecked: boolean;
  name: string;
  amount: string;
  quantity: string;
  serviceType: string;
  feeSettings: number;
};

const usePermitFeesRows = projectId => {
  const [params, setParams] = useState<any>();
  const { data, isFetching: isFetchingData } = useGetFeesListQuery(params, { skip: params === undefined });
  const [prjParams, setPrjParams] = useState<{ id: number }>();  
  const { data: project, isFetching, refetch } = useGetProjectQuery(prjParams, { skip: prjParams === undefined });
  const { data: voca } = useGetVocabularyQuery(11);
  const [rows, setRows] = useState<FeeType[]>([]);
  const [filtered, setFiltered] = useState<FeeType[]>([]);
  const filters = useSelector((s: RootState) => s.filters.filters);

  useEffect(() => {
    setPrjParams({ id: projectId });
  }, [projectId]);
  useEffect(() => {
    if (project) {
      setParams({
        limit: 100,
        offset: 0,
        jurisdiction: project.jurisdiction,
      });
    }
  }, [project]);

  useEffect(() => {
    if (data && data.results && voca && voca.results) {
      const _rows = data.results.map(fee => {
        const _serviceType = voca.results.find(v => v.id === fee.feeServiceType);
        return {
          isChecked: false,
          name: fee.description,
          amount: fee.amount,
          quantity: '1',
          serviceType: _serviceType ? _serviceType.title : '',
          feeSettings: fee.id,
        };
      });
      setRows(_rows);
    }
  }, [data, voca]);

  useEffect(() => {
    if(filtered.length === 0){
      setFiltered(rows);
    }    
  }, [rows]);

  useEffect(() => {
    if (filters.length > 0) {
      const value = filters[0].filterValue.trim();
      const _rows = rows.filter(fee => {
        if (fee.name.toLocaleLowerCase().indexOf(value) > -1 || fee.serviceType.toLocaleLowerCase().indexOf(value) > -1) {
          return true;
        }
      });
      setFiltered(_rows);
    } else {
      setFiltered(rows);
    }
  }, [filters]);

  const updateCheck = (id, checked) => {
    const _rows = rows.map((row, ndx) => {
      if (id === row.feeSettings) {
        return {
          ...row,
          isChecked: checked,
        };
      }
      return row;
    });
    setRows(_rows);
  };
  return { rows: filtered, setRows: setFiltered, updateCheck,allRows: rows, isFetching, isFetchingData };
};

export default usePermitFeesRows;
