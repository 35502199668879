import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { FeeItemType, PermitFeeItem, PermitTicketItem, TicketComment, TicketItemType } from '../../modules/clientModule/types';

export interface TicketsState {
  permitTicketsList: PermitTicketItem[];
  selectedPermitTicket: PermitTicketItem;
  ticketList: TicketItemType[];
  comments: TicketComment[];
  selectedTicket: TicketItemType;
}

const initialState: TicketsState = {
  permitTicketsList: [{} as PermitTicketItem],
  selectedPermitTicket: {} as PermitTicketItem,
  ticketList: [],
  comments: [],
  selectedTicket: {} as TicketItemType,
};

export const ticketsSlice = createSlice({
  name: 'tickets',
  initialState,
  reducers: {
    setPermitTicketsList: (state, action: PayloadAction<PermitTicketItem[]>) => {
      state.permitTicketsList = action.payload;
    },
    setSelectedPermitTicket: (state, action: PayloadAction<PermitTicketItem>) => {
      state.selectedPermitTicket = action.payload;
    },
    setTicketList: (state, action: PayloadAction<TicketItemType[]>) => {
      state.ticketList = action.payload;
    },
    setComments: (state, action: PayloadAction<TicketComment[]>) => {
      state.comments = action.payload;
    },
    setSelectedTicket: (state, action: PayloadAction<TicketItemType>) => {
      state.selectedTicket = action.payload;
    },
    appendTicket: (state, action: PayloadAction<TicketItemType>) => {
      const _tickets = [action.payload, ...state.ticketList];
      state.ticketList = _tickets;
    },
    appendComment: (state, action: PayloadAction<TicketComment>) => {
      const _comments = [action.payload, ...state.comments];
      state.comments = _comments;
    },
    resetTickets: state => {
      state.permitTicketsList = [];
      state.selectedPermitTicket = {} as PermitTicketItem;
      state.ticketList = [];
      state.comments = [];
      state.selectedTicket = {} as TicketItemType;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setPermitTicketsList,
  setSelectedPermitTicket,
  setTicketList,
  setComments,
  setSelectedTicket,
  appendComment,
  appendTicket,
  resetTickets,
} = ticketsSlice.actions;

export default ticketsSlice.reducer;
