import React, { useEffect, useState } from 'react';
import { ButtonMain } from '../atoms';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/root';
import { getReviewerComments } from '../api/projectDocuments';
import { getCustomActivities } from '../api/activities';
import { useParams } from 'react-router';
import { setReviewComments } from '../redux/slices/documentsSlice';

const ReviewCommentsLetterButton = ({ setShowReviewCommentLtr, cameFromSearch, disableReviewCommentBtn }) => {
  const reviewComments = useSelector((s:RootState) => s.documents.ReviewComments);
  const [reviews, setReviews] = useState<any>();
  const dispatch = useDispatch();
  
   //@ts-ignore
   const { permitId } = useParams();
  useEffect(() => {
    const load = async () => {
      const result = await getCustomActivities({
        is_deleted: false,
        permit_id:permitId,
        event_type: "Activity",
        activity_type_name: "Reviews"
      });

      const ids = result?.data?.results?.map(a => a.id);
      if(ids && ids.length > 0){
        setReviews(ids);
      }
    }
    load();
   
  }, [])
  React.useEffect(() => {
    const promises = [];
    reviews?.map(review => promises.push(getReviewerComments(review,true)));
    Promise.all(promises).then(response => {
      const arr = response.map(res => res.data);
      dispatch(setReviewComments(arr));
    });
  }, [reviews]);
  const isDisabled = () => {
    if(reviewComments.length === 0){
        return true;
    }
    return cameFromSearch || disableReviewCommentBtn;
  };
  return (
    <ButtonMain onClick={() => setShowReviewCommentLtr(true)} disabled={isDisabled()}>
      Review Comments Letter
    </ButtonMain>
  );
};

export default ReviewCommentsLetterButton;
