import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router';
import { Loader } from '../../atoms';
import { Button } from '@material-ui/core';
import { createPermitFee } from '../../api/fees';
import SearchInput from '../../Components/TableWithFilters/SearchInput';
import usePermitFeesRows from './usePermitFeesRows';

const PermitFeesForm1 = ({ setIsMoreThanMillion, setIsSubmitting, showSuccessModal, handleCloseModal }) => {
  //@ts-ignore
  const { projectId, permitId } = useParams();
  const { rows, setRows, updateCheck, allRows, isFetching, isFetchingData } = usePermitFeesRows(projectId);

  const onChange = (e, index) => {
    const name = e.target.name;
    const value = e.target.value;
    let _rows = [];
    if (name === 'check') {
      const _checked = e.target.checked;
      _rows = rows.map((fee, ndx) => {
        if (ndx === index) {
          updateCheck(fee.feeSettings,_checked)
          return {
            ...fee,
            isChecked: _checked,
          };
        }

        return fee;
      });
    } else {
      if (isNaN(value)) {
        return;
      }

      if (name === 'quantity' && value.indexOf('.') > -1) {
        return;
      }
      _rows = rows.map((fee, ndx) => {
        if (ndx === index) {
          return {
            ...fee,
            [name]: value,
          };
        }

        return fee;
      });
    }

    setRows(_rows);
  };

  const onCheckAll = e => {
    const _checked = e.target.checked;
    const _rows = rows.map((fee, ndx) => {
      return {
        ...fee,
        isChecked: _checked,
      };
    });
    setRows(_rows);
  };

  const addDisabled = () => {
    return !(rows.filter(r => r.isChecked).length > 0);
  };

  const onBlur = (e, index) => {
    const value = e.target.value;
    const _rows = rows.map((fee, ndx) => {
      if (ndx === index) {
        return {
          ...fee,
          amount: Number(value).toFixed(2),
        };
      }

      return fee;
    });
    setRows(_rows);
  };
  const onAdd = () => {
    const selected = allRows.filter(r => r.isChecked);
    const totalAmountFees = selected.reduce((previousValue, currentValue) => {
      previousValue = previousValue + Number(currentValue.amount) * Number(currentValue.quantity);
      return previousValue;
    }, 0);

    if (totalAmountFees > 99999.0) {
      setIsSubmitting(false);
      setIsMoreThanMillion(true);

      return;
    }

    selected.forEach(async fee => {
      await createPermitFee({
        permit: permitId,
        name: fee.name,
        amount: fee.amount,
        feeSettings: fee.feeSettings,
        quantity: fee.quantity,
      });
    });

    showSuccessModal(true);
    handleCloseModal();
  };
  return (
    <Wrapper>
      <Search>
        <Label>Search:</Label>
        <SearchInput name="global" />
      </Search>

      <Row noMargin={true}>
        <input type="checkbox" onChange={onCheckAll} />
        <HeaderCell>Fee Name</HeaderCell>
        <HeaderCell>Amount</HeaderCell>
        <HeaderCell>Fee Service Type</HeaderCell>
        <HeaderCell>Quantity</HeaderCell>
      </Row>
      <Table>
        {!(isFetching || isFetchingData) ? (
          <div>
            {rows.map((fee, ndx) => (
              <Row key={ndx}>
                <div>
                  <input type="checkbox" name="check" checked={fee.isChecked} onChange={e => onChange(e, ndx)} />
                </div>
                <Cell enabled={fee.isChecked} left={true}>
                  {fee.name}
                </Cell>
                <Cell enabled={fee.isChecked}>
                  <input
                    type="text"
                    value={fee.amount}
                    name="amount"
                    disabled={!fee.isChecked}
                    onChange={e => onChange(e, ndx)}
                    onBlur={e => onBlur(e, ndx)}
                  />
                </Cell>
                <Cell enabled={fee.isChecked} left={true}>
                  {fee.serviceType}
                </Cell>
                <Cell enabled={fee.isChecked}>
                  <input type="text" value={fee.quantity} name="quantity" disabled={!fee.isChecked} onChange={e => onChange(e, ndx)} />
                </Cell>
              </Row>
            ))}
          </div>
        ) : (
          <LoaderCenter>
            <Loader />
          </LoaderCenter>
        )}
      </Table>
      <Buttons>
        <Button disabled={addDisabled()} color="primary" variant="contained" onClick={onAdd}>
          Add
        </Button>
      </Buttons>
    </Wrapper>
  );
};

export default PermitFeesForm1;

const Search = styled.div`
  display: flex;
  width: 100%;
  margin: 0 21px;
  gap: 10px;
  justify-content: flex-end;
  padding-right: 38px;
`;

const Label = styled.div`
  font-size: 15px;
  position: relative;
  top: 5px;
`;

const LoaderCenter = styled.div`
  width: 100%;
  text-align: center;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 75vh;
`;

const Table = styled.div`
  height: 80%;
  width: 100%;
  overflow: auto;
`;

const Row = styled.div<{ noMargin?: boolean }>`
  margin: 5px 20px;
  margin-right: ${({ noMargin }) => (noMargin ? '16px' : 'inherit')};
  display: grid;
  grid-template-columns: 65px 1fr 110px 162px 73px;
  padding-top: 14px;
  padding-bottom: 14px;
  background-color: var(--border);
  background-color: var(--defaultTableCell);
  border-radius: 6px;
`;

const HeaderCell = styled.div`
  font-size: 13px;
  font-weight: bold;
`;

const Cell = styled.div<{ left?: boolean; enabled: boolean }>`
  font-size: 14px;
  text-align: ${({ left }) => (left ? 'left' : 'inherit')};
  padding-left: ${({ left }) => (left ? '20px' : 'inherit')};
  input {
    width: 50px;
    border: 1px solid var(--disabledButton);
    color: ${({ enabled }) => (!enabled ? 'var(--disabledButton)' : '#000')};
  }
`;

const Buttons = styled.div`
  height: 10%;
  text-align: center;
  margin-top: 17px;
  button {
    width: 200px;
  }
`;
