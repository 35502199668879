import React from 'react';
import styled from 'styled-components';
import { link1, link2, link3, link4, link5 } from './links';
import openInNewTab from '../../../../../../utils/openInNewTab';

const Toturial = ({onHelpPage=false}) => {
  const onClick = e => {
    switch (Number(e.target.id)) {
      case 1:
        openInNewTab(link1);
        break;
      case 2:
        openInNewTab(link2);
        break;
      case 3:
        openInNewTab(link3);
        break;
      case 4:
        openInNewTab(link4);
        break;
      case 5:
        openInNewTab(link5);
        break;
    }
  };
  return (
    <Wrapper>
      <Header onHelpPage={onHelpPage}>Quick Tutorial</Header>
      <Container>
        <Links>
          <ol>
            <li>
              <Line id="1" onClick={onClick}>
                Video 1: Introduction
              </Line>
            </li>
            <li>
              <Line id="2" onClick={onClick}>
                Video 2: How to Access your Permits
              </Line>
            </li>
            <li>
              <Line id="3" onClick={onClick}>
                Video 3: How to Schedule Inspections
              </Line>
            </li>
            <li>
              <Line id="4" onClick={onClick}>
                Video 4: How to Pay Invoices
              </Line>
            </li>
            <li>
              <Line id="5" onClick={onClick}>
                Video 5: How to Upload Documents
              </Line>
            </li>
          </ol>
        </Links>
      </Container>
    </Wrapper>
  );
};

export default Toturial;

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  margin: auto;
  margin-top: 8px;
`;
const Header = styled.div<{onHelpPage:boolean}>`
  font-size: 40px;
  width: 100%;
  text-align: ${({onHelpPage}) => onHelpPage ? 'left': 'center' };
  padding-left: ${({onHelpPage}) => onHelpPage ? '231px': '0' };
  padding-top: 30px;
  font-weight: bold;
`;

const Links = styled.div`
  margin: auto;
  margin-top: 41px;
  color: var(--primary);
  font-size: 24px;
  line-height: 60px;
`;

const Line = styled.div`
  cursor: pointer;
  &:hover {
    font-weight: bold;
  }
`;
