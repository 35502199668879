import * as React from 'react';
import styled from 'styled-components';
import { makeStyles, Box } from '@material-ui/core';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import ProjectFormStepOne from './ProjectFormStepOne';
import ProjectFormStepThree from './ProjectFormStepThree';
import ProjectFormStepFour from './ProjectFormStepFour';
import { ButtonWithLoader, InputBaseContainer, InputSelectWithLabel, LoaderWithContainer, ModalDefaultDraggable } from '../molecules';
import { createProject, getProjects, ProjectType } from '../api/projects';
import { getContactTypeList } from '../api/contactType';
import { GET_ALL_QUERY_PARAMS } from '../constants';
import { USER_TYPES_VALUES } from '../constants/forms';
import ModalActionDialog from '../molecules/ModalActionDialog';
import useTablePage from '../hooks/useTablePage';
import UsersForm from './UsersForm';
import { createUser, getUsers, getUsersCustom } from '../api/users';
import ModalInfo from '../molecules/ModalInfo';
import useUserRole from '../hooks/useUserRole';
import { getCurrentUser } from '../api/userSettings';
import useDidUpdateEffect from '../hooks/useDidUpdate';
import formatDate from '../utils/formatDate';
import { validationSchema } from './ProjectFormContainer/ProjectFormSchema';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/root';
import { defaultValues } from './ProjectFormContainer/ProjectFormLabels';
import { dispatch } from '../redux/store';
import { persistNewProjectData } from '../utils';
import { useGetClientProjectsQuery } from '../redux/rtkQuery/apiSlice';

const useStyles = makeStyles(theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: '90%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
}));

const ProjectFormContainer = ({
  onSubmit,
  handleCloseMainForm,
  isModalError,
  setIsExistingProjectChosen,
  isExistingProjectChosen,
  setModalTitle,
}: Props) => {
  const {
    isModalOpen,
    modalTitle,
    values,
    modalErrorTitle,
    errors,
    handleAdd,
    modalError,
    modalSuccess,
    isApiSuccess,
    createOrUpdate,
    handleCloseModal,
    handleCloseModalInfo,
  } = useTablePage<ProjectType>({
    createEndpoint: createUser,
    modalCreateTitle: 'Add Contact',
  });

  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);

  const [isContactModalOpen, setContactModalOpen] = React.useState(false);
  const [contacts, setContacts] = React.useState(null);
  const [selectedContacts, setSelectedContacts] = React.useState(null);
  const [savedContacts, setSavedContacts] = React.useState(null);
  const [contactTypes, setContactTypes] = React.useState(null);

  const steps = ['one', 'three', 'four'];

  const [projectsList, setProjectsList] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [isSomeStepLoading, setIsSomeStepLoading] = React.useState(false);
  const [isNewProject, setIsNewProject] = React.useState([]);
  const [isDialogModalOpen, setIsDialogModalOpen] = React.useState(false);
  const [isChooseProjectModalOpen, setIsChooseProjectModalOpen] = React.useState(false);
  const [selectedProject, setSelectedProject] = React.useState(null);
  const [isContactFormSubmitting, setIsContactFormSubmitting] = React.useState(false);
  const projectCandidate = useSelector((s:RootState) => s.projectsSlice.projectCandidate);
  const { data: clientProject, refetch } = useGetClientProjectsQuery({ project_request: true });

  const { isEmployee } = useUserRole();

  const [currentManager, setCurrentManager] = React.useState(null);

  useDidUpdateEffect(() => {
    setIsLoading(true);
    // setIsExistingProjectChosen(false);

    Promise.all([
      // getProjects({
      //   ...GET_ALL_QUERY_PARAMS,
      // }),
      getUsersCustom({
        userRole: USER_TYPES_VALUES.CLIENT,
        project: 'true',
      }),    
      getContactTypeList({
        ...GET_ALL_QUERY_PARAMS,
      }),
    ])
      .then(
        ([
          // projectsResponse,
          individualClientsResponse,
          // companyClientsResponse,
          contactTypeResponse,
        ]) => {
          setContacts([...individualClientsResponse.data?.filter(el => el?.isActive && (el?.clientType === 1 || el?.clientType === 2))]);
          setContactTypes(contactTypeResponse.data.results?.filter(el => el?.isActiveStatus === 'True'));
          // setProjectsList(projectsResponse.data.results);
        },
      )
      .catch(() => {})
      .finally(() => setIsLoading(false));
  }, [activeStep === 2]);

  // get contacts data on open select event
  const getContactsData = () => {
    Promise.all([
      getUsersCustom({
        userRole: USER_TYPES_VALUES.CLIENT,
        project: 'true',
      }),
    ]).then(([individualClientsResponse]) => {
      setContacts([...individualClientsResponse.data?.filter(el => el?.isActive && (el?.clientType === 1 || el?.clientType === 2))]);
    });
  };

  React.useEffect(() => {
    if (activeStep === 2) {
      setModalTitle('Add Permit');
    } else {
      setModalTitle('Add Project');
    }
  }, [activeStep]);

  React.useEffect(() => {
    Promise.all([
      getCurrentUser(),
      getUsers({
        ...GET_ALL_QUERY_PARAMS,
        userRole: USER_TYPES_VALUES.EMPLOYEE,
      }),
    ]).then(([currentUserResponse, projectManagersResponse]) => {
      if (isEmployee) {
        const activeManagers = projectManagersResponse.data.results?.filter(el => el?.isActive);
        const currentUserAsEmployee = activeManagers.find(el => el.id === currentUserResponse.data.id);

        setCurrentManager(currentUserAsEmployee);
      }
    });
  }, [isEmployee]);

  const currentValidationSchema = validationSchema[activeStep];

  const methods = useForm({
    shouldUnregister: false,
    defaultValues: projectCandidate ? projectCandidate : defaultValues,
    resolver: yupResolver(currentValidationSchema),
    mode: 'all',
  });

  const { handleSubmit, trigger, getValues, formState, setError, errors: formErrors } = methods;

  React.useEffect(() => {
    if (formState.isSubmitting && !Object.keys(formErrors).length) {
      setIsSubmitting(formState.isSubmitting);
    }
  }, [formState]);

  React.useEffect(() => {
    if (Object.keys(formErrors).length) {
      setIsSubmitting(false);
    }
  }, [formErrors]);

  const handleCloseForm = () => {
    if (!modalError && !modalSuccess) {
      handleCloseModal();
    }
  };

  const handleCloseContactForm = () => {
    setSelectedContacts(null);
    setContactModalOpen(false);
  };

  const handleSelectContacts = selected => {
    methods.setValue('selectContactsField', selected);
    setSelectedContacts(selected);
    if (!selected && selectedContacts) {
      setSelectedContacts(null);
    }
  };

  const handleSaveContacts = () => {
    if (savedContacts && selectedContacts) {
      const contactsAll = [...savedContacts, ...selectedContacts];
      const uniqueContacts = contactsAll.reduce((x, y) => (x.findIndex(e => e.id === y.id) < 0 ? [...x, y] : x), []);
      setSavedContacts(uniqueContacts);
      setSelectedContacts(null);
    }
    if (!savedContacts) {
      setSavedContacts(selectedContacts);
    }
    setContactModalOpen(false);
  };

  let isStepValid = true;

  const [isValidating, setIsValidating] = React.useState(false);

  const validate = async () => {
    setIsValidating(true);
    isStepValid = await trigger();
    setIsValidating(false);
    if (!isStepValid) return false;
    return true;
  };

  const handleNext = async () => {
    const pattern = /^\s+/g;

    if (activeStep === 0) {
      const res = await validate();
      if (res) {
        const address = await methods.getValues('address');
        const city = await methods.getValues('city');
        const state = await methods.getValues('state');
        const street = await methods.getValues('street');

        if (pattern.test(address)) {
          setError('address', {
            type: 'manual',
            message: 'Address field contains too many spaces',
          });
        }

        if (pattern.test(city)) {
          setError('city', {
            type: 'manual',
            message: 'City field contains too many spaces',
          });
        }

        if (pattern.test(state)) {
          setError('state', {
            type: 'manual',
            message: 'State field contains too many spaces',
          });
        }

        if (pattern.test(street)) {
          setError('street', {
            type: 'manual',
            message: 'Street field contains too many spaces',
          });
        }
      }
    }

    if (activeStep === 1) {
      const res = validate();
      if (res) {
        const projectName = getValues('name');

        if (pattern.test(projectName)) {
          setError('name', {
            type: 'manual',
            message: 'Project name field contains too many spaces',
          });
        }
      }
    }

    if (!Object.keys(formState?.errors)?.length) {
      const res = await validate();

      if (res) {
        if (activeStep === 0) {
          getProjects({
            is_deleted: false,
            search: getValues('address'),
          }).then(projectResponse => {
            if (projectResponse?.data?.results?.length) {
              setIsNewProject(projectResponse?.data?.results);
              setIsDialogModalOpen(true);
            }
          });
        }
        setActiveStep(prevActiveStep => prevActiveStep + 1);
      }
    }
  };

  const handleBack = () => {
    if (!Object.keys(formState?.errors)?.length) {
      setActiveStep(prevActiveStep => prevActiveStep - 1);
    }
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <ProjectFormStepOne currentValidationSchema={currentValidationSchema} />;
      case 1:
        return (
          <ProjectFormStepThree
            isContactFormSubmitting={isApiSuccess}
            setIsContactFormSubmitting={setIsContactFormSubmitting}
            currentValidationSchema={currentValidationSchema}
            setIsSecondModalOpen={handleAdd}
            setIsSomeStepLoading={setIsSomeStepLoading}
            currentManager={currentManager}
            selectedProject={selectedProject}
          />
        );
      case 2:
        return (
          <ProjectFormStepFour
            isContactFormSubmitting={isApiSuccess}
            setIsContactFormSubmitting={setIsContactFormSubmitting}
            currentValidationSchema={currentValidationSchema}
            setIsSecondModalOpen={handleAdd}
            setIsContactModalOpen={handleAdd}
            setIsSomeStepLoading={setIsSomeStepLoading}
            setContactModalOpen={setContactModalOpen}
            selectedContacts={savedContacts}
            setSelectedContacts={setSavedContacts}
            contactTypes={contactTypes}
          />
        );
      default:
        return 'Unknown step';
    }
  }

  const handleSelectedProjectContacts = (projectContacts, allContacts, permitContacts) => {
    if (!projectContacts.length) {
      return permitContacts
        ?.map(contact => ({
          user: +contact.id,
          contact_type: contact.contactType ? +contact.contactType : +contactTypes[0]?.id,
        }))
        .filter(el => !!el.user);
    }
    return allContacts.filter(contact => savedContacts?.map(el => el?.id)?.includes(contact.id));
  };

  const middleware = (submitFn: (data) => void) => data => {
    const payload1 = {
      ...data,
      expirationDate: data.expirationDate ? `${formatDate(data.expirationDate)}` : null,
      name: data.name,
      contacts: data.projectContacts ? data.projectContacts?.filter(item => item.id).map(item => item.id) : undefined,
      manager: data.manager?.id,
      jurisdiction: data.jurisdiction?.id,
      // franchise: data.franchise?.id,
      permit: {
        amount: data.totalContract ? data.totalContract : '0',
        description: data.description,
        //@ts-ignore
        client_attachments: projectCandidate?.attachments?.length > 0 ? projectCandidate?.attachments:[],
        name: data.permitName,
        expirationDate: data.expirationDate ? `${formatDate(data.expirationDate)}` : null,
        building: data.building?.id,
        contractor: data.contractor?.id,
        inspections_count: data.inspectionsNumber,
        manager: data.permitManager?.id,
        work: data.workScope?.id,
        serviceType: data.serviceType?.filter(item => item.id).map(item => item.id),
        contacts: savedContacts
          ?.map(contact => ({
            user: +contact.id,
            contact_type: contact.contactType ? +contact.contactType : +contactTypes[0]?.id,
          }))
          .filter(el => !!el.user),
      },
    };

    const payload2 = {
      name: data?.permitName,
      description: data.description,
      expirationDate: data.expirationDate ? `${formatDate(data.expirationDate)}` : null,
      contractor: data.contractor?.id,
      amount: data.totalContract ? data.totalContract : '0',
      building: data.building?.id,
      manager: data.permitManager?.id,
      work: data.workScope?.id,
      inspections_count: data.inspectionsNumber,
      serviceType: data.serviceType?.filter(item => item.id).map(item => item.id),
      project: selectedProject ? selectedProject.id : null,
      // jurisdiction: selectedProject?.jurisdiction,
      contacts: handleSelectedProjectContacts(selectedProject ?selectedProject.contacts : [], contacts, savedContacts).map(contact => ({
        user: +contact.id || contact?.user,
        contact_type: contact.contactType ? +contact.contactType || contact?.contact_type : +contactTypes[0]?.id,
      })),
    };
    try {
      if(projectCandidate){
        persistNewProjectData(projectCandidate,selectedProject, payload1,payload2,dispatch,refetch);         
        return 
      }  else 
      return submitFn(
        !isExistingProjectChosen && !projectCandidate
          ? payload1
          : payload2
      );
    } catch (error) {
      //
    } finally {
      setIsExistingProjectChosen(false);
    }
  };

  const submitWrapper = async func => {
    if (activeStep === 2) {
      const pattern = /^\s+/g;
      const res = await validate();
      if (res) {
        const permitName = getValues('permitName');
        const description = getValues('description');

        if (pattern.test(permitName)) {
          setError('permitName', {
            type: 'manual',
            message: 'Permit name field contains too many spaces',
          });
        }

        if (pattern.test(description)) {
          setError('description', {
            type: 'manual',
            message: 'Description field contains too many spaces',
          });
        }
      }
      if (!Object.keys(formState?.errors)?.length) {
        const resTwo = await validate();
        if (resTwo) {
          func();
        }
      }
    }
  };

  if (isLoading) {
    return <LoaderWithContainer />;
  }

  return (
    <>
      <div className={classes.layout}>
        <Stepper activeStep={activeStep}>
          {steps.map(label => {
            return (
              <Step key={label}>
                <StepLabel />
              </Step>
            );
          })}
        </Stepper>
        <div style={{ minHeight: '50%' }}>
          {activeStep !== steps.length && !isValidating ? (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <FormProvider {...methods}>
              <div className={classes.instructions}>{getStepContent(activeStep)}</div>
              <Box display="flex" justifyContent="center" style={{ paddingTop: '5vh' }}>
                {activeStep !== 0 && (
                  <Button
                    disabled={activeStep === 0 || isLoading || isSomeStepLoading || !!selectedProject}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    Back
                  </Button>
                )}
                {activeStep === steps.length - 1 ? (
                  <ButtonWithLoader
                    variant="contained"
                    color="primary"
                    onClick={() => submitWrapper(handleSubmit(middleware(onSubmit)))}
                    className={classes.button}
                    disabled={(isSubmitting && !isModalError) || !isStepValid || isLoading || isSomeStepLoading}
                    loading={isSubmitting && !isModalError}
                  >
                    Submit
                  </ButtonWithLoader>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                    disabled={!isStepValid || isLoading || isSomeStepLoading}
                  >
                    Next
                  </Button>
                )}
              </Box>
            </FormProvider>
          ) : (
            <LoaderWithContainer />
          )}
        </div>
      </div>

      {isDialogModalOpen && (
        <ModalActionDialog
          title="Is it going to be a new project or this is an addition to an existing project?"
          className="over"
          centered
          onCancel={() => {
            handleCloseMainForm();
            setIsDialogModalOpen(false);
          }}
          actionFirst={() => {
            setIsExistingProjectChosen(true);
            setIsChooseProjectModalOpen(true);
            setIsDialogModalOpen(false);
          }}
          actionSecond={() => {
            setIsNewProject(null);
            setActiveStep(1);
            setIsDialogModalOpen(false);
          }}
          buttonFirstTitle="Existing project"
          buttonSecondTitle="New project"
        />
      )}

      {isChooseProjectModalOpen && (
        <ModalActionDialog
          title="Select Project"
          className="over"
          onCancel={() => {
            handleCloseMainForm();
            setIsExistingProjectChosen(false);
          }}
          actionFirst={() => {
            setActiveStep(2);
            setIsChooseProjectModalOpen(false);
          }}
          actionSecond={null}
          buttonFirstDisable={!selectedProject}
          buttonFirstTitle="Select"
        >
          <InputBaseContainer>
            <InputSelectWithLabel
              placeholder=""
              menuPlacement="bottom"
              label="Project Name"
              options={isNewProject}
              onChange={selected => setSelectedProject(selected)}
              getOptionValue={(item: any) => item.id || Math.random()}
              getOptionLabel={(item: any) => item.name}
              isRequired
            />
          </InputBaseContainer>
        </ModalActionDialog>
      )}

      {isModalOpen && (
        <StyledModalDraggable onClose={handleCloseForm} title={modalTitle}>
          <UsersForm
            setIsContactFormSubmitting={setIsContactFormSubmitting}
            initialValues={values}
            modalError={modalErrorTitle.length > 0}
            initialErrors={errors}
            onSubmit={createOrUpdate}
          />
        </StyledModalDraggable>
      )}

      {isContactModalOpen && (
        <ModalActionDialog
          title="Select Contacts"
          className="over"
          centered
          onCancel={handleCloseContactForm}
          actionFirst={handleSaveContacts}
          actionSecond={null}
          buttonFirstDisable={!selectedContacts}
          buttonFirstTitle="Select"
        >
          <InputBaseContainer>
            <Controller
              name="selectContactsField"
              defaultValue=""
              control={methods.control}
              render={props => (
                <InputSelectWithLabel
                  isMulti
                  showCompany
                  // selectAllActive
                  placeholder=""
                  label="Contacts"
                  getOptionValue={(item: any) => item?.id}
                  getOptionLabel={(item: any) =>
                    // eslint-disable-next-line no-nested-ternary
                    item.name
                      ? item.name
                      : `${item.firstName} ${item.lastName}${
                          item?.companyName || item?.parentCompany ? ` - ${item?.companyName || item?.parentCompany}` : ''
                        }`
                  }
                  isRequired
                  options={contacts}
                  {...props}
                  onChange={handleSelectContacts}
                  // onFocus={() => getContactsData()}
                />
              )}
            />
          </InputBaseContainer>
        </ModalActionDialog>
      )}

      {(modalSuccess || modalError) && (
        <ModalInfo timeToClose={5000} isError={modalError} onClose={handleCloseModalInfo} errorMsg={modalErrorTitle} />
      )}
    </>
  );
};

const StyledModalDraggable = styled(ModalDefaultDraggable)`
  height: 100%;
  overflow: inherit;
`;

interface Props {
  onSubmit: (data) => void;
  setModalTitle: (title: string) => void;
  handleCloseMainForm: any;
  isModalError: boolean;
  isExistingProjectChosen: boolean;
  setIsExistingProjectChosen: (data: boolean) => void;
}

export default ProjectFormContainer;
